import { useContext, useEffect, useMemo, useState } from "react";
import RegionSelect from "react-region-select";
import { IonIcon } from "@ionic/react";
import { useCounter, useEventListener, useMeasure } from "@react-hookz/web";
import * as Icon from "ionicons/icons";
import { observer } from "mobx-react-lite";
import { PixelArea } from "@/interfaces/geometry";
import { AssessmentStoreContext, StoreContext } from "@/stores";
import useDebouncedMouseHovered from "@/utils/use-mouse-hovered";

// pixels that the svg draws offset from the container
const CURSOR_DRAW_ERROR = { x: 8, y: 6 };

const CLICK_ANIMATION_RADIUS_MAX = 40;

const OTHER_USER_CURSOR_COLOR = "#32CD32";

const ClickAnimation = ({ position, isOtherUser }: { position: { x: number; y: number }; isOtherUser: boolean }) => {
  const [radius, radiusCounter] = useCounter(0);
  const [animationInterval, setAnimationInterval] = useState<NodeJS.Timeout>();

  const stopAnimation = (newInterval?: NodeJS.Timeout) => {
    if (animationInterval) clearInterval(animationInterval);
    radiusCounter.reset();
    setAnimationInterval(newInterval);
  };

  // if position is updated, reset the animation
  useEffect(() => {
    const newInterval = setInterval(radiusCounter.inc, 15);
    stopAnimation(newInterval);
    return () => clearInterval(newInterval);
  }, [position]);

  // watch the radius - when it goes over max, stop the animation
  useEffect(() => {
    if (radius >= CLICK_ANIMATION_RADIUS_MAX) stopAnimation();
  }, [radius]);

  const stroke = isOtherUser ? OTHER_USER_CURSOR_COLOR : "black";
  return (
    <div
      style={{
        transform: `translateX(${position.x}px) translateY(${position.y}px)`,
        width: CLICK_ANIMATION_RADIUS_MAX * 2,
        height: CLICK_ANIMATION_RADIUS_MAX * 2,
        top: -CLICK_ANIMATION_RADIUS_MAX,
        left: -CLICK_ANIMATION_RADIUS_MAX,
      }}
      className="absolute pointer-events-none"
      role="img"
      aria-label="click-animation"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`-${CLICK_ANIMATION_RADIUS_MAX} -${CLICK_ANIMATION_RADIUS_MAX} ${CLICK_ANIMATION_RADIUS_MAX * 2} ${
          CLICK_ANIMATION_RADIUS_MAX * 2
        }`}
      >
        <circle r={radius} fill="none" stroke={stroke} />
      </svg>
    </div>
  );
};

const OtherUserCursor = observer(({ containerArea }: { containerArea: PixelArea }) => {
  const {
    remoteStimulus: { mousePosition, mouseClick },
  } = useContext(AssessmentStoreContext);

  const mousePositionOffset = {
    x: containerArea.width * mousePosition.x,
    y: containerArea.height * mousePosition.y,
  };
  const mouseClickOffset = useMemo(() => {
    return (
      mouseClick && {
        x: containerArea.width * mouseClick.x,
        y: containerArea.height * mouseClick.y,
      }
    );
  }, [mouseClick]);

  return (
    <>
      <div
        style={{
          transform: `translateX(${mousePositionOffset.x}px) translateY(${mousePositionOffset.y}px)`,
        }}
        className="absolute top-0 left-0 w-6 h-6 opacity-700"
        role="img"
        aria-label="other-user-cursor"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox={`${CURSOR_DRAW_ERROR.x} ${CURSOR_DRAW_ERROR.y} 28 28`}
          xmlSpace="preserve"
        >
          <path fill="#000" d="M8.2 20.9L8.2 4.9 19.8 16.5 13 16.5 12.6 16.6z"></path>
          <path fill="#000" d="M17.3 21.6L13.7 23.1 9 12 12.7 10.5z"></path>
          <path
            fill={OTHER_USER_CURSOR_COLOR}
            d="M12.5 13.6H14.5V21.6H12.5z"
            transform="rotate(-22.773 13.483 17.596)"
          ></path>
          <path fill={OTHER_USER_CURSOR_COLOR} d="M9.2 7.3L9.2 18.5 12.2 15.6 12.6 15.5 17.4 15.5z"></path>
        </svg>
      </div>
      {mouseClickOffset && <ClickAnimation position={mouseClickOffset} isOtherUser={true} />}
    </>
  );
});

export const ClientStimulusTools = ({
  sendMouse,
  receiveMouse,
  canUserZoom,
}: {
  sendMouse: boolean;
  receiveMouse: boolean;
  canUserZoom: boolean;
}) => {
  const { assessmentStore, activityStore } = useContext(StoreContext);

  const [containerArea, containerRef] = useMeasure<HTMLDivElement>();
  const { x: elX, y: elY } = useDebouncedMouseHovered(containerRef);
  const mousePosition = containerArea && {
    x: elX / containerArea.width,
    y: elY / containerArea.height,
  };
  useEffect(() => {
    if (!sendMouse || !mousePosition) return;
    assessmentStore.setLocalStimulusMousePosition(mousePosition);
  }, [elX, elY, containerArea]);

  // const [clickPosition, setClickPosition] = useState<{x: number, y: number}>()
  useEventListener(containerRef, "mousedown", () => {
    if (!sendMouse || !mousePosition) return;
    assessmentStore.setLocalStimulusMouseClick(mousePosition);
  });

  // the 3rd-party `RegionSelect` component uses a similar proportion-based approach to area selection as our `IRelativeAreaSelection`,
  //  but represents the proportions as whole number percentages (i.e. `50` -> 50%),
  //  so we have to divide all the values by 100
  const [selectedRegion, setSelectedRegion] = useState<any>();
  const zoomConfirmed = () => {
    setSelectedRegion(undefined);
    const zoomData = {
      width: selectedRegion.width / 100,
      height: selectedRegion.height / 100,
      position: {
        x: selectedRegion.x / 100,
        y: selectedRegion.y / 100,
      },
    };
    activityStore.updateAssessmentZoom(zoomData);
  };
  const zoomStopped = () => activityStore.updateAssessmentZoom(undefined);

  const localMouseClick = assessmentStore.localStimulus.mouseClick;
  const localMouseClickOffset = useMemo(
    () =>
      localMouseClick &&
      containerArea && {
        x: containerArea.width * localMouseClick.x,
        y: containerArea.height * localMouseClick.y,
      },
    [localMouseClick, containerArea],
  );
  const zoomSelection = activityStore.currAssessmentTest?.zoom;

  return (
    <div className="relative w-full h-full" ref={containerRef} role="region" aria-label="client-stimulus-tools">
      {containerArea && receiveMouse && <OtherUserCursor containerArea={containerArea} />}
      {localMouseClickOffset && <ClickAnimation position={localMouseClickOffset} isOtherUser={false} />}
      {canUserZoom &&
        (!zoomSelection ? (
          <RegionSelect
            regions={selectedRegion && [selectedRegion]}
            maxRegions={1}
            onChange={(regions: any) => {
              const region = regions[0];
              // prevent selection on stimulus click
              if (!region.width || region.width < 5) return;
              // add some additional properties to the first region to prevent crashes
              if (!selectedRegion) {
                region.new = true;
                region.data = { index: 0 };
              }
              setSelectedRegion(region);
            }}
            constraint={true}
            className="w-full h-full"
            regionRenderer={() => (
              <div className="absolute h-12 -bottom-12">
                <button onClick={zoomConfirmed}>
                  <IonIcon icon={Icon.checkmark} />
                </button>
                <button onClick={() => setSelectedRegion(undefined)}>
                  <IonIcon icon={Icon.close} />
                </button>
              </div>
            )}
          />
        ) : (
          <div className="absolute top-0 right-0 m-4">
            <button onClick={zoomStopped}>Cancel Zoom</button>
          </div>
        ))}
    </div>
  );
};

export default observer(ClientStimulusTools);
