import { ReactNode, isValidElement } from "react";
import { ClipLoader } from "react-spinners";
import { PlacesType } from "react-tooltip";
import { IonIcon } from "@ionic/react";
import Link from "@/components/common/elements/Link";
import { classNames } from "@/utils";
import ToolTip from "./ToolTip";

// props shared by both components below
type ICommonProps = {
  uniqueName: string;
  labelText: string;
  placeTooltip?: PlacesType;
  onClick: () => void;
  propagateClick?: boolean;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  href?: string;
};

// TODO update all buttons in `CallControls` + `ProviderTools` to use this component
const TooltipButton = ({
  uniqueName,
  labelText,
  placeTooltip = "top",
  onClick,
  propagateClick = true,
  className,
  isLoading,
  children,
  loadingStateColor,
  disabled,
  href,
}: ICommonProps & { children: ReactNode; loadingStateColor?: string }) => {
  const tooltipId = `${uniqueName}-tooltip`;
  const button = (
    <button
      data-tooltip-id={tooltipId}
      className={`cursor-pointer grid place-items-center ${className}`}
      onClick={e => {
        if (disabled) return;
        onClick();
        if (!propagateClick) e.stopPropagation();
      }}
      disabled={disabled}
      aria-label={uniqueName}
      name={uniqueName}
    >
      {isLoading ? <ClipLoader size="60%" color={loadingStateColor || "white"} /> : children}
      <ToolTip place={placeTooltip} id={tooltipId}>
        {labelText}
      </ToolTip>
    </button>
  );
  if (!href) return button;

  return <Link href={href}>{button}</Link>;
};

// a helper component for the common case of wanting to simply render a button with a single IonIcon component inside it
export const IonIconTooltipButton = (
  props: ICommonProps & {
    icon: string;
    badgeNumber?: number;
    loadingStateColor?: string;
    disabled?: boolean;
  },
) => (
  <TooltipButton {...props} disabled={props.disabled}>
    <div className="flex relative justify-center items-center">
      <IonIcon icon={props.icon as string} className={classNames(props.badgeNumber ? "" : "")} />

      {props.badgeNumber && props.badgeNumber > 0 ? (
        <span className="absolute -top-px right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red rounded-full">
          {props.badgeNumber}
        </span>
      ) : undefined}
    </div>
  </TooltipButton>
);

// helper component that allows for non-ion icons to be used with the button component
export const IconTooltipButton = (
  props: ICommonProps & {
    icon: ReactNode;
    badgeNumber?: number;
    loadingStateColor?: string;
    disabled?: boolean;
  },
) => (
  <TooltipButton {...props} disabled={props.disabled}>
    <div className="flex relative justify-center items-center shrink-0">
      {isValidElement(props.icon) && props.icon}
      {props.badgeNumber && props.badgeNumber > 0 ? (
        <span className="absolute -top-px right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red rounded-full">
          {props.badgeNumber}
        </span>
      ) : undefined}
    </div>
  </TooltipButton>
);

export default TooltipButton;
