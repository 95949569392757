import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { ClipLoader } from "react-spinners";
import { IonIcon } from "@ionic/react";
import * as Icons from "ionicons/icons";
import { groupBy, isNull, mapValues } from "lodash";
import { DateTime } from "luxon";
import { CompletedConnectionTestStatus } from "@parallel/vertex/types/logging.types";
import { loggingApi } from "@/api/logging.api";
import SupportEmailLink from "@/components/common/elements/SupportEmailLink";
import { SimpleHeaderLayout } from "@/components/common/layout/HeaderLayout";
import { UserStoreContext, loggerContext } from "@/stores";
import { ConnectionTargetResult } from "@/utils/hooks/use-meeting-connection";
import { initLogger, useEventRedirect } from "@/utils/logging.utils";

const logger = initLogger("TestScreen", loggerContext);

export enum TestStatus {
  Loading = "Loading",
  Success = "Success",
  Warning = "Warning",
  Failure = "Failure",
}

export type TestProps = { title: string; status: TestStatus; messages?: ReactNode[] };

export const getTestStatus = (stepResult?: ConnectionTargetResult) => {
  if (!stepResult?.finishedAt) return TestStatus.Loading;
  if (stepResult.errorMessage) return TestStatus.Failure;
  if (stepResult.warningMessages?.length || 0 > 0) return TestStatus.Warning;
  return TestStatus.Success;
};

const getTestDisplayProps = (test: TestProps, completedStatus?: CompletedConnectionTestStatus) => {
  switch (test.status) {
    case TestStatus.Loading:
      return {
        icon: completedStatus === "Error" ? <IonIcon icon={Icons.closeCircle} /> : <ClipLoader size={15} />,
      };
    case TestStatus.Success:
      return {
        icon: <IonIcon icon={Icons.checkmarkCircle} />,
        headerClass: "text-green-600",
      };
    case TestStatus.Failure:
      return {
        icon: <IonIcon icon={Icons.closeCircle} />,
        headerClass: "text-red",
      };
    case TestStatus.Warning:
      return {
        icon: <IonIcon icon={Icons.warning} />,
        headerClass: "text-orange-600",
      };
  }
};

const TestDisplay = ({
  test,
  completedStatus,
}: {
  test: TestProps;
  completedStatus?: CompletedConnectionTestStatus;
}) => {
  const { icon, headerClass } = getTestDisplayProps(test, completedStatus);
  const messages = test.messages || [];
  return (
    <div className="bg-zinc-50 rounded-md my-1 p-1 border border-grey-500">
      <div className={`h-8 w-full text-lg flex items-center ${headerClass}`}>
        <div className="h-full grid place-content-center mx-3">{icon}</div>
        <p>{test.title}</p>
      </div>
      {messages.length > 0 && (
        <div className="text-gray-600 pl-4 mt-2">
          {messages.map((m, i) => (
            <p key={i}>{m}</p>
          ))}
        </div>
      )}
    </div>
  );
};

const getCompletedStatus = (tests: TestProps[]): CompletedConnectionTestStatus | undefined => {
  const testCountsByStatus = mapValues(groupBy(tests, "status"), tests => tests?.length);
  if (testCountsByStatus[TestStatus.Failure] > 0) return "Error";
  if (testCountsByStatus[TestStatus.Loading] > 0) return undefined;
  if (testCountsByStatus[TestStatus.Warning] > 0) return "Warning";
  if (testCountsByStatus[TestStatus.Success] > 0) return "Success";
};

const ConnectionTest = ({ tests }: { tests: (TestProps & { id: string })[] }) => {
  const { user, sessionId } = useContext(UserStoreContext);

  const [testId, setTestId] = useState<string | null>();
  const date = useMemo(() => DateTime.utc(), []);
  const [isComplete, setIsComplete] = useState(false);

  let isFirstRun = true;
  useEffect(() => {
    if (!user || !sessionId || !isFirstRun) return;
    loggingApi
      .createConnectionTest({
        triggeredAtTimestamp: date,
        triggeredByUserId: user.userId,
        triggeredBySessionId: sessionId,
      })
      .then(r => setTestId(r.connectionTestId))
      .catch(() => setTestId(null));
    return () => {
      isFirstRun = false;
    };
  }, []);

  const completedStatus = getCompletedStatus(tests);
  useEffect(() => {
    if (!testId || !completedStatus || isComplete) return;
    setIsComplete(true);
    loggingApi.completeConnectionTest(testId, {
      status: completedStatus,
      completedAtTimestamp: DateTime.utc(),
      details: { tests: tests as object[] },
    });
  }, [testId, completedStatus]);

  const redirect = useEventRedirect(logger);
  const emailIssueTitle = testId ? `Connection Test ${testId}` : "Connection Test";

  return (
    <SimpleHeaderLayout>
      {testId ? (
        <div className="w-full h-full p-8 overflow-y-auto grid place-items-center">
          <div className="w-1/2 flex flex-col text-gray-700">
            <h1 className="text-3xl mb-2">Parallel Telehealth Connection Test</h1>
            <div className="flex gap-3">
              <div>Test ID:</div>
              <span>{testId || "Loading..."}</span>
            </div>
            <div className="mb-4" />
            {tests.map(t => (
              <TestDisplay test={t} completedStatus={completedStatus} key={t.id} />
            ))}
            <p className="mt-6">
              The results of this test have been logged, and support will be promptly notified of any failures.
            </p>
            <p className="mt-4">
              If you have any questions or require further assistance, please{" "}
              <SupportEmailLink issueTitle={emailIssueTitle} />.
            </p>
            <button
              className="w-36 mt-8 p-2 rounded-md bg-ocean text-white hover:bg-navy"
              onClick={() => redirect(`/lobby`, "user interaction")}
            >
              Back to Lobby
            </button>
          </div>
        </div>
      ) : (
        <div className="bg-background flex h-screen">
          <div className="m-auto flex flex-col gap-4 justify-center items-center">
            {!isNull(testId) && <ClipLoader />}
            <p>
              {isNull(testId)
                ? "Failed to initiate connection test. Please refresh the page, if the issue persists contact support"
                : "Initiating Connection Test"}
            </p>
          </div>
        </div>
      )}
    </SimpleHeaderLayout>
  );
};

export default ConnectionTest;
