import { useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { MeetingStoreContext } from "@/stores";

const LiveCaptions = () => {
  const { providerTranscript } = useContext(MeetingStoreContext);
  const [transcript, setTranscript] = useState(providerTranscript);

  useEffect(() => {
    setTranscript(providerTranscript);

    const timeout = setTimeout(() => {
      setTranscript("");
    }, 2000);

    return () => clearTimeout(timeout);
  }, [providerTranscript]);

  return transcript ? (
    <div
      style={{
        position: "absolute",
        bottom: "68px",
        left: "calc((100% - 800px) / 2)",
        minWidth: "800px",
        minHeight: "44px",
        padding: "10px",
        borderRadius: "6px",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        color: "white",
        textAlign: "center",
        zIndex: 1000,
      }}
    >
      <p style={{ color: "white" }}>{transcript}</p>
    </div>
  ) : null;
};

export default observer(LiveCaptions);
