import { ReactElement } from "react";
import { PlacesType } from "react-tooltip";
import { kebabCase } from "lodash";
import { observer } from "mobx-react-lite";
import { IonIconTooltipButton } from "@/components/common/elements/TooltipButton";
import { classNames } from "@/utils";

const HEADER_BUTTON_COLOR_CLASSES = {
  red: "bg-red hover:bg-failure-dark",
  blue: "bg-ocean hover:bg-navy",
};

export const IonIconHeaderButton = ({
  icon,
  label,
  color = "blue",
  onClick,
  badgeNumber,
  placeTooltip,
}: {
  icon: string;
  label: string;
  color?: "blue" | "red";
  onClick: () => void;
  badgeNumber?: number;
  placeTooltip?: PlacesType;
}) => (
  <div className={classNames("flex h-10 w-10 rounded-full", HEADER_BUTTON_COLOR_CLASSES[color])}>
    <IonIconTooltipButton
      className="h-10 w-10 text-2xl text-white"
      icon={icon}
      uniqueName={kebabCase(label)}
      labelText={label}
      onClick={onClick}
      badgeNumber={badgeNumber}
      placeTooltip={placeTooltip}
    />
  </div>
);

/** pass in `badges` and `contols` as a fragment */
const Header = ({ badges, controls }: { badges: ReactElement; controls: ReactElement }) => {
  return (
    <div className="bg-white flex flex-row justify-between p-2 pr-4 border-b border-slate-300 items-center">
      <div className="flex flex-row gap-4">{badges}</div>
      <div className="flex flex-row gap-4">{controls}</div>
    </div>
  );
};

export default observer(Header);
