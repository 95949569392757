import { useContext, useState } from "react";
import { DailyCall } from "@daily-co/daily-js";
import { useDaily, useNetwork } from "@daily-co/daily-react";
import { useMountEffect } from "@react-hookz/web";
import { omit } from "lodash";
import PlaceholderContainer from "@/components/common/layout/PlaceholderContainer";
import ConnectionTest, { TestStatus, getTestStatus } from "@/components/test/ConnectionTest";
import { UserStoreContext, loggerContext } from "@/stores";
import { NetworkQualityStatus, getNetworkAlertContent } from "@/stores/alert.store";
import {
  DailyConnectionResult,
  DailyConnectionTarget,
  DailyMeetingConnectionParams,
  useDailyMeetingConnection,
} from "@/utils/hooks/use-meeting-connection";
import { initLogger, useEventRedirect } from "@/utils/logging.utils";

const logger = initLogger("ConnectionTestScreen", loggerContext);

const DailyTargetStrings: Record<DailyConnectionTarget, string> = {
  parallel: "Parallel Meeting Service",
  streaming: "Streaming Service",
  whiteboard: "Whiteboard Service",
  chat: "Chat Service",
};

const targetTestProps = (target: DailyConnectionTarget, result: DailyConnectionResult) => {
  const stepResult = result.targets[target];
  return {
    id: target,
    title: DailyTargetStrings[target],
    status: getTestStatus(stepResult),
    messages: stepResult?.errorMessage ? [stepResult.errorMessage] : stepResult?.warningMessages || [],
  };
};

const getNetworkTestProps = (networkQuality: "good" | "low" | "very-low") => {
  switch (networkQuality) {
    case "good":
      return { status: TestStatus.Success };
    case "low":
      return {
        status: TestStatus.Warning,
        messages: [getNetworkAlertContent(NetworkQualityStatus.Poor).message],
      };
    case "very-low":
      return {
        status: TestStatus.Failure,
        messages: [getNetworkAlertContent(NetworkQualityStatus.Unstable).message],
      };
  }
};

const DailyConnectionTest = (props: { dailyCall: DailyCall; userId: string; sessionId: string }) => {
  const { dailyCall, userId } = props;
  const { fullName, isClient, sessionId, appThreadId } = useContext(UserStoreContext);
  const connectionParams: DailyMeetingConnectionParams = {
    dailyCall,
    meetingKey: `test-${sessionId}-${appThreadId}`,
    participantKey: userId,
    user: { id: userId, name: fullName, type: isClient ? "client" : "provider" },
  };
  const { result } = useDailyMeetingConnection(connectionParams);

  useMountEffect(() =>
    logger.postEvent("PageReady", "connection test ready", {
      props: omit(props, "dailyCall"),
      connectionParams: omit(connectionParams, "dailyCall"),
    }),
  );

  const { threshold: networkQuality } = useNetwork();
  const [isNetworkReady, setIsNetworkReady] = useState(false);
  useMountEffect(() => setTimeout(() => setIsNetworkReady(true), 5000));
  const networkTest = {
    id: "network-quality",
    title: "Network Quality",
    ...(isNetworkReady ? getNetworkTestProps(networkQuality) : { status: TestStatus.Loading }),
  };

  const tests = [
    targetTestProps("parallel", result),
    targetTestProps("streaming", result),
    targetTestProps("whiteboard", result),
    targetTestProps("chat", result),
    networkTest,
  ];

  return <ConnectionTest tests={tests} />;
};

const ConnectionTestScreen = () => {
  const dailyCall = useDaily();
  const { userId, sessionId } = useContext(UserStoreContext);
  const redirect = useEventRedirect(logger);

  if (!userId || !sessionId) {
    redirect("/login", "not signed in");
    return <PlaceholderContainer>Invalid Access - Redirecting</PlaceholderContainer>;
  } else if (!dailyCall) {
    return <PlaceholderContainer>Cannot display connection test</PlaceholderContainer>;
  }

  return <DailyConnectionTest dailyCall={dailyCall} userId={userId} sessionId={sessionId} />;
};

export default ConnectionTestScreen;
