import { useState } from "react";
import SupportEmailLink from "@/components/common/elements/SupportEmailLink";
import config from "@/config";

const Footer = ({ shouldFloat = true, floatPosition = 0 }: { shouldFloat?: boolean; floatPosition?: number }) => {
  const [isHover, setIsHover] = useState(false);
  const floatClass = shouldFloat ? `absolute bottom-${floatPosition}` : "";
  return (
    <div
      className={`${floatClass} px-8 pb-2 pt-4 w-full flex flex-row justify-between z-50`}
      onMouseLeave={() => setIsHover(false)}
    >
      <button
        className="relative cursor-pointer flex items-center z-50 italic pointer-events-auto"
        onMouseEnter={() => setIsHover(true)}
        aria-describedby="technical-difficulties-tooltip"
        onKeyDown={e => e.key === "Enter" && setIsHover(true)}
      >
        Technical difficulties?
      </button>
      {isHover && (
        <div
          className="rounded shadow-md p-4 bg-sky-100 absolute bottom-12 left-8 z-50"
          role="tooltip"
          id="technical-difficulties-tooltip"
        >
          <p className="mb-2">
            If you experience issues, please <span className="font-bold">refresh the page</span>.
          </p>
          <p>
            If you are having trouble joining a session and need immediate technical assistance, please call{" "}
            {config.supportLines.phone} (available {config.supportLines.phoneHours}).
          </p>
          <p>
            For all other assistance, please email{" "}
            <SupportEmailLink text={config.supportLines.email} onBlur={() => setIsHover(false)} /> (available{" "}
            {config.supportLines.emailHours}).
          </p>
        </div>
      )}

      <div>
        <a
          className="italic cursor-pointer hover:text-navy"
          target="_blank"
          href="https://www.parallellearning.com/legal/terms-of-use"
          rel="noreferrer"
        >
          Parallel Terms of Use
        </a>
        {config.parallelEnv !== "production" && (
          <div className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded last:mr-0 mr-1">
            ({config.parallelEnv} ENV)
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
