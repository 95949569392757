import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useMountEffect } from "@react-hookz/web";
import { observer } from "mobx-react-lite";
import { useAsyncProcessStatus } from "@parallel/polygon/components/shared/input/status.input";
import ListMenu from "@parallel/polygon/components/shared/layout/ListMenu";
import { FullCenterBox, FullStack } from "@parallel/polygon/components/shared/layout/container";
import { getProgressMenuItems } from "@parallel/polygon/util/progress.util";
import ProgressSidebarInput from "@/components/meeting/activity/progress/ProgressSidebarInput";
import { StoreContext } from "@/stores";

const ProgressSidebarContent = () => {
  const { meetingStore } = useContext(StoreContext);
  const { appointmentProgress, selectedStudentProgress } = meetingStore;

  useMountEffect(() => meetingStore.loadAppointmentProgress());

  const [selectedItemId, setSelectedItemId] = useState<string>();

  const [stagedStudentNote, setStagedStudentNote] = useState("");

  useEffect(() => {
    if (!selectedStudentProgress) return;
    setStagedStudentNote(selectedStudentProgress.note || "");
  }, [selectedStudentProgress]);

  const { status: noteStatus, perform: writeNote } = useAsyncProcessStatus(() =>
    meetingStore.setCurrentStudentNote(stagedStudentNote),
  );

  if (!appointmentProgress) {
    return (
      <FullCenterBox>
        <CircularProgress />
      </FullCenterBox>
    );
  }

  const activeStudent = appointmentProgress.students.find(s => s.studentId === appointmentProgress.selectedStudentId);
  const activeGoals = activeStudent?.goals || [];

  const { menuItems, selection } = getProgressMenuItems(activeGoals, selectedItemId, () => true, {
    goalMode: "static",
  });

  let noteLabel = activeStudent?.studentName;
  if (noteStatus?.value === "loading") noteLabel = "Saving...";
  if (noteStatus?.value === "failure") noteLabel = "Save Failed";

  return (
    <FullStack sx={{ overflowY: "hidden" }}>
      <Box sx={{ width: "100%", flex: "1 1 0%", overflowY: "auto" }}>
        <ListMenu nested compact items={menuItems} selectedKey={selectedItemId} onSelect={setSelectedItemId} />
      </Box>
      <Stack sx={{ p: 2, borderTop: 1, borderColor: "grey.300", flexShrink: 0 }} gap={2}>
        {selection?.objective && <ProgressSidebarInput selectedObjective={selection.objective.record} />}
        <TextField
          label={`Student Note - ${noteLabel}`}
          value={stagedStudentNote}
          onChange={e => setStagedStudentNote(e.target.value)}
          onBlur={writeNote}
          fullWidth
          multiline
          rows={3}
        />
      </Stack>
    </FullStack>
  );
};

export default observer(ProgressSidebarContent);
