import { ExtendedFormSubmission, ExtendedFormTemplate } from "@parallel/vertex/types/form.types";
import { BaseAPI } from "./base.api";

export class FormAPI extends BaseAPI {
  public getFormTemplate = (templateId: string): Promise<ExtendedFormTemplate> =>
    this.instance.get(`/form/template/${templateId}`).then(r => r.data);

  public createFormSubmission = ({
    formId,
    formAnswers,
    appointmentId,
    clientId,
  }: {
    formId: string;
    formAnswers: Record<string, string | number>;
    appointmentId?: string;
    submissionId?: string;
    clientId?: string;
  }): Promise<ExtendedFormSubmission> =>
    this.instance.post(`/form/${formId}/submission`, { formAnswers, appointmentId, clientId }).then(r => r.data);
}
