import { ReactNode } from "react";
import Footer from "./Footer";

const WelcomeLayout = ({
  firstColumn,
  secondColumn,
  bottomContent,
}: {
  firstColumn?: ReactNode;
  secondColumn?: ReactNode;
  bottomContent?: ReactNode;
}) => {
  return (
    <div className="w-full h-full flex flex-col">
      <main className="bg-background w-full h-full flex flex-col overflow-y-auto">
        <div className={`grow flex flex-col mx-auto pt-4 justify-center max-w-[1280px] w-[80%] gap-4`}>
          <div className="flex flex-row gap-4 flex-wrap lg:flex-nowrap">
            <div className="flex flex-col w-full lg:h-full lg:w-1/2">{firstColumn}</div>
            <div className="flex flex-col w-full lg:h-full lg:w-1/2 items-center">{secondColumn}</div>
          </div>
          <div className="w-full">{bottomContent}</div>
        </div>
        <Footer shouldFloat={false} />
      </main>
    </div>
  );
};

export default WelcomeLayout;
