import { useContext, useEffect, useState } from "react";
import { Image, Layer, Stage } from "react-konva";
import { useMeasure } from "@react-hookz/web";
import { refresh } from "ionicons/icons";
import { observer } from "mobx-react-lite";
import { GameConfig, GamePiece } from "@parallel/vertex/types/game.types";
import { IonIconTooltipButton } from "@/components/common/elements/TooltipButton";
import Spinner from "@/components/meeting/activity/game/Spinner";
import { OffsetPixelArea, PixelArea, lockedArea, relativeArea, Position } from "@/interfaces/geometry";
import { ApiStoreContext, loggerContext, StoreContext } from "@/stores";
import { useImageLoad, useImageFetch } from "@/utils/hooks/use-image";
import { initLogger } from "@/utils/logging.utils";

const logger = initLogger("GameDisplay", loggerContext);

const GamePieceDisplay = ({
  piece,
  backgroundArea,
  isDraggable,
  syncPosition,
  onImageLoad,
}: {
  piece: GamePiece;
  backgroundArea: OffsetPixelArea;
  isDraggable: boolean;
  syncPosition: (p: Position) => void;
  onImageLoad: () => void;
}) => {
  const { gameApi } = useContext(ApiStoreContext);

  const pixelPositionUpdated = (x: number, y: number) => {
    syncPosition({
      x: (x - backgroundArea.offset.x) / backgroundArea.width,
      y: (y - backgroundArea.offset.y) / backgroundArea.height,
    });
  };
  const pieceImage = useImageLoad(piece.imageFileName, gameApi.loadPieceImage, () =>
    logger.postEvent("Warning", "game piece image load failed", undefined, "Game"),
  );
  useEffect(() => {
    pieceImage && onImageLoad();
  }, [pieceImage, onImageLoad]);

  if (!pieceImage) return <></>;

  const {
    width,
    height,
    offset: { x, y },
  } = relativeArea(backgroundArea, { ...piece.relativeArea, offset: piece.relativeArea.offset }, pieceImage.area);
  return (
    <Image
      draggable={isDraggable}
      image={pieceImage.html}
      width={width}
      height={height}
      x={x}
      y={y}
      onDragEnd={e => pixelPositionUpdated(e.target.x(), e.target.y())}
      onMouseEnter={e => {
        const container = e.target.getStage()?.container();
        if (container) container.style.cursor = isDraggable ? "move" : "not-allowed";
      }}
      onMouseLeave={e => {
        const container = e.target.getStage()?.container();
        if (container) container.style.cursor = "default";
      }}
    />
  );
};

const _GameBoard = ({ gameConfig, containerArea }: { gameConfig: GameConfig; containerArea: PixelArea }) => {
  const {
    apiStore: { gameApi },
    userStore: { isStaff },
    activityStore,
    participantStore: { localParticipant },
  } = useContext(StoreContext);

  const { spinner, pieces = [] } = activityStore.currGame || {};
  const canMovePieces = isStaff || localParticipant?.gameMode === "write";

  const [pieceLoadState, setPieceLoadState] = useState<Partial<Record<string, boolean>>>({});

  const backgroundImage = useImageFetch(gameConfig.backgroundImageFileName, gameApi.getBackgroundImage, () =>
    logger.postEvent("Warning", "game background image load failed", undefined, "Game"),
  );
  useEffect(() => {
    backgroundImage &&
      pieces.every(p => pieceLoadState[p.id]) &&
      logger.postEvent("ComponentReady", "game board ready", undefined, "Game");
  }, [backgroundImage, pieceLoadState, pieces]);

  if (!backgroundImage) return <></>;

  const backgroundArea = lockedArea(containerArea, backgroundImage.area);
  const {
    width,
    height,
    offset: { x, y },
  } = backgroundArea;

  const spinnerArea = gameConfig.spinnerArea && relativeArea(backgroundArea, gameConfig.spinnerArea);
  const spinnerProps = spinnerArea &&
    spinner && {
      remoteState: spinner,
      dispatchSpinResult: canMovePieces
        ? (n: number) =>
            activityStore.updateSelectedGame(
              {
                spinner: {
                  ...spinner,
                  result: n,
                  spinCount: spinner.spinCount + 1,
                },
              },
              "dispatchSpinResult",
            )
        : undefined,
      radius: spinnerArea.width,
      position: spinnerArea.offset,
    };

  return (
    <>
      <Layer>
        <Image image={backgroundImage.html} width={width} height={height} x={x} y={y} />
        {pieces.map(piece => (
          <GamePieceDisplay
            piece={piece}
            backgroundArea={backgroundArea}
            isDraggable={canMovePieces}
            syncPosition={position => activityStore.updatePiecePosition(piece.id, position)}
            onImageLoad={() => setPieceLoadState({ ...pieceLoadState, [piece.id]: true })}
            key={piece.id}
          />
        ))}
      </Layer>
      {spinnerProps && <Spinner {...spinnerProps} />}
    </>
  );
};

const GameBoard = observer(_GameBoard);

const GameDisplay = ({ gameConfig, className }: { gameConfig: GameConfig; className?: string }) => {
  const { meetingStore, userStore, activityStore } = useContext(StoreContext);
  const [isReloading, setIsReloading] = useState(false);
  const [containerArea, containerRef] = useMeasure<HTMLDivElement>();

  useEffect(() => {
    setIsReloading(true);
    setTimeout(() => {
      setIsReloading(false);
    }, 150);
  }, [meetingStore.leftSidebarType, meetingStore.rightSidebarType]);

  return (
    <div role="region" className={`w-full h-full bg-white rounded-xl justify-center ${className}`} ref={containerRef}>
      {containerArea && !isReloading && (
        <Stage width={containerArea?.width} height={containerArea?.height}>
          <GameBoard gameConfig={gameConfig} containerArea={containerArea} />
        </Stage>
      )}
      {userStore.isStaff && (
        <div className="absolute top-4 right-4">
          <IonIconTooltipButton
            uniqueName="game-reset"
            labelText="Reset Game"
            icon={refresh}
            onClick={() => activityStore.resetGame()}
            className="bg-primary p-2 rounded-full text-white"
          />
        </div>
      )}
    </div>
  );
};

export default observer(GameDisplay);
