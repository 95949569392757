import { useRef, useState } from "react";
import { Layer, Wedge, Group, Text, Circle } from "react-konva";
import { useUpdateEffect } from "@react-hookz/web";
import Konva from "konva";
import { random } from "lodash";
import { SpinnerState } from "@parallel/vertex/types/game.types";
import { mapSequence } from "@parallel/vertex/util/collection.util";
import { Position } from "@/interfaces/geometry";

const BORDER_COLOR = "#22475F";

const SLICE_COLORS = [
  "#5CAA7F", // green
  "#DFA556", // yellow
  "#326DDB", // blue
];

const sliceColor = (i: number, total: number) => {
  // if last slice color will be the same as the first, return the second color instead so every adjacent color is different
  if ((total - 1) % 3 === 0 && i + 1 === total) return SLICE_COLORS[1];
  return SLICE_COLORS[i % 3];
};

type LocalSpinnerState = {
  result: number;
  rotatedDegrees: number;
  isSpinning: boolean;
};

const Spinner = ({
  remoteState: { sliceCount, result, spinCount },
  dispatchSpinResult,
  position,
  radius = 200,
  spinCycles = 3,
}: {
  remoteState: SpinnerState;
  dispatchSpinResult?: (n: number) => void;
  position: Position;
  radius?: number;
  spinCycles?: number;
}) => {
  const wheelGroupRef = useRef<Konva.Group>(null);
  const sliceAngle = 360 / sliceCount;
  const initialRotation = (result - 1) * sliceAngle;

  const [wheelState, setWheelState] = useState<LocalSpinnerState>({
    result,
    rotatedDegrees: initialRotation,
    isSpinning: false,
  });

  const dispatchNewResult =
    (!wheelState.isSpinning &&
      dispatchSpinResult &&
      (() => {
        const newResult = random(1, sliceCount);
        dispatchSpinResult(newResult);
      })) ||
    undefined;

  const spin = (destinationResult: number) => {
    if (!wheelGroupRef.current || wheelState.isSpinning) return;
    setWheelState({ ...wheelState, isSpinning: true });

    // determine amount of slices we have to travel to go from the current `wheelState.result` to the new `destinationResult`
    // spinner can only move in one direction, so if target is less than the current, wrap back around to 1
    const resultDelta =
      destinationResult > wheelState.result
        ? destinationResult - wheelState.result
        : sliceCount - wheelState.result + destinationResult;

    // detemine how many degrees we need to spin the wheel to reach the new `destinationResult`
    const newRotatedDegrees = wheelState.rotatedDegrees + spinCycles * 360 + resultDelta * sliceAngle;

    wheelGroupRef.current.to({
      rotation: newRotatedDegrees,
      duration: 5,
      onFinish: () =>
        setWheelState({
          result,
          rotatedDegrees: newRotatedDegrees,
          isSpinning: false,
        }),
    });
  };

  // spin to new result whenever `spinCount` prop is updated
  useUpdateEffect(() => {
    spin(result);
  }, [spinCount]);

  // spin again after wheel finished spinning if the number that was spun to does not match the `result` prop
  useUpdateEffect(() => {
    if (!wheelState.isSpinning && wheelState.result !== result) spin(result);
  }, [wheelState]);

  const isSelected = (n: number) =>
    !wheelState.isSpinning && wheelState.rotatedDegrees !== initialRotation && result === n;

  return (
    <Layer draggable>
      <Circle x={position.x} y={position.y} radius={radius * 1.1} fill={BORDER_COLOR} />
      <Group x={position.x} y={position.y} ref={wheelGroupRef} rotation={wheelState.rotatedDegrees}>
        {mapSequence(sliceCount, i => (
          <Group rotation={i * sliceAngle * -1} key={i}>
            <Wedge
              angle={sliceAngle}
              rotation={-90 - sliceAngle / 2}
              radius={radius}
              stroke={BORDER_COLOR}
              fill={sliceColor(i, sliceCount)}
            />
            <Text
              text={`${i + 1}`}
              width={radius / 2}
              height={radius / 2}
              align="center"
              verticalAlign="middle"
              x={radius / -4}
              y={radius * (isSelected(i + 1) ? -0.85 : -0.9)}
              fontSize={radius * (isSelected(i + 1) ? 0.5 : 0.3)}
              fill={isSelected(i + 1) ? "white" : "black"}
            />
          </Group>
        ))}
      </Group>
      <Wedge
        x={position.x}
        y={position.y - radius + radius * 0.12}
        rotation={-120}
        angle={60}
        radius={radius * 0.2}
        fill="white"
        stroke={BORDER_COLOR}
      />
      <Group
        onClick={dispatchNewResult}
        onMouseEnter={e => {
          const container = e.target.getStage()?.container();
          if (container) container.style.cursor = dispatchNewResult ? "pointer" : "not-allowed";
        }}
        onMouseLeave={e => {
          const container = e.target.getStage()?.container();
          if (container) container.style.cursor = "default";
        }}
      >
        <Circle
          x={position.x}
          y={position.y}
          fill="white"
          radius={radius * 0.3}
          shadowColor={BORDER_COLOR}
          shadowOpacity={0.4}
          shadowOffset={{ x: radius * 0.03, y: radius * 0.03 }}
        />
        {dispatchNewResult && (
          <Text
            text="SPIN"
            x={position.x - radius * 0.25}
            y={position.y - radius * 0.25}
            width={radius * 0.5}
            height={radius * 0.5}
            align="center"
            verticalAlign="middle"
            fontSize={radius * 0.2}
          />
        )}
      </Group>
    </Layer>
  );
};

export default Spinner;
