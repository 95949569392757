import { useEffect, useState } from "react";
import { noop } from "lodash";
import { PixelArea } from "@/interfaces/geometry";

type Image = { url: string; html: HTMLImageElement; area: PixelArea };

export const useImageLoad = (
  fileName: string,
  resolveLocalUrl: (fileName: string) => Promise<string>,
  onError: () => void = noop,
) => {
  const [image, setImage] = useState<Image>();
  useEffect(() => {
    resolveLocalUrl(fileName).then(url => {
      const html = new Image();
      html.onerror = onError;
      html.onload = () => {
        const area = { width: html.naturalWidth, height: html.naturalHeight };
        setImage({ url, html, area });
      };
      html.src = url;
    });
  }, [fileName]);
  return image;
};

export const useImageFetch = (
  fileName: string,
  fetchImage: (fileName: string) => Promise<Blob>,
  onError: () => void = noop,
) => useImageLoad(fileName, fileName => fetchImage(fileName).then(URL.createObjectURL), onError);
