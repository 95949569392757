import { isUndefined } from "lodash";
import {
  MeetingConnectionMetadata,
  MeetingDisplay,
  MeetingState,
  MeetingParticipant,
  MeetingPrepareRequestDto,
  ActivityState,
} from "@parallel/vertex/types/meeting.types";
import { Override } from "@parallel/vertex/types/shared.types";
import { BaseAPI } from "./base.api";

type MeetingPrepareRequestParams = Override<
  MeetingPrepareRequestDto,
  { showAssessments?: boolean; isProvider?: boolean; isWaiting?: boolean }
>;

export class MeetingAPI extends BaseAPI {
  public prepareMeeting = (
    meetingKey: string,
    request: MeetingPrepareRequestParams,
  ): Promise<MeetingConnectionMetadata> =>
    this.instance
      .post(`/meeting/${meetingKey}/prepare`, {
        ...request,
        showAssessments: !isUndefined(request.showAssessments) && request.showAssessments.toString(),
        isProvider: !isUndefined(request.isProvider) && request.isProvider.toString(),
        isWaiting: !isUndefined(request.isWaiting) && request.isWaiting.toString(),
      })
      .then(r => r.data);

  public getMeetingState = (appointmentId: string): Promise<MeetingState> =>
    this.instance.get(`/meeting/${appointmentId}/state`).then(r => r.data);

  public updateMeetingDisplay = (
    appointmentId: string,
    meetingDisplay: MeetingDisplay,
    userId?: string,
  ): Promise<MeetingState> =>
    this.instance.put(`/meeting/${appointmentId}/state/display?userId=${userId}`, meetingDisplay).then(r => r.data);

  public updateMeetingActivity = (meetingKey: string, activity: ActivityState | null): Promise<MeetingState> =>
    this.instance.put(`/meeting/${meetingKey}/state/activity`, { activity }).then(r => r.data);

  public updateMeetingParticipants = (
    meetingKey: string,
    updates: Record<string, Partial<MeetingParticipant>>,
  ): Promise<MeetingState> => this.instance.put(`/meeting/${meetingKey}/state/participant`, updates).then(r => r.data);
}
