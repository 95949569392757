import {
  ClientLogRequest,
  ClientMeetingEventRequest,
  CompleteConnectionTestBody,
  ConnectionTest,
  CreateConnectionTestBody,
} from "@parallel/vertex/types/logging.types";
import { BaseAPI } from "./base.api";

export class LoggingAPI extends BaseAPI {
  constructor() {
    super({ parseTimestamps: true });
  }

  public postLog = (request: ClientLogRequest): Promise<void> => this.instance.post("/logging/frontend", request);

  public postEvent = (request: ClientMeetingEventRequest): Promise<void> =>
    this.instance.post("/logging/frontend/event", request);

  public createConnectionTest = (body: CreateConnectionTestBody): Promise<ConnectionTest> =>
    this.instance.post("/logging/frontend/connection-test", body).then(r => r.data);

  public completeConnectionTest = (testId: string, body: CompleteConnectionTestBody): Promise<ConnectionTest> =>
    this.instance.put(`/logging/frontend/connection-test/${testId}/complete`, body).then(r => r.data);
}

export const loggingApi = new LoggingAPI();
