import { useEffect, MutableRefObject, useContext } from "react";
import { useMeasure } from "@react-hookz/web";
import { captureMessage } from "@sentry/react";
import { observer } from "mobx-react-lite";
import { AssessmentImageMargin, AssessmentTestSelection } from "@parallel/vertex/types/assessment.types";
import { IRelativeAreaSelection, PixelArea, aspectRatio, isWider, lockedArea } from "@/interfaces/geometry";
import { loggerContext, StoreContext } from "@/stores";
import { useImageFetch } from "@/utils/hooks/use-image";
import { initLogger } from "@/utils/logging.utils";
import ClientStimulusTools from "./ClientStimulusTools";
import ProviderStimulusTools from "./ProviderStimulusTools";

const PEARSON_BOOK_NAMES = ["CELF", "GFTA", "WISC", "Goldman Fristoe Test of Articulation"];
export const PEARSON_COPYRIGHT_MESSAGE =
  "© 2022 NCS Pearson, Inc. All rights reserved. Digital version provided by Parallel Learning under license from NCS Pearson, Inc.";

export type IDivRef = MutableRefObject<HTMLDivElement | null>;

const logger = initLogger("Stimulus", loggerContext);

const getZoom = ({
  requestedZoom,
  imagePath,
  imageMargin,
  imageArea,
  stimulusType,
}: {
  requestedZoom: IRelativeAreaSelection | undefined | null;
  imagePath: string;
  imageMargin?: AssessmentImageMargin;
  imageArea?: PixelArea;
  stimulusType: "client" | "provider";
}) => {
  if (!imageArea) return;

  if (!!imageMargin && (imageMargin.left > imageMargin.right || imageMargin.top > imageMargin.bottom)) {
    captureMessage(`invalid assessment margin - image: ${imagePath} - margins: ${imageMargin}`, "error");
    return;
  }

  const marginZoom = imageMargin
    ? {
        width: (imageMargin.right - imageMargin.left) / imageArea.width,
        height: (imageMargin.bottom - imageMargin.top) / imageArea.height,
        position: {
          x: imageMargin.left / imageArea.width,
          y: imageMargin.top / imageArea.height,
        },
      }
    : {
        width: 1.0,
        height: 1.0,
        position: { x: 0, y: 0 },
      };

  if (!requestedZoom || stimulusType === "provider") return marginZoom;
  else
    return {
      width: marginZoom.width * requestedZoom.width,
      height: marginZoom.height * requestedZoom.height,
      position: {
        x: marginZoom.position.x + requestedZoom.position.x * marginZoom.width,
        y: marginZoom.position.y + requestedZoom.position.y * marginZoom.height,
      },
    };
};

export type StimulusProps = {
  testSelection: AssessmentTestSelection;
  stimulusType: "client" | "provider";
  imagePath: string;
  imageMargin?: AssessmentImageMargin;
  shareMouse?: boolean;
  receiveMouse?: boolean;
  canUserZoom?: boolean;
  className?: string;
};

export const Stimulus = ({
  testSelection,
  stimulusType,
  imagePath,
  imageMargin,
  shareMouse,
  receiveMouse,
  canUserZoom = false,
  className,
}: StimulusProps) => {
  const {
    apiStore: { assessmentApi },
    activityStore,
    assessmentStore,
  } = useContext(StoreContext);

  /**
   * get area dimensions to generate styles of div displaying stimulus image
   */
  const [containerArea, containerRef] = useMeasure<HTMLDivElement>();

  const image = useImageFetch(imagePath.replace("/assessments/", ""), assessmentApi.getAssessmentImage, () =>
    logger.postEvent("Warning", "stimulus image load failed", { imagePath }, "Stimulus"),
  );

  /**
   * zoom stimulus
   *
   * when there is an active zoom selection, display the zoomed stimulus by tweaking the content styles:
   *  a. lock content aspect ratio to selection aspect ratio + fill window
   *  b. transpose + scale the image so the selection shows in the content div
   */
  const assessmentZoom =
    image &&
    getZoom({
      requestedZoom: activityStore.currAssessmentTest?.zoom,
      imagePath,
      imageMargin,
      imageArea: image.area,
      stimulusType,
    });

  useEffect(() => {
    image && assessmentZoom && logger.postEvent("ComponentReady", "stimulus image ready", { imagePath }, "Stimulus");
  }, [image, assessmentZoom]);

  // reset stimulus every time the image changes or component unmounts
  useEffect(() => {
    return () => assessmentStore.resetStimulus();
  }, [imagePath]);

  if (!image) return <></>;

  const contentsArea = containerArea && !!image && lockedArea(containerArea, image.area);

  let contentsStyle = {
    backgroundImage: `url("${image.url}")`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: contentsArea ? `${contentsArea.width}px` : "100%",
    height: contentsArea ? `${contentsArea.height}px` : "100%",
  };

  // prevent rendering image while loading `naturalImageArea` + `containerArea` iff we are zooming the assessment
  let isLoading = !!assessmentZoom;

  if (!!assessmentZoom && !!image && !!containerArea) {
    isLoading = false;
    const zoomArea = {
      width: assessmentZoom.width * image.area.width,
      height: assessmentZoom.height * image.area.height,
    };

    // fit zoom area to window + lock aspect ratio,
    //  and determine the zoom factor from how much the locked side is scaled the natural image
    let contentAreaStyleOverride;
    let zoomFactor;
    if (isWider(zoomArea, containerArea)) {
      const fixedHeight = containerArea.width / aspectRatio(zoomArea);
      contentAreaStyleOverride = {
        width: "100%",
        height: `${fixedHeight}px`,
      };
      zoomFactor = fixedHeight / zoomArea.height;
    } else {
      const fixedWidth = containerArea.height * aspectRatio(zoomArea);
      contentAreaStyleOverride = {
        width: `${fixedWidth}px`,
        height: "100%",
      };
      zoomFactor = fixedWidth / zoomArea.width;
    }

    // use zoomFactor to calculate new `backgroundSize` + `backgroundPosition` styles,
    //  which effectively transpose + zoom the image to fit the zoom selection
    const backgroundArea = {
      width: image.area.width * zoomFactor,
      height: image.area.height * zoomFactor,
    };
    const backgroundPosition = {
      x: assessmentZoom.position.x * image.area.width * zoomFactor,
      y: assessmentZoom.position.y * image.area.height * zoomFactor,
    };
    contentsStyle = {
      ...contentsStyle,
      ...contentAreaStyleOverride,
      backgroundSize: `${backgroundArea.width}px ${backgroundArea.height}px`,
      backgroundPosition: `-${backgroundPosition.x}px -${backgroundPosition.y}px`,
    };
  }

  const copyrightMessage =
    stimulusType === "provider" && !!PEARSON_BOOK_NAMES.find(b => testSelection.bookName?.includes(b))
      ? PEARSON_COPYRIGHT_MESSAGE
      : undefined;

  return (
    <div className={`absolute w-full h-full top-0 ${className}`} ref={containerRef}>
      <div className="bg-white w-full h-full rounded-xl grid place-items-center">
        {!isLoading && (
          <div style={contentsStyle} role="img">
            {stimulusType === "client" && (
              <ClientStimulusTools
                sendMouse={shareMouse || false}
                receiveMouse={receiveMouse || false}
                canUserZoom={canUserZoom && !!image.area}
              />
            )}
          </div>
        )}
      </div>
      {copyrightMessage && <div className="absolute bottom-0 left-0 p-2 text-xs">{copyrightMessage}</div>}
      {stimulusType === "provider" && <ProviderStimulusTools testSelection={testSelection} />}
    </div>
  );
};

export default observer(Stimulus);
