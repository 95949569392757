import { ReactElement, ReactNode, useContext } from "react";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import AppointmentBanner from "@/components/common/content/AppointmentBanner";
import LoadingPlaceholder from "@/components/common/content/LoadingPlaceholder";
import BlockingErrorLayout from "@/components/common/content/error/BlockingErrorLayout";
import { DailyConnectionHelpContent } from "@/components/common/content/error/ConnectionHelpContent";
import SupportPrompt from "@/components/common/content/error/SupportPrompt";
import WelcomeLayout from "@/components/common/layout/WelcomeLayout";
import VideoPlaceholder from "@/components/meeting/video/VideoPlaceholder";
import { StoreContext, loggerContext } from "@/stores";
import { DailyConnectionError } from "@/utils/hooks/use-meeting-connection";
import { initLogger, useEventRedirect } from "@/utils/logging.utils";

const logger = initLogger("AppointmentWaitingRoom", loggerContext);

const _AppointmentColumn = ({
  appointment,
  isConnected,
}: {
  appointment: ExtendedAppointment;
  isConnected: boolean;
}) => {
  const {
    meetingStore: { otherParticipantString },
    userStore: { fullName: userFullName },
  } = useContext(StoreContext);

  const redirect = useEventRedirect(logger);

  return (
    <div className="flex flex-col w-full">
      <div>
        <AppointmentBanner appointment={appointment} />
      </div>

      <div className="w-full py-8">
        <span className="text-sm pb-1">Joining Meeting As</span>
        <button
          className={`flex justify-between items-center bg-white border border-slate-300 hover:border-ocean text-gray-900 px-8 h-12 w-3/4 text-left rounded-lg`}
          onClick={() => redirect("/lobby", "changing appointment user")}
          aria-label="join"
        >
          <span> {userFullName}</span>
          <span className="underline text-ocean hover:font-bold">Change User</span>
        </button>
      </div>
      {isConnected && (
        <h2 className="text-xl font-semibold text-gray-900 px-1">{otherParticipantString} will let you in soon</h2>
      )}
    </div>
  );
};

const AppointmentColumn = observer(_AppointmentColumn);

const PreviewColumn = ({ video }: { video?: ReactElement }) => {
  return (
    <div className="relative w-full h-full">
      <div className="w-full aspect-video">{video || <VideoPlaceholder text="Connecting..." />}</div>
    </div>
  );
};

const AppointmentWaitingRoom = ({
  appointment,
  status,
  localVideo,
  error,
}: {
  appointment: ExtendedAppointment;
  status: "loading" | "connected" | "error";
  localVideo?: ReactElement;
  error?: DailyConnectionError;
}) => {
  let statusContent: ReactNode;
  if (status === "loading") {
    statusContent = (
      <div className="w-full flex flex-col gap-4 justify-center items-center">
        <LoadingPlaceholder text="Connecting to Meeting" />
      </div>
    );
  } else if (error) {
    statusContent = (
      <div className="p-1">
        <BlockingErrorLayout
          header="Connection Error"
          subheader={capitalize(error.message)}
          details={
            error.failedStep ? (
              <DailyConnectionHelpContent failedStep={error.failedStep} />
            ) : (
              <p>
                Please try refreshing the page. <SupportPrompt issueTitle="Unexpected Meeting Connection Issue" />
              </p>
            )
          }
        />
      </div>
    );
  }

  return (
    <WelcomeLayout
      firstColumn={<AppointmentColumn appointment={appointment} isConnected={status === "connected"} />}
      secondColumn={status === "connected" ? <PreviewColumn video={localVideo} /> : undefined}
      bottomContent={statusContent}
    />
  );
};

export default AppointmentWaitingRoom;
