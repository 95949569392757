import { useContext, useEffect, useState } from "react";
import { MoonLoader } from "react-spinners";
import { IonIcon } from "@ionic/react";
import { useDeepCompareEffect } from "@react-hookz/web";
import { useGoogleLogin } from "@react-oauth/google";
import { setContext, setUser } from "@sentry/react";
import { observer } from "mobx-react-lite";
import { AppointmentCodeSignIn } from "@parallel/vertex/types/auth.types";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import { ExtendedUser } from "@parallel/vertex/types/user/user.types";
import { AppointmentCodeSignInError } from "@/api/auth.api";
import AppointmentDisplay from "@/components/common/content/AppointmentBanner";
import Button, { ButtonStyleClass, DEFAULT_BUTTON_CLASS } from "@/components/common/elements/Button";
import Link from "@/components/common/elements/Link";
import Modal from "@/components/common/modal/Modal";
import config from "@/config";
import uTurnLeft from "@/icons/uturn-left-ocean.svg";
import { AlertStoreContext, ApiStoreContext, MeetingStoreContext, UserStoreContext, loggerContext } from "@/stores";
import { initLogger, useEventRedirect } from "@/utils/logging.utils";
import LobbyCommon from "./LobbyCommon";

const logger = initLogger("SingleAppointmentLobby", loggerContext);

export type SingleAppointmentLobbyProps = {
  appointmentCode: string;
  loadingText?: string;
};

const SingleAppointmentLobby = (props: SingleAppointmentLobbyProps) => {
  const { appointmentCode } = props;
  let { loadingText } = props;

  const { authApi } = useContext(ApiStoreContext);
  const userStore = useContext(UserStoreContext);
  const meetingStore = useContext(MeetingStoreContext);
  const alertStore = useContext(AlertStoreContext);

  const goBack = {
    text: "Back to Calendar",
    link: `${config.pathwayClientUrl}/calendar`,
  };

  const redirect = useEventRedirect(logger);
  const meetingQueryParams = { code: appointmentCode };

  const showAppointmentToStudent = async (
    client: ExtendedUser,
    shortCode: string,
    appointment: ExtendedAppointment,
  ) => {
    userStore.setAppointmentCodeUser(client, shortCode);
    meetingStore.setAppointment(appointment);

    redirect(`/waiting-room/appointment/${appointment.appointmentId}/v2`, "client meeting join", {
      queryParams: meetingQueryParams,
    });
  };

  const [noAuthSession, setNoAuthSession] = useState<AppointmentCodeSignIn>();
  useEffect(() => {
    let isFirstRun = true;

    const fetchData = async () => {
      if (!isFirstRun) return;

      const response = await authApi.postAppointmentCodeSignIn(appointmentCode);
      setNoAuthSession(response);

      if (response.user.userId !== userStore.signedInUserId) {
        userStore.setAuthorizedAppointmentCode(response);
      }

      if (response.user.userType === "STUDENT") {
        showAppointmentToStudent(response.user, appointmentCode, response.appointment);
      }
    };

    fetchData().catch(e => {
      if (!isFirstRun) return;
      logger.postEvent("Error", "single appointment lobby data load failed", { props });
      if (e instanceof AppointmentCodeSignInError) {
        alertStore.push(e.message, {
          severity: "warn",
          resolution: "access",
          details: e.isInvalid ? "Appointment-specific links are only valid the day of the appointment" : undefined,
        });
      } else {
        alertStore.push("Unexpected error fetching appointment", { severity: "warn", resolution: "access" });
      }
      redirect(userStore.signedInUserId ? "/lobby" : "/login", "appointment key sign in failed", {
        clearParams: true,
      });
    });

    return () => {
      isFirstRun = false;
    };
  }, []);

  const lobbyContext = {
    appointmentId: noAuthSession?.appointment?.appointmentId,
    userId: noAuthSession?.user?.userId,
  };

  useDeepCompareEffect(() => {
    setUser({
      userId: lobbyContext.userId,
      sessionId: userStore.sessionId,
      appThreadId: userStore.appThreadId,
    });
    setContext("single-appointment-lobby", lobbyContext);
  }, [lobbyContext]);

  const joinButtonClass = "h-12 my-2 px-8 text-left rounded-xl hover:font-bold";

  const showAppointmentToProvider = () => {
    if (!noAuthSession) return;
    meetingStore.setAppointment(noAuthSession.appointment);
    redirect(`/meeting/appointment/${noAuthSession.appointment.appointmentId}/v2`, "provider meeting join", {
      queryParams: meetingQueryParams,
    });
  };

  const [showProviderSignOnModal, setShowProviderSignOnModal] = useState(false);
  const [providerSignOnError, setProviderSignOnError] = useState("");

  const showAppointmentToAuthenticatedProvider = () => {
    if (!noAuthSession) return;
    if (userStore.signedInUserId !== noAuthSession.appointment.provider.userId) {
      setShowProviderSignOnModal(true);
    } else {
      showAppointmentToProvider();
    }
  };

  const performProviderGoogleLogin = useGoogleLogin({
    onSuccess: ({ access_token: accessToken }) => {
      authApi
        .postGoogleSignIn(accessToken)
        .then(u => {
          if (u.userId !== noAuthSession?.appointment.provider.userId) {
            setProviderSignOnError(
              "The user you have signed in as is not the provider of this appointment. Please try again.",
            );
            return;
          }
          userStore.userSignedIn(u);
          showAppointmentToProvider();
        })
        .catch(e => {
          logger.error("error verifying google login response", e);
          setProviderSignOnError(
            "Unexpected error signing in - Please ensure you are signing in as a valid user and contact support if the issue persists",
          );
        });
    },
    onError: () => {
      logger.error("GoogleLogin component error");
      setProviderSignOnError(
        "Unexpected error signing in - Please try again and contact support if the issue persists",
      );
    },
  });

  if (!loadingText && !noAuthSession) loadingText = "Loading Appointment";

  const isPageReady = !!noAuthSession && !loadingText;
  useEffect(() => {
    if (!isPageReady) return;
    logger.postEvent("PageReady", "single appointment lobby ready", { props, noAuthSession });
  }, [isPageReady]);

  return (
    <LobbyCommon loadingText={loadingText}>
      <>
        {!noAuthSession ? (
          <div className="flex flex-row items-center gap-4">
            <MoonLoader size="12px" />
            <p>Loading</p>
          </div>
        ) : (
          <>
            <AppointmentDisplay appointment={noAuthSession.appointment} onClick={undefined} />
            <div className="pt-4">
              <h2 className="text-xl text-black px-1">Select your student account</h2>
              {noAuthSession.appointment.students.map(student => (
                <button
                  className={`bg-ocean text-white outline-white w-3/4  ${joinButtonClass}`}
                  onClick={() => showAppointmentToStudent(student, student.shortCode, noAuthSession.appointment)}
                  key={student.userId}
                  aria-label="join"
                >
                  {student.fullName}
                </button>
              ))}

              <h3 className="mt-4 text-black text-md">Join as Parallel Provider</h3>
              <button
                className={`bg-white border border-ocean text-ocean outline-ocean w-3/4  ${joinButtonClass}`}
                onClick={showAppointmentToAuthenticatedProvider}
                aria-label="join"
              >
                {noAuthSession.appointment.provider.fullName}
              </button>
              {showProviderSignOnModal && (
                <Modal
                  title="Parallel Provider Sign-In"
                  onClose={() => setShowProviderSignOnModal(false)}
                  submitButtonText="Cancel"
                >
                  <div className="flex flex-col gap-4 w-96">
                    <Button
                      text="Sign In With Google"
                      icon={
                        <img
                          alt="Google Login Button"
                          src={`${config.staticAssetsUrl}/logos/google-logo.svg`}
                          style={{ height: 20, width: 20, marginRight: 4 }}
                        />
                      }
                      onClick={performProviderGoogleLogin}
                      styleClass={ButtonStyleClass.Secondary}
                      className={`${DEFAULT_BUTTON_CLASS} w-full`}
                    />
                    {providerSignOnError && <p className="text-failure-dark">{providerSignOnError}</p>}
                  </div>
                </Modal>
              )}
            </div>
            <div className="pt-8 text-ocean gap-2 flex">
              <span className="text-black">Not your appointment?</span>{" "}
              <Link
                className="text-navy italic flex justify-center items-center gap-1 hover:cursor-pointer hover:font-bold"
                href={goBack.link}
              >
                <IonIcon color="blue" icon={uTurnLeft} /> {goBack.text}
              </Link>
            </div>
          </>
        )}
      </>
    </LobbyCommon>
  );
};

export default observer(SingleAppointmentLobby);
