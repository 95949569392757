import { BaseAPI } from "./base.api";

export class AssessmentAPI extends BaseAPI {
  public getAssessmentAudio = (filePath: string): Promise<File> =>
    this.instance
      .get(`/assessment/audio/${filePath}`, { responseType: "blob" })
      .then(r => new File([r.data], filePath));

  public getAssessmentImage = (filePath: string): Promise<File> =>
    this.instance
      .get(`/assessment/image/${filePath}`, { responseType: "blob" })
      .then(r => new File([r.data], filePath));
}
