import { useMountEffect } from "@react-hookz/web";
import { noop } from "lodash";
import Footer from "@/components/common/layout/Footer";
import LoginForm from "@/components/login/LoginForm";
import config from "@/config";
import { loggerContext } from "@/stores";
import { initLogger } from "@/utils/logging.utils";

const logger = initLogger("LoginScreen", loggerContext);

const LoginScreen = ({ isPreAuth }: { isPreAuth?: boolean }) => {
  useMountEffect(() => logger.postEvent("PageReady", "login screen ready", { isPreAuth }));
  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full grow p-8 flex flex-row gap-10 place-content-center">
        <LoginForm onSuccess={isPreAuth ? noop : undefined} />
        <div className="w-[700px] hidden lg:grid place-items-center">
          <img src={`${config.staticAssetsUrl}/images/two-brains.png`} alt="Two Faces" />
        </div>
      </div>
      <Footer shouldFloat={false} />
    </div>
  );
};

export default LoginScreen;
