import { useState, useEffect, useContext } from "react";
import { IonIcon } from "@ionic/react";
import { useUnmountEffect } from "@react-hookz/web";
import * as Icon from "ionicons/icons";
import { indexOf } from "lodash";
import { observer } from "mobx-react-lite";
import Select from "@/components/common/elements/Select";
import { AlertStoreContext, loggerContext } from "@/stores";
import { AlertId } from "@/stores/alert.store";
import { digitalClock } from "@/utils";
import { initLogger } from "@/utils/logging.utils";

type AudioStatus = "stopped" | "paused" | "playing";

export type AudioTrack = {
  setStatusListener: (fn: (status: AudioStatus) => unknown) => unknown;
  start: () => unknown;
  resume: () => unknown;
  stop: () => unknown;
  pause: () => unknown;
  backToStart: () => unknown;
  terminate: () => unknown;
  durationSeconds: number;
  currTimeSeconds: () => number;
};

const logger = initLogger("AudioPlayer", loggerContext);

const Button = ({
  label,
  icon,
  onClick,
  isEnabled,
}: {
  label: string;
  icon: string;
  onClick: () => void;
  isEnabled: boolean;
}) => {
  const className = isEnabled ? "bg-primary cursor-pointer" : "bg-gray-400";
  return (
    <div
      onClick={isEnabled ? onClick : undefined}
      className={`h-12 w-12 flex justify-center items-center rounded-full text-white text-xl ${className}`}
      role="button"
      aria-label={label}
      tabIndex={0}
    >
      <IonIcon icon={icon} className="fill-white" />
    </div>
  );
};

export type AudioPlayerProps = {
  trackPaths: string[];
  track?: AudioTrack;
  initiateTrack: (path: string) => Promise<unknown>;
  clearTrack: () => unknown;
  mic: { isEnabled: boolean; toggle: () => unknown };
};

/**
 * Use this component to load a list of remote audio files accessible through parallel-server
 *  and provide controls that simulatneously play the file locally and publish to the active Agora RTC meeting
 */
const AudioPlayer = ({ trackPaths, track, initiateTrack, clearTrack, mic }: AudioPlayerProps) => {
  const alertStore = useContext(AlertStoreContext);

  const [trackPath, setTrackPath] = useState(trackPaths[0]);
  const [audioStatus, setAudioStatus] = useState<AudioStatus>("stopped");
  const [error, setError] = useState<string>();

  useEffect(() => setTrackPath(trackPaths[0]), [trackPaths]);

  const pickNextTrack = () => {
    const currIndex = indexOf(trackPaths, trackPath);
    const nextTrackPath = trackPaths[currIndex + 1];
    nextTrackPath && setTrackPath(nextTrackPath);
  };
  const audioStatusUpdated = (track: AudioTrack, nextOnStop: boolean) => (newStatus: AudioStatus) => {
    setAudioStatus(newStatus);
    if (newStatus === "stopped") {
      if (nextOnStop) pickNextTrack();
      else track.setStatusListener(audioStatusUpdated(track, true));
    }
  };

  const playAudio = () => {
    if (!track) return;
    try {
      audioStatus === "paused" ? track.resume() : track.start();
      setAudioStatus("playing");
    } catch (e) {
      logger.error("error playing audio", { track }, e);
      setError("error playing audio");
    }
  };

  const stopAudio = () => {
    if (!track) return;
    try {
      track.setStatusListener(audioStatusUpdated(track, false));
      track.stop();
      setAudioStatus("stopped");
    } catch (e) {
      logger.error("error stopping audio", { track }, e);
      track.setStatusListener(audioStatusUpdated(track, false));
      setError("error stopping audio");
    }
  };

  const pauseAudio = () => {
    if (!track) return;
    try {
      track.pause();
      setAudioStatus("paused");
    } catch (e) {
      logger.error("error pausing audio", { track }, e);
      setError("error pausing audio");
    }
  };

  const restartAudio = () => {
    if (!track) return;
    try {
      track.backToStart();
      playAudio();
      setAudioStatus("playing");
    } catch (e) {
      logger.error("error restarting audio", { track }, e);
      setError("error restarting audio");
    }
  };

  const terminateCurrTrack = () => {
    track?.terminate();
    stopAudio();
  };

  // when active `trackPath` state changes, terminate + unset the current track and load the new one
  useEffect(() => {
    (async () => {
      terminateCurrTrack();
      clearTrack();
      await initiateTrack(trackPath);
      setError(undefined);
      setAudioStatus("stopped");
    })();
  }, [trackPath]);

  useEffect(() => {
    if (!track) return;
    track.setStatusListener(audioStatusUpdated(track, true));
  });

  useUnmountEffect(terminateCurrTrack);

  // mute mic audio input when audio plays
  const [autoMuteAlertId, setAutoMuteAlertId] = useState<AlertId>();
  useEffect(() => {
    if (audioStatus === "playing" && mic.isEnabled) {
      mic.toggle();
      setAutoMuteAlertId(
        alertStore.push("Mic automatically muted", {
          severity: "info",
          autoClose: false,
          details:
            "Your mic has been muted while assessment audio plays to prevent echoes. If you want to talk to the client, stop the audio track.",
        }),
      );
    } else if (autoMuteAlertId) {
      if (!mic.isEnabled) {
        mic.toggle();
        alertStore.clear(autoMuteAlertId);
      }
      setAutoMuteAlertId(undefined);
    }
  }, [audioStatus]);

  const trackName = (path: string) => path.split("/").reverse()[0];

  let statusText = error ? `Error: ${error}` : "Loading...";
  if (track) {
    if (audioStatus === "playing") statusText = "Playing";
    else if (audioStatus === "paused")
      statusText = `Paused - ${digitalClock(track.currTimeSeconds())} / ${digitalClock(track.durationSeconds)}`;
    else if (audioStatus === "stopped") statusText = `Stopped - 0:00 / ${digitalClock(track.durationSeconds)}`;
  }

  return (
    <>
      {trackPaths.length === 1 ? (
        <p role="heading">{trackName(trackPaths[0])}</p>
      ) : (
        <Select
          value={trackPath}
          onChange={setTrackPath}
          options={trackPaths.map(p => ({ value: p, label: trackName(p) }))}
        />
      )}
      <div className="flex flex-row gap-2">
        <Button label="play" icon={Icon.play} onClick={playAudio} isEnabled={!!track && audioStatus !== "playing"} />
        <Button label="pause" icon={Icon.pause} onClick={pauseAudio} isEnabled={!!track && audioStatus === "playing"} />
        <Button label="stop" icon={Icon.stop} onClick={stopAudio} isEnabled={!!track && audioStatus === "playing"} />
        <Button
          label="refresh"
          icon={Icon.refresh}
          onClick={restartAudio}
          isEnabled={!!track && audioStatus === "paused"}
        />
      </div>
      <span role="status" aria-label="status">
        {statusText}
      </span>
    </>
  );
};

export default observer(AudioPlayer);
