import { ReactNode } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { IonIcon } from "@ionic/react";
import { refresh } from "ionicons/icons";
import Button, { DEFAULT_BUTTON_CLASS } from "@/components/common/elements/Button";
import { classNames } from "@/utils";

const BlockingErrorLayout = ({
  header,
  subheader,
  details,
}: {
  header: string;
  subheader?: string;
  details: ReactNode;
}) => {
  return (
    <div
      className="p-4 flex flex-row items-start gap-4 bg-white border-l-4 border-red"
      role="region"
      aria-label="error"
    >
      <div className="rounded-full p-2 bg-red text-white">
        <XMarkIcon className="w-5 h-5" />
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex-1">
          <p className="text-2xl font-semibold" role="heading">
            {header}
          </p>
          {subheader && (
            <p className="text-gray-600" role="subheading">
              {subheader}
            </p>
          )}
        </div>
        {details}
        <Button
          text="Reload"
          label="reload"
          icon={<IonIcon icon={refresh} />}
          onClick={() => window.location.reload()}
          className={classNames(DEFAULT_BUTTON_CLASS, "w-48")}
        />
      </div>
    </div>
  );
};

export default BlockingErrorLayout;
