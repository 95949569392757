import { ReactNode } from "react";
import { ActivityState } from "@parallel/vertex/types/meeting.types";
import DailyMeetingWindow, { DailyWindowProps } from "@/components/meeting/daily/DailyMeetingWindow";
import { ActivityStoreContext, UserStoreContext } from "@/stores";
import { useStoreContextProps } from "@/utils/hooks/use-store-props";
import Stimulus, { StimulusProps } from "../activity/assessment/Stimulus";
import StimulusOverlay from "../activity/assessment/StimulusOverlay";
import GameDisplay from "../activity/game/GameDisplay";
import Whiteboard from "../activity/whiteboard/Whiteboard";

const useActivityWindow = ({
  currActivity,
  primaryParticipant,
}: {
  currActivity?: ActivityState;
  primaryParticipant?: DailyWindowProps;
}): { window?: ReactNode; usesPrimaryParticipant?: boolean } => {
  const { isClient } = useStoreContextProps(UserStoreContext, ["isClient"]);
  const { gameMetadata } = useStoreContextProps(ActivityStoreContext, ["gameMetadata"]);

  if (currActivity?.type === "whiteboard") return { window: <Whiteboard /> };

  if (currActivity?.type === "game") {
    const selectedConfig = gameMetadata.find(g => g.id === currActivity.gameId);
    if (selectedConfig) return { window: <GameDisplay gameConfig={selectedConfig} /> };
  }

  if (currActivity?.type === "assessment") {
    const clientStimulusProps: StimulusProps | undefined = currActivity.image.client
      ? {
          testSelection: currActivity,
          stimulusType: "client",
          imagePath: currActivity.image.client,
          imageMargin: currActivity.image.clientMargin,
        }
      : undefined;
    if (isClient) {
      // client only ever sees the client stimulus (if allowed + present)
      if (!currActivity.clientStimulus.showClient || !clientStimulusProps) return {};
      return {
        window: (
          <Stimulus
            {...clientStimulusProps}
            shareMouse={currActivity.shareClientMouse}
            receiveMouse={currActivity.shareProviderMouse}
          />
        ),
      };
    } else {
      // provider always sees staff stimulus - overlay w/ either client stimulus (if allowed) or primary window when present
      const providerStimulus = (
        <Stimulus
          testSelection={currActivity}
          stimulusType="provider"
          imagePath={currActivity.image.staff}
          imageMargin={currActivity.image.staffMargin}
        />
      );

      let overlayForeground: ReactNode | undefined;
      let usesPrimaryParticipant = false;
      if (currActivity.clientStimulus.showProvider && !!clientStimulusProps) {
        overlayForeground = (
          <Stimulus
            {...clientStimulusProps}
            shareMouse={currActivity.shareProviderMouse}
            receiveMouse={currActivity.shareClientMouse}
            canUserZoom={true}
          />
        );
      } else if (primaryParticipant) {
        overlayForeground = <DailyMeetingWindow {...primaryParticipant} isPinningDisabled={true} />;
        usesPrimaryParticipant = true;
      }

      const window = overlayForeground ? (
        <StimulusOverlay background={providerStimulus} foreground={overlayForeground} />
      ) : (
        providerStimulus
      );

      return { window, usesPrimaryParticipant };
    }
  }

  return {};
};

export default useActivityWindow;
