// allows us to take a screenshot using the built-in browser media device API
export const takeScreenshot = async () => {
  const stream = await navigator.mediaDevices.getDisplayMedia();
  const track = stream.getVideoTracks()[0];

  const imageCapture = new ImageCapture(track);
  if (!imageCapture) throw new Error("error taking screenshot - unsupported browser");

  const screenshotBitmap = await imageCapture.grabFrame();
  track.stop();

  const canvas = document.createElement("canvas");
  canvas.width = screenshotBitmap.width;
  canvas.height = screenshotBitmap.height;

  const ctx = canvas.getContext("2d");
  ctx?.drawImage(screenshotBitmap, 0, 0, screenshotBitmap.width, screenshotBitmap.height);

  const img = canvas.toDataURL();
  const res = await fetch(img);
  const buffer = await res.arrayBuffer();

  const screenshotFilename = `telehealth_screenshot_${new Date()}.jpg`;
  const file = new File([buffer], screenshotFilename, {
    type: "image/jpeg",
  });

  const link = document.createElement("a");
  link.hidden = true;
  link.download = screenshotFilename;
  link.href = URL.createObjectURL(file);

  link.click();
  link.remove();
};
