import { ReactNode } from "react";
import { capitalize } from "lodash";
import SupportPrompt from "@/components/common/content/error/SupportPrompt";
import Link from "@/components/common/elements/Link";
import config from "@/config";
import { DailyConnectionStep } from "@/utils/hooks/use-meeting-connection";

export type ConnectionFailure = DailyConnectionStep | "device-permissions" | "device-connection";

const DAILY_CONNECTION_STEP_STRINGS: Record<DailyConnectionStep, string> = {
  prepare: "Parallel Meeting Data Service",
  join: "Media Streaming Service",
};

const ConnectionHelpContentLayout = ({
  descriptionLines,
  resolutionSteps,
  supportDetails,
}: {
  descriptionLines: string[];
  resolutionSteps: ReactNode[];
  supportDetails: { issueTitle: string; failureCode?: string };
}) => {
  return (
    <>
      <div>
        {descriptionLines.map((lineText, i) => (
          <p key={i}>{lineText}</p>
        ))}
      </div>
      <div>
        <ol className="list-decimal list-inside">
          {resolutionSteps.map((step, i) => (
            <li key={i}>{step}</li>
          ))}
        </ol>
      </div>
      <p>
        <SupportPrompt issueTitle={supportDetails.issueTitle} failedProcessCode="" />
      </p>
    </>
  );
};

const DevicePermissionsHelpContent = ({ userMediaError }: { userMediaError?: string }) => (
  <ConnectionHelpContentLayout
    descriptionLines={[
      "Your browser has blocked access to your microphone or camera. To fix the issue we suggest taking the following steps:",
    ]}
    resolutionSteps={[
      "Click the camera blocked icon in your browser's address bar, enable access, and refresh the page",
      <span key="permission-support-link">
        If this icon is not showing, view{" "}
        <Link href={`${config.techSupportLink}#h_01HBXT0PD6S0ZYRYZDTPKCNNB2`}>this page</Link> for more help
      </span>,
    ]}
    supportDetails={{ issueTitle: "Device Permission Issue", failureCode: userMediaError }}
  />
);

const DeviceConnectionHelpContent = ({ userMediaError }: { userMediaError?: string }) => (
  <ConnectionHelpContentLayout
    descriptionLines={[
      "There was an error attempting to connect to your microphone or camera. To fix the issue, we suggest taking the following steps:",
    ]}
    resolutionSteps={[
      "Close any other windows/apps that may be using your camera or microphone",
      "If you are using an external microphone or camera, disconnect then reconnect them",
      "Reload this page",
      "Check your system settings to ensure that your microphone and camera are enabled",
      "Reboot your device",
      "Check for any anti-virus software running on your device that could be preventing webcam access",
      <span key="connection-support-link">
        View <Link href={config.techSupportLink}>this guide</Link> for more help troubleshooting common
        microphone/camera issues
      </span>,
    ]}
    supportDetails={{ issueTitle: "Device Connection Issue", failureCode: userMediaError }}
  />
);

export const GenericNetworkHelpContent = ({
  errorDescription,
  issueTitle,
  failureCode,
}: {
  errorDescription: string;
  issueTitle: string;
  failureCode?: string;
}) => (
  <ConnectionHelpContentLayout
    descriptionLines={[
      `${errorDescription},`,
      "which is typically caused by an incorrectly configured firewall or some other network failure.",
      "To fix the issue, we suggest taking the following steps:",
    ]}
    resolutionSteps={[
      "Reload this page",
      <span key="network-support-link">
        View <Link href={config.techSupportLink}>this guide</Link> for help troubleshooting common network errors
      </span>,
    ]}
    supportDetails={{ issueTitle, failureCode }}
  />
);

export const DailyConnectionHelpContent = ({ failedStep }: { failedStep: DailyConnectionStep }) => {
  const target = DAILY_CONNECTION_STEP_STRINGS[failedStep];
  return (
    <GenericNetworkHelpContent
      errorDescription={`There was an error connecting to the ${target}`}
      issueTitle={`${target} Connection Issue`}
      failureCode={`${capitalize(failedStep)}Error`}
    />
  );
};

const ConnectionHelpContent = ({
  failure,
  userMediaError,
}: {
  failure: ConnectionFailure;
  userMediaError?: string;
}) => {
  switch (failure) {
    case "device-permissions":
      return <DevicePermissionsHelpContent userMediaError={userMediaError} />;
    case "device-connection":
      return <DeviceConnectionHelpContent userMediaError={userMediaError} />;
    default:
      return <DailyConnectionHelpContent failedStep={failure} />;
  }
};

export default ConnectionHelpContent;
