import { useContext } from "react";
import { MoonLoader } from "react-spinners";
import { IonIcon } from "@ionic/react";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import * as Icons from "ionicons/icons";
import { noop } from "lodash";
import { observer } from "mobx-react-lite";
import { IconTooltipButton, IonIconTooltipButton } from "@/components/common/elements/TooltipButton";
import config from "@/config";
import ChatIcon from "@/icons/chat-bubble.svg";
import { StoreContext } from "@/stores";
import { LeftSidebarType } from "@/stores/meeting.store";
import { classNames } from "@/utils";
import WindowControlMenu from "./WindowControlMenu";
import { useClientFeedbackSurvey } from "./use-client-feedback-survey";

const ACTIVITY_BUTTON_STYLE_CLASSES = `h-10 w-10 bg-white rounded-full text-2xl cursor-pointer flex justify-center items-center border-primary border text-primary hover:border-secondary`;

const determineSurveyButttonProps = (activeParticipantKeys: string[], receivingSurveyKeys: string[]) => {
  return {
    labelText:
      !activeParticipantKeys || activeParticipantKeys.length === 0
        ? "Cannot send surveys when there are no other active participants"
        : receivingSurveyKeys.length > 0
          ? "Sending ROM Survey"
          : "Send ROM Survey",
    icon:
      receivingSurveyKeys.length > 0 ? <MoonLoader size="15px" color="blue" /> : <IonIcon icon={Icons.statsChart} />,
    disabled: !activeParticipantKeys || activeParticipantKeys.length === 0,
    className: classNames(
      activeParticipantKeys && activeParticipantKeys.length > 0
        ? "border-primary hover:border-secondary text-primary"
        : "border-neutral-icon text-neutral-icon",
      "h-10 w-10 bg-white rounded-full text-2xl cursor-pointer flex justify-center items-center border",
    ),
  };
};

type MediaProps = {
  isEnabled: boolean;
  toggle: () => void;
};

export type MeetingFooterProps = {
  video: MediaProps;
  audio: MediaProps;
  screenshare: MediaProps;
  disconnect: () => void;
  activeParticipantKeys: string[];
};

const MeetingFooter = ({ video, audio, screenshare, disconnect, activeParticipantKeys }: MeetingFooterProps) => {
  const {
    meetingStore,
    userStore: { isStaff },
  } = useContext(StoreContext);

  const { appointment, appointmentFlags } = meetingStore;

  const { sendSurvey, receivingSurveyKeys } = useClientFeedbackSurvey({ activeParticipantKeys });

  const leftSidebarTooltip = ({
    activity,
    label,
    onText = "Open",
    offText = "Close",
  }: {
    activity: LeftSidebarType;
    label: string;
    onText?: string;
    offText?: string;
  }) => {
    const verb = meetingStore.leftSidebarType === activity ? offText : onText;
    return `${verb} ${label}`;
  };

  const buttonClass = (
    highlight: boolean = false,
    secondaryHighlight: boolean = false,
    widthClass: string = "w-10",
  ) => {
    const colorClass = highlight ? (secondaryHighlight ? "bg-secondary" : "bg-red") : "bg-primary";
    return `${widthClass} h-10 flex justify-center items-center rounded-full text-white text-xl cursor-pointer ${colorClass} shrink-0`;
  };

  const videoButtonLabel = video.isEnabled ? "Turn Off Cam" : "Turn On Cam";
  const audioButtonLabel = audio.isEnabled ? "Turn Off Mic" : "Turn On Mic";
  const screenshareButtonLabel = screenshare.isEnabled ? "Stop Sharing" : "Share Screen";

  return (
    <div className="flex w-full justify-stretch py-2 px-4 bg-white border-t border-slate-300">
      <div className="flex items-center space-x-4 md:w-1/3 sm:w-[45%]">
        <IonIconTooltipButton
          labelText={leftSidebarTooltip({ activity: "chat", label: "Chat" })}
          icon={ChatIcon}
          className={ACTIVITY_BUTTON_STYLE_CLASSES}
          onClick={() => meetingStore.toggleLeftSidebar("chat")}
          uniqueName={"chat-button"}
          badgeNumber={meetingStore.unreadMessageCount}
        />
        {isStaff && (
          <>
            <IonIconTooltipButton
              labelText={leftSidebarTooltip({ activity: "activities", label: "Activities" })}
              icon={Icons.easel}
              className={ACTIVITY_BUTTON_STYLE_CLASSES}
              onClick={() => meetingStore.toggleLeftSidebar("activities")}
              uniqueName={"activity-button"}
            />
            <IconTooltipButton
              labelText={leftSidebarTooltip({
                activity: "client-goals",
                label: "Session Notes",
                onText: "View",
                offText: "Hide",
              })}
              icon={<EmojiEventsIcon />}
              uniqueName="client-goals-tooltip"
              className={ACTIVITY_BUTTON_STYLE_CLASSES}
              onClick={
                appointmentFlags?.showPathwayProgressSidebar
                  ? () => meetingStore.toggleLeftSidebar("client-goals")
                  : noop
              }
              disabled={!appointment}
              href={
                appointment && !appointmentFlags?.showPathwayProgressSidebar
                  ? `${config.pathwayClientUrl}/calendar/appointment/${appointment.appointmentId}/progress`
                  : undefined
              }
            />
            {sendSurvey && (
              <IconTooltipButton
                uniqueName="client-survey-tooltip"
                onClick={() => sendSurvey()}
                {...determineSurveyButttonProps(activeParticipantKeys, receivingSurveyKeys)}
              />
            )}
          </>
        )}
      </div>
      <div className="flex justify-center items-center space-x-4 last:grow md:w-1/3 sm:w-[45%]">
        <IonIconTooltipButton
          icon={video.isEnabled ? Icons.videocam : Icons.videocamOff}
          labelText={videoButtonLabel}
          uniqueName={videoButtonLabel}
          className={buttonClass(!video.isEnabled)}
          onClick={video.toggle}
        />
        <IonIconTooltipButton
          labelText={audioButtonLabel}
          uniqueName={audioButtonLabel}
          icon={audio.isEnabled ? Icons.mic : Icons.micOff}
          className={buttonClass(!audio.isEnabled)}
          onClick={audio.toggle}
        />
        <IonIconTooltipButton
          labelText={screenshareButtonLabel}
          uniqueName={screenshareButtonLabel}
          icon={screenshare.isEnabled ? Icons.eyeOff : Icons.desktopOutline}
          className={buttonClass(screenshare.isEnabled)}
          onClick={() => screenshare.toggle()}
        />
        <IconTooltipButton
          labelText="End Call"
          icon={<IonIcon icon={Icons.call} className="text-xl" style={{ transform: "rotate(135deg)" }} />}
          uniqueName="End Call"
          aria-labelledby="End Call"
          className={buttonClass(true, false, "w-14")}
          onClick={disconnect}
        />
      </div>
      <div className="flex justify-end items-center space-x-4 md:w-1/3 sm:w-[10%]">
        {isStaff && <WindowControlMenu />}
      </div>
    </div>
  );
};

export default observer(MeetingFooter);
