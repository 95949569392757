import DailyDocumentCameraWindow from "@/components/meeting/daily/DailyDocumentCameraWindow";
import DailyParticipant, { DailyParticipantBaseProps } from "@/components/meeting/daily/DailyParticipant";

type DailySessionProps = DailyParticipantBaseProps & { type: "session" };

type DailyDocumentCameraProps = {
  type: "document-camera";
  videoTrack: MediaStreamTrack;
  displayName: string;
};

export type DailyWindowProps = DailySessionProps | DailyDocumentCameraProps;

const DailyMeetingWindow = (props: DailyWindowProps & { isPinningDisabled: boolean; activeSpeakerId?: string }) => {
  if (props.type === "session") return <DailyParticipant {...props} />;
  if (props.type === "document-camera") return <DailyDocumentCameraWindow {...props} />;
  return <></>;
};

export default DailyMeetingWindow;
