import { useDaily } from "@daily-co/daily-react";
import { isString } from "lodash";
import DailyMeeting from "@/components/meeting/daily/DailyMeeting";
import { AppointmentMeetingLayout } from "@/components/meeting/layout/MeetingLayout";
import { MeetingPlaceholderContainer } from "@/components/meeting/layout/MeetingPlaceholder";
import { loggerContext } from "@/stores";
import { useValidAppointmentParamAccess } from "@/utils/hooks/use-valid-access";
import { initLogger } from "@/utils/logging.utils";

const logger = initLogger("AppointmentMeetingScreen", loggerContext);

const AppointmentMeetingScreen = () => {
  const dailyCall = useDaily();
  const appointmentPropsOrError = useValidAppointmentParamAccess();

  if (isString(appointmentPropsOrError)) {
    return <MeetingPlaceholderContainer>{appointmentPropsOrError}</MeetingPlaceholderContainer>;
  } else if (!dailyCall) {
    logger.error("daily call is undefined");
    return <MeetingPlaceholderContainer>Unexpected error loading meeting video chat</MeetingPlaceholderContainer>;
  }

  return (
    <AppointmentMeetingLayout>
      <DailyMeeting
        dailyCall={dailyCall}
        meetingKey={appointmentPropsOrError.appointment.appointmentId}
        loadingText="Connecting to Appointment"
        {...appointmentPropsOrError}
      />
    </AppointmentMeetingLayout>
  );
};

export default AppointmentMeetingScreen;
