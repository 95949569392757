import { ReactNode, useContext } from "react";
import { useEventListener } from "@react-hookz/web";
import { LeftSidebar, RightSidebar } from "@/components/meeting/layout/sidebar/MeetingSidebar";
import { StoreContext } from "@/stores";
import { classNames } from "@/utils";

type MeetingWindowLayoutProps = {
  primary?: ReactNode;
  secondary?: ReactNode;
  footer: ReactNode;
};

/** everything but the header */
const MeetingWindowLayout = ({ primary, secondary, footer }: MeetingWindowLayoutProps) => {
  const {
    participantStore,
    userStore: { isClient },
  } = useContext(StoreContext);

  useEventListener(
    document,
    "visibilitychange",
    () => isClient && participantStore.updateLocalParticipant({ hasLostFocus: document.hidden }),
  );

  return (
    <div className="w-full h-full bg-background flex flex-row">
      <LeftSidebar />
      <div className="h-full flex-1 flex flex-col overflow-y-hidden">
        <div
          className={classNames(
            "w-full flex-1 overflow-y-hidden p-2 flex gap-2",
            !!primary && !!secondary ? "flex-row" : "flex-col",
          )}
        >
          {primary && <div className={classNames("relative h-full", secondary ? "w-4/5" : "w-full")}>{primary}</div>}
          {secondary && (
            <div className={classNames("relative h-full", primary ? "w-1/5" : "w-full")}>{secondary}</div>
          )}
        </div>
        <div className="w-full">{footer}</div>
      </div>
      <RightSidebar />
    </div>
  );
};

export default MeetingWindowLayout;
