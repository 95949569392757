import { useContext } from "react";
import { observer } from "mobx-react-lite";
import Modal from "@/components/common/modal/Modal";
import { GameMenuOptions } from "@/components/meeting/activity/game/GameMenu";
import { MeetingStoreContext } from "@/stores";

const SelectGameModal = () => {
  const meetingStore = useContext(MeetingStoreContext);
  return (
    <Modal title="Select Game" onClose={() => meetingStore.toggleSelectingGame()} containerStyle={{ minWidth: "30%" }}>
      <GameMenuOptions />
    </Modal>
  );
};

export default observer(SelectGameModal);
