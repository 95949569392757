import { ReactElement } from "react";
import { MoonLoader } from "react-spinners";
import { DailyWaitingParticipant } from "@daily-co/daily-js";
import { useDaily, useParticipantIds, useParticipantProperty, useWaitingParticipants } from "@daily-co/daily-react";
import { IonIcon } from "@ionic/react";
import * as Icons from "ionicons/icons";
import { noop } from "lodash";
import { observer } from "mobx-react-lite";
import Button, { ButtonStyleClass } from "@/components/common/elements/Button";
import ArrowLeftRectangle from "@/icons/arrow-left-rectangle.svg";
import MinusCircleIcon from "@/icons/minus-circle.svg";
import { decodeUserName, shouldDisplayParticipant } from "@/utils/daily.utils";

type ParticipantStatus = "waiting" | "connecting" | "connected" | "dropped" | "kicked" | "error";

type ParticipantAction = {
  text: string;
  icon?: string;
  fn: () => any;
};

const ParticipantActionButton = ({ text, fn, icon }: ParticipantAction) => (
  <button onClick={fn} className="text-ocean hover:underline flex flex-row justify-center items-center gap-1 text-sm">
    {!!icon && <IonIcon className="h-4 w-4" icon={icon} />}
    {text}
  </button>
);

type ParticipantActionProps = {
  status: ParticipantStatus;
  admit?: () => unknown;
  reject?: () => unknown;
};

const ParticipantActions = ({ status, admit = noop, reject = noop }: ParticipantActionProps) => {
  switch (status) {
    case "waiting":
      return (
        <>
          <ParticipantActionButton text="Admit" fn={admit} icon={ArrowLeftRectangle} />
          <ParticipantActionButton text="Reject" fn={reject} icon={Icons.closeCircleOutline} />
        </>
      );
    case "connecting":
      return (
        <>
          <MoonLoader size="12px" />
          <span>Connecting</span>
        </>
      );
    case "connected":
      return <ParticipantActionButton text="Kick" fn={reject} icon={MinusCircleIcon} />;
    case "kicked":
      return <ParticipantActionButton text="Reinstate" fn={admit} />;
    case "error":
      return (
        <>
          <IonIcon icon={Icons.alertCircle} className="w-5 h-5 text-red" />
          <span className="text-red">Connection Error</span>
        </>
      );
    default:
      return <></>;
  }
};

type ParticipantItemProps = ParticipantActionProps & { name: string };

const ParticipantItem = (props: ParticipantItemProps) => {
  return (
    <div className="flex flex-col">
      <div className="w-full flex flex-row justify-between gap-2">
        <div className="flex flex-row items-center gap-2">
          <IonIcon className={`text-neutral-icon h-4 w-4`} icon={Icons.person} />
          <p className="py-1 flex justify-start items-center text-sm break-words">{props.name}</p>
        </div>
        <div className="flex flex-row gap-2 place-items-center mx-2">
          <ParticipantActions {...props} />
        </div>
      </div>
    </div>
  );
};

const ParticipantManagerContentLayout = ({
  participantItems,
  admitAll,
}: {
  participantItems?: ReactElement;
  admitAll?: () => unknown;
}) => {
  return (
    <div className="w-full h-full p-4 flex flex-col">
      {participantItems || <span className="flex justify-center">Waiting for others to join</span>}
      {admitAll && (
        <div className="mt-4 w-full grid place-items-center fill-white">
          <Button
            text="Admit All"
            styleClass={ButtonStyleClass.Custom}
            className="text-ocean hover:underline"
            icon={<IonIcon className="text-white" icon={ArrowLeftRectangle} />}
            onClick={admitAll}
          />
        </div>
      )}
    </div>
  );
};

const ConnectedDailyParticipantItem = ({ dailySessionId }: { dailySessionId: string }) => {
  const dailyCall = useDaily();
  const [dailyUserName] = useParticipantProperty(dailySessionId, ["user_name"]);
  if (!dailyUserName) return <></>; // this is undefined immediately after the participant leaves
  return (
    <ParticipantItem
      status="connected"
      name={decodeUserName(dailyUserName).userName}
      admit={noop}
      reject={() => dailyCall?.updateParticipant(dailySessionId, { eject: true })}
    />
  );
};

const WaitingDailyParticipantItem = ({
  dailyParticipant,
  admit,
  reject,
}: {
  dailyParticipant: DailyWaitingParticipant;
  admit: () => unknown;
  reject: () => unknown;
}) => {
  return (
    <ParticipantItem
      status="waiting"
      name={decodeUserName(dailyParticipant.name).userName}
      admit={admit}
      reject={reject}
    />
  );
};

const ParticipantManagerContent = () => {
  const remoteSessionIds = useParticipantIds({
    filter: participant => !participant.local && shouldDisplayParticipant(participant),
    sort: "joined_at",
  });
  const { waitingParticipants, grantAccess, denyAccess } = useWaitingParticipants();
  const waitingSessionIds = waitingParticipants.map(p => p.id);

  const participantItems = (
    <>
      {waitingParticipants.map(p => (
        <WaitingDailyParticipantItem
          dailyParticipant={p}
          admit={() => grantAccess(p.id)}
          reject={() => denyAccess(p.id)}
          key={p.id}
        />
      ))}
      {remoteSessionIds.map(id => (
        <ConnectedDailyParticipantItem dailySessionId={id} key={id} />
      ))}
    </>
  );

  let admitAll: (() => unknown) | undefined = undefined;
  if (waitingSessionIds.length > 0) {
    admitAll = () => grantAccess("*");
  }

  return <ParticipantManagerContentLayout participantItems={participantItems} admitAll={admitAll} />;
};

export default observer(ParticipantManagerContent);
