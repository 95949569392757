import config from "@/config";

export const EMAIL_SUPPORT = `email support at ${config.supportLines.email}`;
export const SUPPORT_FOLLOW_UP = `if the issue persists ${EMAIL_SUPPORT}`;

export const ERROR_FOLLOW_UP_TEXT = {
  contactSupport: SUPPORT_FOLLOW_UP,
  retryOrContactSupport: `please try again, ${SUPPORT_FOLLOW_UP}`,
  refreshOrContactSupport: `please try refreshing the page, ${SUPPORT_FOLLOW_UP}`,
};
