import { TelehealthLogger } from "@/utils/logging.utils";

enum ConnectionState {
  Ready = "ready",
  Connecting = "connecting",
  Connected = "connected",
  Reconnecting = "reconnecting",
  PendingDisconnect = "pending-disconnect",
  Disconnecting = "disconnecting",
  Error = "error",
}

export const isStateValid =
  (getCurrState: () => ConnectionState, logger: TelehealthLogger) =>
  (processName: string, validStates: ConnectionState[]) => {
    const currState = getCurrState();
    if (!validStates.includes(currState)) {
      logger.warn(`invalid state for ${processName}`, {
        processName,
        currentState: getCurrState(),
        validStates,
      });
      return false;
    }
    return true;
  };

export default ConnectionState;
