import Draggable from "react-draggable";
import { AssessmentTestSelection } from "@parallel/vertex/types/assessment.types";
import DailyAudioPlayer from "@/components/meeting/daily/DailyAudioPlayer";

/**
 * A placeholder stopwatch component.
 */
const StopWatch = () => {
  return (
    <Draggable
      defaultPosition={{
        x: -10,
        y: -100,
      }}
    >
      <div className="absolute bg-white p-2 rounded-xl shadow-xl border-2 bottom-0 right-0">Stopwatch!</div>
    </Draggable>
  );
};

const ProviderStimulusTools = ({ testSelection }: { testSelection: AssessmentTestSelection }) => {
  return (
    <>
      {!!testSelection.audio && testSelection.audio.length > 0 && (
        <Draggable defaultPosition={{ x: -10, y: -10 }}>
          <div className="absolute bottom-0 right-0 bg-white p-2 rounded-xl flex flex-col items-center gap-2 z-50 shadow-xl border-2 border-slate-300">
            <DailyAudioPlayer trackPaths={testSelection.audio} />
          </div>
        </Draggable>
      )}
      {testSelection.stopwatch && <StopWatch />}
    </>
  );
};

export default ProviderStimulusTools;
