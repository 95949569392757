export type Message = {
  text: string;
  isError?: boolean;
};

export enum DailyMessageType {
  AssessmentMouseClick = "assessment-mouse-click",
  AssessmentMouseMove = "assessment-mouse-move",
  MeetingActivityUpdated = "meeting-activity-updated",
  MeetingDisplayUpdated = "meeting-display-updated",
  MeetingParticipantUpdated = "meeting-participant-updated",
  ProviderClosedChat = "provider-closed-chat",
  StartDocumentCamera = "start-document-camera",
  StopDocumentCamera = "stop-document-camera",
}
