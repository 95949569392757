import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useDaily } from "@daily-co/daily-react";
import { useMountEffect } from "@react-hookz/web";
import { AppointmentCodeSignInError } from "@/api/auth.api";
import DailyMeetingWatch from "@/components/meeting/daily/DailyMeetingWatch";
import { MeetingPlaceholderContainer } from "@/components/meeting/layout/MeetingPlaceholder";
import LoadingScreen from "@/screens/LoadingScreen";
import { ApiStoreContext, MeetingStoreContext, UserStoreContext, loggerContext } from "@/stores";
import { initLogger } from "@/utils/logging.utils";

const logger = initLogger("AppointmentMeetingWatchScreen", loggerContext);

const AppointmentMeetingWatchScreen = () => {
  const { appointmentCode } = useParams<{ appointmentCode: string }>();

  const { authApi } = useContext(ApiStoreContext);
  const userStore = useContext(UserStoreContext);
  const meetingStore = useContext(MeetingStoreContext);
  const { appointment } = meetingStore;

  const dailyCall = useDaily();

  const [error, setError] = useState("");

  useMountEffect(() => {
    if (!appointmentCode) {
      setError("missing required `appointmentCode` in url params");
      return;
    }
    authApi
      .postAppointmentCodeSignIn(appointmentCode)
      .then(response => {
        userStore.setWatcherUser(response);
        meetingStore.setAppointment(response.appointment);
      })
      .catch(error => {
        const isInvalid = error instanceof AppointmentCodeSignInError && error.isInvalid;
        const message = isInvalid
          ? `appointment code ${appointmentCode} is invalid`
          : "unexpected error fetching appointment";
        setError(message);
        logger.error(message, { appointmentCode }, error);
      });
  });

  if (!appointment && !error) {
    return <LoadingScreen />;
  } else if (!dailyCall) {
    logger.error("daily call is undefined");
    return <MeetingPlaceholderContainer>Daily call is undefined</MeetingPlaceholderContainer>;
  }

  const content = !appointment ? (
    <MeetingPlaceholderContainer>{error}</MeetingPlaceholderContainer>
  ) : (
    <DailyMeetingWatch dailyCall={dailyCall} meetingKey={appointment.appointmentId} />
  );

  return <main className="relative w-full h-full bg-background">{content}</main>;
};

export default AppointmentMeetingWatchScreen;
