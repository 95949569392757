import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { MeetingStoreContext, ParticipantStoreContext, UserStoreContext, alertStore } from "@/stores";
import { useStoreContextProps } from "@/utils/hooks/use-store-props";
import { RemoteRequest } from "@/utils/types";

export const useClientFeedbackSurvey = ({ activeParticipantKeys }: { activeParticipantKeys: string[] }) => {
  const { appointment, feedbackFormTemplateId, connectedMeetingKey } = useStoreContextProps(MeetingStoreContext, [
    "appointment",
    "feedbackFormTemplateId",
    "connectedMeetingKey",
  ]);
  const { isStaff } = useStoreContextProps(UserStoreContext, ["isStaff"]);
  const {
    participants,
    sendClientFeedbackSurvey,
    sync: syncParticipantState,
  } = useStoreContextProps(ParticipantStoreContext, ["participants", "sendClientFeedbackSurvey", "sync"]);

  const appointmentId = appointment?.appointmentId;
  const feedbackSurveyNotifyAt = appointment && appointment.endTime.minus({ minutes: 5 });

  const [feedbackSurveyAlertTimeout, setFeedbackSurveyAlertTimeout] = useState<NodeJS.Timeout>();
  const [surveyRequest, setSurveyRequest] = useState<RemoteRequest>();

  // the number of active participants who have not received surveys
  const canReceiveSurveyKeys = activeParticipantKeys?.filter(
    k => !participants?.[k]?.feedbackSurveyStatus?.receivedAtEpochMs,
  );

  useEffect(() => {
    if (!isStaff) return;

    const haveAllParticipantsReceivedSurvey = canReceiveSurveyKeys.length === 0;

    // don't show alert if active participant count is equal to the
    // number of participants who've already received a survey
    if (!feedbackSurveyNotifyAt || haveAllParticipantsReceivedSurvey) {
      clearTimeout(feedbackSurveyAlertTimeout);
      return;
    }

    // clear any existing scheduled alerts before setting a new alert
    if (feedbackSurveyAlertTimeout) clearTimeout(feedbackSurveyAlertTimeout);

    const timeInMsToShowFeedbackSurveyAlert = feedbackSurveyNotifyAt.diff(DateTime.utc()).milliseconds;

    if (timeInMsToShowFeedbackSurveyAlert > 0) {
      setFeedbackSurveyAlertTimeout(
        setTimeout(() => {
          alertStore.updateFeedbackSurveyAlert(() => sendClientFeedbackSurvey(canReceiveSurveyKeys), appointmentId);
        }, timeInMsToShowFeedbackSurveyAlert),
      );
    }
  }, [activeParticipantKeys]);

  if (!feedbackFormTemplateId) return { sendSurvey: undefined, receivingSurveyKeys: [] };

  const sendSurvey = () => {
    clearTimeout(surveyRequest?.timeout);
    const newRequest = {
      id: uuidv4(),
      timeout: setTimeout(() => {
        setSurveyRequest(undefined);
        connectedMeetingKey && syncParticipantState(connectedMeetingKey);
      }, 10000),
    };
    setSurveyRequest(newRequest);
    sendClientFeedbackSurvey(activeParticipantKeys);
  };

  return { receivingSurveyKeys: surveyRequest ? canReceiveSurveyKeys : [], sendSurvey };
};
