import { useRef } from "react";
import { useClickOutside, useKeyboardEvent } from "@react-hookz/web";
import FocusTrap from "focus-trap-react";

interface IModal {
  title?: string;
  children?: React.ReactNode;
  closeButton?: boolean;
  closeButtonText?: string;
  submitButtonText?: string;
  onClose?: () => void;
  onSubmit?: () => void;
  containerStyle?: any;
  submitButtonStyleOverride?: string;
  closeButtonStyleOverride?: string;
  submitButtonOverride?: React.ReactNode;
}

const BUTTON_STYLE = "py-2 px-4 font-semibold text-sm rounded-md";

const ModalContainer = ({
  title,
  children,
  onClose,
  onSubmit,
  closeButton,
  closeButtonText,
  submitButtonText = "Save Changes",
  containerStyle,
  submitButtonOverride,
  submitButtonStyleOverride,
  closeButtonStyleOverride,
}: IModal) => {
  useKeyboardEvent(
    e => e.key === "Escape",
    () => !!onClose && onClose(),
  );
  const containerRef = useRef(null);
  useClickOutside(containerRef, () => !!onClose && onClose());
  return (
    <div className="relative my-6 mx-auto bg-white" style={containerStyle} ref={containerRef}>
      <div className="w-full h-full border-0 rounded-lg shadow-lg relative flex flex-col outline-none focus:outline-none">
        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
          <h3 className="text-2xl font-semibold">{title}</h3>
          {onClose && (
            <button className="p-1 ml-auto bg-transparent border-0" onClick={onClose} aria-label="close-modal">
              <span className="h-6 w-6 text-2xl text-black font-semibold flex justify-center items-center">×</span>
            </button>
          )}
        </div>
        <div className="w-full flex-1 p-6 relative flex flex-col gap-4 overflow-y-auto">
          <div className="w-full flex-1 relative overflow-y-auto">{children}</div>
          {((onClose && closeButton) || onSubmit) && (
            <div className="flex items-center justify-end rounded-b gap-4">
              {onClose && (closeButton || closeButtonText) && (
                <button
                  className={
                    closeButtonStyleOverride
                      ? `${closeButtonStyleOverride}`
                      : `${BUTTON_STYLE} bg-failure text-white uppercase hover:bg-failure-dark`
                  }
                  type="button"
                  onClick={onClose}
                >
                  {closeButtonText || "Close"}
                </button>
              )}

              {onSubmit &&
                (submitButtonOverride ? (
                  submitButtonOverride
                ) : (
                  <button
                    className={
                      submitButtonStyleOverride
                        ? `${submitButtonStyleOverride}`
                        : `${BUTTON_STYLE} bg-success text-white uppercase hover:bg-success-dark`
                    }
                    type="button"
                    onClick={onSubmit}
                  >
                    {submitButtonText}
                  </button>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Modal = (props: IModal & { isForm?: boolean }) => {
  const containerNode =
    props.isForm === false ? (
      <ModalContainer {...props} />
    ) : (
      <FocusTrap>
        <ModalContainer {...props} />
      </FocusTrap>
    );
  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-40 outline-none focus:outline-none"
        role="dialog"
      >
        {containerNode}
      </div>
      <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
    </>
  );
};

export default Modal;
