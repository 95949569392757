import { ReactNode } from "react";
import { Listbox } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { classNames } from "@/utils";

export type SelectOption = {
  name?: string;
  value: string;
};

const Select = ({
  label,
  showLabel = false,
  value,
  options,
  onChange,
  defaultValue,
  placeholder,
  disabled,
  className,
}: {
  label?: string;
  showLabel?: boolean;
  value?: string;
  options: SelectOption[];
  onChange: (v: string) => void;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}) => {
  const selected = value || (placeholder ? "placeholder" : defaultValue);
  return (
    <div>
      {showLabel && (
        <label htmlFor={label} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <select
        id={label}
        name={label}
        value={selected}
        onChange={e => onChange(e.target.value)}
        className={classNames(
          "mt-1 block rounded-md border-gray-300 py-2 pl-3 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm",
          disabled ? "bg-gray-100" : "bg-white cursor-pointer",
          className,
        )}
        disabled={disabled}
      >
        {placeholder && (
          <option value="placeholder" disabled>
            {placeholder}
          </option>
        )}
        {options.map(({ name, value }) => (
          <option value={value} key={value}>
            {name || value}
          </option>
        ))}
      </select>
    </div>
  );
};

export type StyledSelectOption<A> = {
  content: ReactNode;
  value: A;
  className?: string;
  selectedClassName?: string;
};

type StyledSelectProps<A> = {
  options: StyledSelectOption<A>[];
  value: A;
  onChange: (v: A) => void;
  containerClassName?: string;
};

export const StyledSelect = <A,>({ options, value, onChange, containerClassName }: StyledSelectProps<A>) => {
  const selectedOption = options.find(o => o.value === value);
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div className={classNames("relative overflow-visible", containerClassName)}>
          <Listbox.Button
            className={classNames(
              "w-full flex flex-row gap-6 items-center border p-2 rounded-md",
              selectedOption?.className,
              selectedOption?.selectedClassName,
            )}
          >
            <span className="grow">{selectedOption?.content}</span>
            <ChevronDownIcon className="w-3 h-3" />
          </Listbox.Button>
          {open && (
            <Listbox.Options
              className={classNames("w-full absolute z-10 bg-white shadow-lg cursor-pointer divide-y border")}
            >
              {options.map((option, i) => (
                <Listbox.Option
                  value={option.value}
                  className={({ active }) => classNames("p-2", option.className, active ? "bg-gray-100" : "")}
                  key={i}
                >
                  {option.content}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          )}
        </div>
      )}
    </Listbox>
  );
};

export default Select;
