import { DOCUMENT_CAMERA_TRACK_NAME } from "@/utils/daily.utils";
import ParticipantWindowControls, { Participant } from "../layout/controls/ParticipantWindowControls";
import DailyParticipantMedia from "./DailyParticipantMedia";

const DailyDocumentCameraWindow = ({
  videoTrack,
  displayName,
  isPinningDisabled,
}: {
  videoTrack: MediaStreamTrack;
  displayName: string;
  isPinningDisabled: boolean;
}) => {
  const participantProps: Participant = {
    participantKey: DOCUMENT_CAMERA_TRACK_NAME,
    isRemote: true,
    isScreenshare: false,
    isDocumentCamera: true,
    displayName,
  };
  return (
    <div className="w-full h-full relative group">
      <DailyParticipantMedia video={videoTrack} isRemote={true} />
      {participantProps && (
        <ParticipantWindowControls participant={participantProps} isPinningDisabled={isPinningDisabled} />
      )}
    </div>
  );
};

export default DailyDocumentCameraWindow;
