import { useContext } from "react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { IonIcon } from "@ionic/react";
import { format } from "fecha";
import * as Icons from "ionicons/icons";
import { observer } from "mobx-react-lite";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import Link from "@/components/common/elements/Link";
import SupportEmailLink from "@/components/common/elements/SupportEmailLink";
import config from "@/config";
import { MeetingStoreContext } from "@/stores";
import AlertHistoryContent from "./AlertHistoryContent";

const _AppointmentHelpContent = ({
  appointment: { title, startTime, endTime, provider, students },
}: {
  appointment: ExtendedAppointment;
}) => {
  const subheaderClassName = "mt-2 text-sm";
  return (
    <div className="p-4">
      <h1 className="pb-2 text-base text-md font-semibold leading-6 text-slate-700 italic">
        Current Telehealth Appointment
      </h1>

      <h3 className={subheaderClassName}>Appointment Type</h3>
      <p className="mt-1">{title}</p>

      <h3 className={subheaderClassName}>Appointment Time</h3>
      <p className="mt-1">
        {format(startTime.toJSDate(), "h:mm A")} - {format(endTime.toJSDate(), "h:mm A")}
      </p>

      <h3 className={subheaderClassName}>Provider</h3>
      <p className="py-1 flex justify-start items-center gap-1">
        <IonIcon className="text-slate-700 h-5 w-5" icon={Icons.personCircleOutline} />
        {provider.fullName}
      </p>

      <h3 className={subheaderClassName}>{students.length > 1 ? "Students" : "Student"}</h3>
      {students.map(student => (
        <p className="py-1 flex justify-start items-center gap-1" key={student.userId}>
          <IonIcon className="text-neutral-icon h-4 w-4 px-0.5" icon={Icons.person} />
          {student.fullName}
        </p>
      ))}
    </div>
  );
};

const AppointmentHelpContent = observer(_AppointmentHelpContent);

const HelpContent = ({ showAppointment }: { showAppointment?: boolean }) => {
  const { appointment } = useContext(MeetingStoreContext);
  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-1 flex flex-col divide-y divide-slate-200 overflow-y-auto">
        <div className="p-4">
          <p className="mb-2">
            For troubleshooting common technical issues, please take a look at{" "}
            <Link href={`${config.techSupportLink}`}>this troubleshooting page</Link>
          </p>
          <p className="mb-2">
            If you are having trouble joining a session and need immediate technical assistance, please call (909)
            643-8683 (available 9am - 5pm ET).
          </p>
          <div className="flex flex-row items-center no-underline gap-1">
            <EnvelopeIcon className="w-5 h-5 text-ocean " />
            <SupportEmailLink className="no-underline text-ocean" />
          </div>
        </div>
        {showAppointment && appointment && <AppointmentHelpContent appointment={appointment} />}
        <div className="w-full flex-1">
          <AlertHistoryContent />
        </div>
      </div>
      <div className="flex flex-end justify-center items-end py-4 border-t border-slate-200">
        <Link href="https://www.parallellearning.com/legal/terms-of-use">Parallel Terms of Use</Link>
      </div>
    </div>
  );
};

export default observer(HelpContent);
