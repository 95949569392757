type VideoPlayerDimensions = { width: number | string; height: number | string };

const LANDSCAPE_VIDEO_ASPECT_RATIO = 16 / 9;
const PORTRAIT_VIDEO_ASPECT_RATIO = 4 / 3;

// The min and max aspect ratio thresholds for video windows
const MAX_ASPECT_RATIO = 2.5;
const MIN_ASPECT_RATIO = 0.65;

// If the video height is greater than width, a 4:3 aspect ratio providers
// a better crop with more visibility
const pickDefaultAspectRatio = (containerWidth: number, containerHeight: number): number =>
  containerWidth < containerHeight ? PORTRAIT_VIDEO_ASPECT_RATIO : LANDSCAPE_VIDEO_ASPECT_RATIO;

// In most circumstances, a container aspect ratio between 0.65 and 2.5 provides good visibility and cropping.
// However, if the container width or height drops below 150px, the video will be cropped too much
// and will negatively impact visibility. In these cases, we use either a 16:9 aspect ratio or 4:3
// aspect ratio, depending on whether container height is greater than container width
export const determineVideoAspectRatio = (containerWidth: number, containerHeight: number): VideoPlayerDimensions => {
  const currentAspectRatio = containerWidth / containerHeight;

  if (
    currentAspectRatio < MAX_ASPECT_RATIO &&
    currentAspectRatio > MIN_ASPECT_RATIO &&
    (containerHeight >= 150 || containerWidth >= 150) &&
    containerWidth > containerHeight
  )
    return { height: "100%", width: "100%" };
  else {
    // If the video height is greater than width, a 4:3 aspect ratio providers
    // a better crop with more visibility
    const defaultAspectRatio = pickDefaultAspectRatio(containerWidth, containerHeight);

    return currentAspectRatio > defaultAspectRatio
      ? { height: containerHeight, width: containerHeight * defaultAspectRatio }
      : { width: containerWidth, height: containerWidth / defaultAspectRatio };
  }
};

// For screenshare, take into account the target aspect ratio
// to prevent cropping, since we never want to crop screenshare windows
export const determineScreenshareAspectRatio = (
  containerWidth: number,
  containerHeight: number,
  sourceAspectRatio?: number,
) => {
  const containerAspectRatio = containerWidth / containerHeight;

  const defaultAspectRatio = pickDefaultAspectRatio(containerWidth, containerHeight);

  const targetAspectRatio = sourceAspectRatio ? sourceAspectRatio : defaultAspectRatio;

  return containerAspectRatio > targetAspectRatio
    ? { height: containerHeight, width: containerHeight * targetAspectRatio }
    : { width: containerWidth, height: containerWidth / targetAspectRatio };
};
