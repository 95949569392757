import axios from "axios";
import { runInAction } from "mobx";
import { NexusBaseAPI } from "@parallel/vertex/util/nexus.base.api";
import config from "@/config";
import { AlertStore } from "@/stores/alert.store";
import { UserStore } from "@/stores/user.store";
import { TelehealthLogger } from "@/utils/logging.utils";

export type BaseApiOptions = {
  alertStore?: AlertStore;
  userStore?: UserStore;
  logger?: TelehealthLogger;
};

export class BaseAPI extends NexusBaseAPI {
  public constructor({
    alertStore,
    userStore,
    logger,
    parseTimestamps,
  }: BaseApiOptions & { parseTimestamps?: boolean } = {}) {
    super({ baseURL: config.nexusServerUrl, parseTimestamps }, axios);
    if (alertStore)
      this.handleAuthFailure = () => {
        runInAction(() => (alertStore.isSessionExpired = true));
        logger?.postEvent("Warning", "authorization failure", {
          userId: userStore?.userId,
          sessionId: userStore?.sessionId,
          appThreadId: userStore?.appThreadId,
          authorization: userStore?.auth,
          userType: userStore?.user?.userType,
        });
      };
    if (userStore) this.getAuthKey = () => userStore.auth?.token;
  }
}
