import { useContext, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react-lite";
import { ExtendedFormTemplate } from "@parallel/vertex/types/form.types";
import Button from "@/components/common/elements/Button";
import ProgressStepper from "@/components/common/elements/ProgressStepper";
import SmileySlider from "@/components/common/elements/SmileySlider";
import { StoreContext, loggerContext } from "@/stores";
import { initLogger } from "@/utils/logging.utils";

const logger = initLogger("ClientFeedbackSurvey", loggerContext);

const ClientFeedbackSurvey = ({
  feedbackFormTemplateId,
  appointmentId,
  userId,
}: {
  feedbackFormTemplateId: string;
  appointmentId?: string;
  userId: string;
}) => {
  const {
    participantStore,
    apiStore: { formApi },
  } = useContext(StoreContext);

  const [formTemplate, setFormTemplate] = useState<ExtendedFormTemplate>();
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  useEffect(() => {
    setFormTemplate(undefined);
    formApi.getFormTemplate(feedbackFormTemplateId).then(setFormTemplate);
  }, [feedbackFormTemplateId]);

  const [feedbackFormAnswers, setFeedbackFormAnswers] = useState<Record<string, string>>({});
  const [currentSliderValue, setCurrentSliderValue] = useState<string | number>(5);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    // This useEffect addresses a race condition where the form submission is triggered before the last question is answered
    const submitFeedbackForm = (answers: Record<string, string>) =>
      formApi
        .createFormSubmission({ clientId: userId, formId: feedbackFormTemplateId, formAnswers: answers, appointmentId })
        .then(() => participantStore.submittedClientFeedbackSurvey())
        .catch(error =>
          logger.operationError("submitFeedbackForm", error, { userId, feedbackFormTemplateId, answers }),
        );

    if (isSubmitting) {
      submitFeedbackForm(feedbackFormAnswers);
    }
  }, [
    currentStepIndex,
    feedbackFormAnswers,
    userId,
    feedbackFormTemplateId,
    appointmentId,
    formApi,
    participantStore,
    isSubmitting,
  ]);

  const formQuestions = formTemplate?.formQuestions || [];
  const numberOfQuestions = formQuestions?.length || 0;

  return (
    <div className="flex flex-col items-center gap-16">
      {!formTemplate ? (
        <div>
          <div className="flex flex-row pt-4">
            <ClipLoader size={65} />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-16">
          {numberOfQuestions && currentStepIndex < numberOfQuestions && (
            <SmileySlider
              key={formQuestions[currentStepIndex]?.key}
              inputLabel={formQuestions[currentStepIndex].question}
              onChangeCallback={(answer: string) => {
                setCurrentSliderValue(answer);
              }}
            />
          )}
          <Button
            text={currentStepIndex < numberOfQuestions - 1 ? "Next Question" : "Submit Form"}
            icon={<ArrowRightCircleIcon />}
            onClick={() => {
              setFeedbackFormAnswers({
                ...feedbackFormAnswers,
                [formQuestions[currentStepIndex].key]: currentSliderValue.toString(),
              });
              if (currentStepIndex < numberOfQuestions - 1) {
                setCurrentStepIndex(currentStepIndex + 1);
              }

              if (currentStepIndex === numberOfQuestions - 1) {
                setIsSubmitting(true);
              }
            }}
          />
          <div className="mt-16 flex justify-center">
            <ProgressStepper totalSteps={numberOfQuestions} currentStep={currentStepIndex} />
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(ClientFeedbackSurvey);
