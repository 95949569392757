import { useContext, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { PropagateLoader } from "react-spinners";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { observer } from "mobx-react-lite";
import config from "@/config";
import { StoreContext, loggerContext } from "@/stores";
import { initLogger, useEventRedirect } from "@/utils/logging.utils";
import useQuery from "@/utils/use-query";

type LoginFormInput = {
  email: string;
  password: string;
};

type LoginFormProps = {
  providerOnlyLoginProps?: { providerEmail?: string; providerName?: string };
  onSuccess?: () => void;
};

const logger = initLogger("LoginForm", loggerContext);

const LoginForm = ({ onSuccess, providerOnlyLoginProps: { providerEmail, providerName } = {} }: LoginFormProps) => {
  const {
    userStore,
    apiStore: { authApi },
  } = useContext(StoreContext);

  const redirect = useEventRedirect(logger);
  const redirectUrl = useQuery().get("redirect");

  const [errorText, setErrorText] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const providerOnlySignOn = !!providerEmail && providerEmail.length > 0 ? true : false;

  const onSubmit: SubmitHandler<LoginFormInput> = async data => {
    try {
      setIsLoading(true);
      const attemptCount = userStore.signInAttempt();
      const user = await authApi.postPasswordSignIn(data.email, data.password, attemptCount).then(
        logger.operationSuccessHandler("postHealthieSignIn", {
          email: data.email,
        }),
      );
      userStore.userSignedIn(user);
      onSuccess ? onSuccess() : redirect(redirectUrl || "/lobby", "login successful");
    } catch (e: any) {
      logger.warn(`error signing in`, { email: data.email, apiError: e.apiError?.message }, e);
      const msg = e.message.replace(/<.*?>/g, "");
      setIsLoading(false);
      setErrorText(msg);
    }
  };

  const { register, handleSubmit } = useForm<LoginFormInput>({
    defaultValues: {
      email: providerEmail ? providerEmail : undefined,
    },
  });

  return (
    <div className="w-96 shrink-0 flex flex-col place-content-center">
      <img src={`${config.staticAssetsUrl}/logos/parallel-wordmark-black.svg`} alt="Parallel Logo" className="w-64" />
      {providerOnlySignOn && <h2 className="text-ocean mt-6 text-xl font-semibold">Welcome {providerName}!</h2>}
      <h2 className={`text-3xl font-extrabold text-gray-900 ${providerOnlySignOn ? "mt-1" : "mt-6"}`}>
        Sign in to your account
      </h2>
      <p className="mt-2 p-1 text-sm text-gray-600 bg-yellow-50">
        Use the same email address and password you use to sign in to the{" "}
        <a href="https://app.parallellearning.com">
          <span className="underline font-semibold">Parallel homepage</span>.
        </a>
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 mt-6" aria-label="login">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email address
          </label>
          <div className="mt-1">
            <input
              {...register("email", { required: true })}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="email"
              disabled={providerOnlySignOn ? true : false}
            />
            {providerOnlySignOn && (
              <p className="flex justify-start text-xs text-ocean italic mt-1" role="alert">
                Not you? Close this screen and sign in as a client.
              </p>
            )}
          </div>
        </div>

        <div className="space-y-1">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            Password
          </label>
          <div className="mt-1">
            <input
              type="password"
              {...register("password")}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="password"
            />
          </div>
        </div>

        {errorText && (
          <div
            id="loginerrors"
            className="flex gap-1 bg-rose-50 border border-red text-red text-sm p-2 rounded-lg"
            role="alert"
          >
            <XCircleIcon className="w-8 h-5" />
            <span>
              <b>Error signing in</b>: {errorText}
            </span>
          </div>
        )}

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
              Remember me
            </label>
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="w-full h-9 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-ocean hover:bg-navy focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ocean disabled:bg-slate-300"
            aria-label="login-submit"
            disabled={isLoading}
          >
            {!isLoading ? "Sign in" : <PropagateLoader color="white" className="mt-0.5" />}
          </button>
        </div>
      </form>
    </div>
  );
};

export default observer(LoginForm);
