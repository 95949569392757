import { useEffect, useRef } from "react";
import { useMeasure } from "@react-hookz/web";
import { classNames } from "@/utils";
import { determineScreenshareAspectRatio, determineVideoAspectRatio } from "@/utils/video";

export const DailyAudio = ({ audioTrack }: { audioTrack: MediaStreamTrack }) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    !!audioTrack && audioRef.current && (audioRef.current.srcObject = new MediaStream([audioTrack]));
  }, [audioTrack]);

  return (
    <audio autoPlay ref={audioRef}>
      <track kind="captions" />
    </audio>
  );
};

const DailyParticipantMedia = ({
  video: videoTrack,
  audio: audioTrack,
  isRemote,
  isScreenshare,
  isOutlined,
}: {
  video?: MediaStreamTrack;
  audio?: MediaStreamTrack;
  isRemote?: boolean;
  isScreenshare?: boolean;
  isOutlined?: boolean;
}) => {
  const [{ width = 0, height = 0 } = {}, containerRef] = useMeasure<HTMLDivElement>();

  const videoDimensionStyle = isScreenshare
    ? determineScreenshareAspectRatio(width, height, videoTrack?.getSettings().aspectRatio)
    : determineVideoAspectRatio(width, height);

  const videoTransformStyle =
    !isRemote && !isScreenshare ? { transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)" } : {};

  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    !!videoTrack && videoRef.current && (videoRef.current.srcObject = new MediaStream([videoTrack]));
  }, [videoTrack]);

  const outlineStyleClass = isOutlined ? "outline outline-4 outline-ocean" : undefined;

  return (
    <div
      className={classNames(
        "w-full h-full bg-black rounded-xl grid place-items-center overflow-hidden",
        outlineStyleClass,
      )}
      ref={containerRef}
    >
      {!!audioTrack && <DailyAudio audioTrack={audioTrack} />}

      <div className="relative" style={videoDimensionStyle}>
        <video
          autoPlay
          muted
          playsInline
          className="w-full h-full object-cover absolute left-0 top-0"
          ref={videoRef}
          style={videoTransformStyle}
        />
      </div>
    </div>
  );
};

export default DailyParticipantMedia;
