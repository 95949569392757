import { isEqual, omit } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { AssessmentTestSelection, AssessmentZoomSelection } from "@parallel/vertex/types/assessment.types";
import { GameConfig } from "@parallel/vertex/types/game.types";
import { GameState } from "@parallel/vertex/types/game.types";
import { ActivityState } from "@parallel/vertex/types/meeting.types";
import { Position } from "@parallel/vertex/types/shared.types";
import { MeetingAPI } from "@/api/meeting.api";
import { TelehealthLogger } from "@/utils/logging.utils";
import { MeetingStore } from "./meeting.store";

export class ActivityStore {
  triggerRemoteSync?: () => unknown = () => undefined;

  currActivity?: ActivityState = undefined;

  gameMetadata: GameConfig[] = [];
  selectedGameInitialState?: GameState = undefined;

  constructor(
    private meetingStore: MeetingStore,
    private meetingApi: MeetingAPI,
    private logger: TelehealthLogger,
  ) {
    makeAutoObservable(this);
  }

  get currActivityType() {
    return this.currActivity?.type;
  }

  get currAssessmentTest() {
    if (this.currActivity?.type !== "assessment") return;
    return this.currActivity;
  }

  get currGame() {
    if (this.currActivity?.type !== "game") return;
    return this.currActivity;
  }

  get currGameMetadata() {
    const currGame = this.currGame;
    return currGame ? this.gameMetadata.find(g => g.id === currGame.gameId) : undefined;
  }

  async sync(meetingKey: string) {
    const { activity } = await this.meetingApi.getMeetingState(meetingKey);
    runInAction(() => (this.currActivity = activity || undefined));
    this.logger.info("synced activity state", { activity });
  }

  toggleWhiteboard = (isEnabled: boolean) =>
    this.updateActivity(isEnabled ? { type: "whiteboard" } : null)
      .then(() => this.logger.postEvent("StateSync", "successfully toggled whiteboard", { isEnabled }, "Whiteboard"))
      .catch(e =>
        this.logger.postEvent("Warning", "error toggling whiteboard", { isEnabled, error: e.message }, "Whiteboard"),
      );

  setAssessmentStimulus = (assessment: AssessmentTestSelection | null) =>
    this.updateActivity(assessment ? { type: "assessment", ...assessment } : null)
      .then(() =>
        this.logger.postEvent("StateSync", "successfully set assessment stimulus", { assessment }, "Stimulus"),
      )
      .catch(e =>
        this.logger.postEvent(
          "Warning",
          "error setting assessment stimulus",
          { assessment, error: e.message },
          "Stimulus",
        ),
      );

  updateAssessmentZoom = (zoom: AssessmentZoomSelection | undefined) => {
    if (!this.currAssessmentTest) return;
    return this.updateActivity({ ...this.currAssessmentTest, zoom })
      .then(() => this.logger.postEvent("StateSync", "successfully set assessment stimulus zoom", { zoom }, "Stimulus"))
      .catch(e =>
        this.logger.postEvent(
          "Warning",
          "error setting assessment stimulus zoom",
          { zoom, error: e.message },
          "Stimulus",
        ),
      );
  };

  selectGame = (game: GameState) => {
    this.selectedGameInitialState = { ...game };
    return this.updateGameActivity({ type: "game", ...game }, "selectGame");
  };

  updateSelectedGame = (gameUpdate: Partial<GameState>, operation: string) => {
    if (!this.currGame) return;
    this.updateGameActivity({ ...this.currGame, ...gameUpdate }, operation);
  };

  updatePiecePosition = (updatePieceId: string, newPosition: Position) => {
    if (!this.currGame) return;
    const pieces = this.currGame.pieces.map(piece =>
      piece.id === updatePieceId
        ? {
            ...piece,
            relativeArea: { ...piece.relativeArea, offset: newPosition },
            lastMovedBy: this.meetingStore.connectedParticipantKey,
          }
        : piece,
    );
    this.updateSelectedGame({ pieces }, "updatePiecePosition");
  };

  resetGame = () => {
    if (!this.selectedGameInitialState) return;
    return this.updateSelectedGame(omit(this.selectedGameInitialState, "spinner"), "resetGame");
  };

  updateGameActivity = async (update: ActivityState | null, operation: string) =>
    this.updateActivity(update)
      .then(() => this.logger.postEvent("StateSync", "successfully updated game state", { update, operation }, "Game"))
      .catch(e =>
        this.logger.postEvent("Warning", "error updating game state", { update, operation, error: e.message }, "Game"),
      );

  private updateActivity = async (activity: ActivityState | null) => {
    const { connectedMeetingKey: meetingKey } = this.meetingStore;
    if (!meetingKey) return;

    this.currActivity = activity || undefined;
    const { activity: updatedRemote } = await this.meetingApi.updateMeetingActivity(meetingKey, activity);
    if (!isEqual(updatedRemote, activity))
      this.logger.error("updated activity did not equal local state", { updatedLocal: activity, updatedRemote });

    if (this.triggerRemoteSync) {
      this.triggerRemoteSync();
      this.logger.info("triggering remote activity state updates", {
        updatedLocal: activity,
        updatedRemote,
      });
    } else {
      this.logger.warn("activity state update remote trigger not set", {
        updatedLocal: activity,
      });
    }
  };
}
