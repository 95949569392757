import { ExtendedAppointment, SearchAppointmentsQuery } from "@parallel/vertex/types/calendar/appointment.types";
import { PaginatedResult } from "@parallel/vertex/types/shared.types";
import { BaseApiOptions, BaseAPI } from "./base.api";

export class AppointmentAPI extends BaseAPI {
  constructor(options: BaseApiOptions) {
    super({ ...options, parseTimestamps: true });
  }

  public searchAppointments = (params: SearchAppointmentsQuery): Promise<PaginatedResult<ExtendedAppointment>> =>
    this.instance.get("calendar/appointment", { params }).then(r => r.data);

  public getAppointment = (appointmentId: string): Promise<ExtendedAppointment> =>
    this.instance.get(`calendar/appointment/${appointmentId}`).then(r => r.data);
}
