import { useContext } from "react";
import { IonIcon } from "@ionic/react";
import * as Icons from "ionicons/icons";
import { observer } from "mobx-react-lite";
import TooltipButton, { IonIconTooltipButton } from "@/components/common/elements/TooltipButton";
import { ParticipantStoreContext, StoreContext } from "@/stores";

const buttonClass = (isSelected = false) =>
  `h-6 rounded-md text-white text-sm ${
    isSelected ? "bg-secondary" : "bg-primary"
  } cursor-pointer rounded-md grid place-items-center`;

const _WhiteboardControls = ({ participantKey }: { participantKey: string }) => {
  const participantStore = useContext(ParticipantStoreContext);
  const colorHex = participantStore.streamWhiteboardColors[participantKey];
  const isDrawEnabled = participantStore.participants[participantKey]?.whiteboardMode === "write";

  const idString = (name: string) => `${name}-whiteboard-control-${participantKey}`;
  const toggle = () =>
    participantStore.updateParticipant(participantKey, { whiteboardMode: isDrawEnabled ? "read" : "write" });

  return (
    <TooltipButton
      uniqueName={idString("pencil")}
      labelText={`${isDrawEnabled ? "Disable" : "Enable"} Draw`}
      onClick={toggle}
      className={buttonClass(isDrawEnabled)}
    >
      <div className="flex flex-row gap-2 px-2">
        <IonIcon icon={Icons.pencil} />
        <div className="rounded-full h-4 w-4 border border-black" style={{ backgroundColor: colorHex }} />
      </div>
    </TooltipButton>
  );
};

const _GameControls = ({ participantKey }: { participantKey: string }) => {
  const participantStore = useContext(ParticipantStoreContext);
  const canWrite = participantStore.participants[participantKey]?.gameMode === "write";
  const toggle = () => participantStore.updateParticipant(participantKey, { gameMode: canWrite ? "read" : "write" });
  return (
    <IonIconTooltipButton
      uniqueName="game-permission-toggle"
      labelText={canWrite ? "Disable Game" : "Enable Game"}
      onClick={toggle}
      icon={Icons.dice}
      className={`${buttonClass(canWrite)} w-6`}
    />
  );
};

const WhiteboardControls = observer(_WhiteboardControls);

const GameControls = observer(_GameControls);

const ActivityControls = ({ participantKey }: { participantKey: string }) => {
  const {
    activityStore: { currActivityType },
    participantStore: { localParticipantKey },
    userStore: { isStaff },
  } = useContext(StoreContext);

  if (!isStaff || participantKey === localParticipantKey) return <></>;

  if (currActivityType === "whiteboard") return <WhiteboardControls participantKey={participantKey} />;
  else if (currActivityType === "game") return <GameControls participantKey={participantKey} />;

  return <></>;
};

export default observer(ActivityControls);
