import { makeAutoObservable, runInAction } from "mobx";
import { StreamChat, Channel } from "stream-chat";
import { processWithTimeout } from "@parallel/vertex/util/async.util";
import config from "@/config";
import ConnectionState from "@/interfaces/ConnectionState";
import { TelehealthLogger } from "@/utils/logging.utils";
import { MeetingStore } from "./meeting.store";
import { UserStore } from "./user.store";

export class ChatStore {
  connectionState: ConnectionState = ConnectionState.Ready;

  client: StreamChat = StreamChat.getInstance(config.streamChatKey);
  channel?: Channel = undefined;
  eventListener?: { unsubscribe: () => void } = undefined;
  contextMenuOpenMessage?: string = undefined;

  errorMessage?: string;

  constructor(
    private userStore: UserStore,
    private meetingStore: MeetingStore,
    private logger: TelehealthLogger,
  ) {
    makeAutoObservable(this);
  }

  async connectWithToken({
    streamChatToken,
    meetingKey,
    userId,
    userName,
    timeoutMs,
  }: {
    streamChatToken: string;
    meetingKey: string;
    userId: string;
    userName?: string;
    timeoutMs?: number;
  }) {
    this.errorMessage = undefined;
    const channel = await processWithTimeout(
      "connect chat",
      async () => {
        await this.client.connectUser({ id: userId, name: userName || `User ${userId}` }, streamChatToken);
        const channel = this.client.channel("messaging", meetingKey);
        await channel.watch();
        return channel;
      },
      timeoutMs,
    );
    runInAction(() => {
      this.connectionState = ConnectionState.Connected;
      this.channel = channel;
      this.eventListener = this.setupListeners(channel);
    });
    return channel;
  }

  private setupListeners = (channel: Channel) =>
    channel.on("message.new", e => {
      // Will override the sidebar for clients in the meeting regardless of their local state.
      if (!this.userStore.isStaff) {
        this.meetingStore.setLeftSidebar("chat");
      } else {
        if (!this.meetingStore.isChatSidebarEnabled) this.meetingStore.incrementUnreadMessageCount();
      }
      this.logger.info("received new message", {
        message: e.message,
        newMessageCount: channel.state.messages.length,
      });
    });

  setContextMenuOpenMessage(messageId?: string) {
    runInAction(() => (this.contextMenuOpenMessage = messageId));
  }

  disconnect() {
    this.eventListener?.unsubscribe();
    this.channel?._disconnect();
    this.channel = undefined;
    this.connectionState = ConnectionState.Ready;
  }

  sendMessage(text: string) {
    if (!this.channel) return;
    this.channel.sendMessage({ text });
  }
}
