import { useCallback, useContext, useEffect } from "react";
import { DailyCall, DailyEventObjectAppMessage, DailyEventObjectTrack } from "@daily-co/daily-js";
import { ExtendedDailyParticipant } from "@daily-co/daily-react";
import { runInAction } from "mobx";
import { DailyMessageType } from "@/interfaces/message";
import { StoreContext } from "@/stores";
import { useStoreProps } from "@/utils/hooks/use-store-props";
import { TelehealthLogger } from "@/utils/logging.utils";

export const AUDIO_PLAYER_TRACK_NAME = "daily-audio-player";

export const DOCUMENT_CAMERA_TRACK_NAME = "document-camera";

export type DailyUserType = "provider" | "client" | "watcher" | "document-camera";
export type DailyParticipantData = { participantKey: string; userType: DailyUserType; displayName: string };

export type EnrichedDailyParticipant = ExtendedDailyParticipant & Partial<DailyParticipantData>;

export const getParticipantData = (participant: ExtendedDailyParticipant) =>
  participant.userData as DailyParticipantData;

export const isProviderParticipant = (participant: ExtendedDailyParticipant): boolean =>
  getParticipantData(participant)?.userType === "provider";

export const isDocumentCameraParticipant =
  (participantKey: string) =>
  (participant: ExtendedDailyParticipant): boolean => {
    if (!participant.userData) return false;
    const data = getParticipantData(participant);
    return data.userType === "document-camera" && data.participantKey.includes(participantKey);
  };

export const shouldDisplayParticipant = (participant: ExtendedDailyParticipant): boolean =>
  getParticipantData(participant).userType !== "watcher" && !!participant.userData;

export const encodeUserName = ({ userName, userId }: { userName: string; userId?: string }) =>
  userId ? `${userId} ~ ${userName}` : userName;

export const decodeUserName = (name: string) => {
  if (!name.includes(" ~ ")) return { userName: name };
  const [userId, userName] = name.split(" ~ ");
  return { userId, userName };
};

// slice the device id - daily doesn't allow track names longer than 50 chars
export const documentCameraTrackId = (deviceId: string) => `${DOCUMENT_CAMERA_TRACK_NAME}_${deviceId.slice(0, 20)}`;

export const useAppMessageCallback = (
  dailyCall: DailyCall,
  participantKey: string,
  meetingKey: string,
  logger: TelehealthLogger,
) => {
  const { activityStore, assessmentStore, participantStore, meetingStore } = useContext(StoreContext);
  return useCallback((e: DailyEventObjectAppMessage) => {
    logger.info("received app message", e);
    switch (e.data.type) {
      case DailyMessageType.MeetingActivityUpdated:
        return activityStore.sync(meetingKey);
      case DailyMessageType.MeetingDisplayUpdated:
        return meetingStore.sync(meetingKey);
      case DailyMessageType.MeetingParticipantUpdated:
        return participantStore.sync(meetingKey);
      case DailyMessageType.AssessmentMouseMove:
        return assessmentStore.remoteUpdate({ mousePosition: e.data.position });
      case DailyMessageType.AssessmentMouseClick:
        return assessmentStore.remoteUpdate({ mouseClick: e.data.position });
      case DailyMessageType.ProviderClosedChat:
        return meetingStore.clearLeftSidebar("chat");
      case DailyMessageType.StartDocumentCamera:
        if (e.data.participantKey !== participantKey) return;
        return meetingStore.startDocumentCameraTrack(dailyCall, e.data.deviceId);
      case DailyMessageType.StopDocumentCamera:
        return meetingStore.stopDocumentCameraTrack(dailyCall);
    }
  }, []);
};

export const useTrackStartedCallback = (
  localSessionId: string,
  setCustomAudioTrack: (t: MediaStreamTrack) => void,
  logger: TelehealthLogger,
) => {
  const { meetingStore, participantStore } = useContext(StoreContext);
  return useCallback(
    (e: DailyEventObjectTrack) => {
      logger.info("received daily track-started event", e);
      if (e.type === AUDIO_PLAYER_TRACK_NAME) {
        if (e.participant?.session_id === localSessionId) return;
        logger.info("playing daily custom audio");
        setCustomAudioTrack(e.track);
      } else if (e.type.includes(DOCUMENT_CAMERA_TRACK_NAME)) {
        if (!e.participant?.userData) return;
        logger.info("receiving document camera video");
        const participantKey = getParticipantData(e.participant).participantKey;
        const startedByParticipant = participantStore.participants[participantKey];
        const displayName = startedByParticipant
          ? `${startedByParticipant.displayName}'s Document Camera`
          : `Document Camera ${e.type.slice(0, 10)}...`;
        runInAction(
          () => (meetingStore.remoteDocumentCamera = { track: e.track, id: e.type, participantKey, displayName }),
        );
      }
    },
    [localSessionId],
  );
};

export const useInitialDailyStateSync = (
  sendAppMessage?: (data: any) => void,
  localDailySessionId?: string,
  isWaiting?: boolean,
) => {
  const { activityStore, assessmentStore, meetingStore, participantStore } = useContext(StoreContext);
  const { localParticipant } = useStoreProps(participantStore, ["localParticipant"]);
  const isSynced =
    !!localParticipant && !!localDailySessionId && localParticipant.dailySessionId === localDailySessionId;
  useEffect(() => {
    if (isSynced || !sendAppMessage || !localDailySessionId || !localParticipant) return;

    activityStore.triggerRemoteSync = () => sendAppMessage({ type: DailyMessageType.MeetingActivityUpdated });
    meetingStore.triggerRemoteSync = () => sendAppMessage({ type: DailyMessageType.MeetingDisplayUpdated });
    participantStore.triggerRemoteSync = () => sendAppMessage({ type: DailyMessageType.MeetingParticipantUpdated });
    assessmentStore.broadcastMouseMove = position =>
      sendAppMessage({ type: DailyMessageType.AssessmentMouseMove, position });
    assessmentStore.broadcastMouseClick = position =>
      sendAppMessage({ type: DailyMessageType.AssessmentMouseClick, position });
    meetingStore.emitProviderClosedChat = () => sendAppMessage({ type: DailyMessageType.ProviderClosedChat });

    participantStore.updateLocalParticipant({ dailySessionId: localDailySessionId }, { skipSync: isWaiting });
  }, [sendAppMessage, localDailySessionId, localParticipant]);

  return { isSynced };
};
