import { ReactNode, useContext, useState } from "react";
import { IonIcon } from "@ionic/react";
import { useMountEffect, useUnmountEffect } from "@react-hookz/web";
import { buildOutline } from "ionicons/icons";
import { observer } from "mobx-react-lite";
import LoadingPlaceholder from "@/components/common/content/LoadingPlaceholder";
import BlockingErrorLayout from "@/components/common/content/error/BlockingErrorLayout";
import ConnectionHelpContent from "@/components/common/content/error/ConnectionHelpContent";
import PlaceholderContainer from "@/components/common/layout/PlaceholderContainer";
import WelcomeLayout from "@/components/common/layout/WelcomeLayout";
import { AlertStoreContext, loggerContext } from "@/stores";
import { ResolutionCategory } from "@/stores/alert.store";
import { initLogger, useEventRedirect } from "@/utils/logging.utils";
import VideoPreview from "./VideoPreview";

const logger = initLogger("LobbyCommon", loggerContext);

export type LobbyCommonProps = {
  appointmentHeaderText?: string;
  children: ReactNode;
  isError?: boolean;
  loadingText?: string;
};

export type BlockingUserMediaFailure = "device-permissions" | "device-connection";

const LobbyCommon = ({ appointmentHeaderText, children, isError, loadingText }: LobbyCommonProps) => {
  const alertStore = useContext(AlertStoreContext);

  const redirect = useEventRedirect(logger);

  const [userStream, setUserStream] = useState<MediaStream>();
  const [blockingMediaError, setBlockingMediaError] = useState<{
    failure: BlockingUserMediaFailure;
    userMediaError: string;
  }>();

  const mediaTestError = (message: string, resolution: ResolutionCategory, cause?: Error) => {
    logger.postEvent("Warning", `error testing user media: ${message}`, { causedBy: cause?.message });
    alertStore.push(message, { resolution });
  };

  const mediaTestBlockingFailure = (failure: BlockingUserMediaFailure, userMediaError: string, cause: any) => {
    logger.postEvent("Error", `blocking error testing user media - error code: ${failure}`, {
      userMediaError,
      causedBy: cause?.message,
    });
    setBlockingMediaError({ failure, userMediaError });
  };

  const startUserMediaTest = async () => {
    if (!navigator.mediaDevices?.getUserMedia) return mediaTestError("Unsupported browser", "browser");

    const stream = await navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then(logger.operationSuccessHandler("getUserMedia"))
      .catch(e => {
        switch (e.name) {
          case "NotAllowedError":
            return mediaTestBlockingFailure("device-permissions", e.name, e);
          case "NotFoundError":
          case "OverconstrainedError":
            return mediaTestBlockingFailure("device-connection", e.name, e);
          default:
            return mediaTestError("Error connecting to local camera / microphone", "devices", e);
        }
      });
    if (!stream) return;
    setUserStream(stream);
  };

  useMountEffect(startUserMediaTest);

  useUnmountEffect(() => userStream && userStream.getTracks().forEach(t => t.stop()));

  const [isPreviewReady, setIsPreviewReady] = useState(false);

  let errorContent: ReactNode | undefined;
  if (blockingMediaError) {
    errorContent = (
      <BlockingErrorLayout
        header="Media Device Error"
        subheader={`Error initiating media devices for meeting - error code: ${blockingMediaError.failure}`}
        details={<ConnectionHelpContent {...blockingMediaError} />}
      />
    );
  } else if (isError) {
    errorContent = children;
  }
  if (errorContent) return <WelcomeLayout bottomContent={errorContent} />;

  const videoPreview = <VideoPreview userStream={userStream} onReady={() => setIsPreviewReady(true)} />;

  if (!loadingText && !isPreviewReady) loadingText = "Preparing Media Preview";
  if (loadingText)
    return (
      <PlaceholderContainer>
        <LoadingPlaceholder text={loadingText} />
        <div className="hidden">{videoPreview}</div>
      </PlaceholderContainer>
    );

  return (
    <WelcomeLayout
      firstColumn={
        <section aria-label="appointments">
          {appointmentHeaderText && (
            <h2 className="text-xl text-gray-900 mb-4 font-semibold" aria-label="appointment">
              {appointmentHeaderText}
            </h2>
          )}
          <div className="space-y-1 leading-6 text-gray-500" role="menu">
            {children}
          </div>
        </section>
      }
      secondColumn={
        <div className="flex pt-8">
          <div className="w-full">
            {videoPreview}
            <div className="flex justify-end">
              <button
                onClick={() => redirect("/connection-test", "user interaction")}
                className="mt-2 bg-inherit rounded-md text-navy hover:font-bold flex items-center "
              >
                <IonIcon icon={buildOutline} className="mr-1" /> Check Connection
              </button>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default observer(LobbyCommon);
