import { useContext, useEffect, useRef, useState } from "react";
import { useKeyboardEvent } from "@react-hookz/web";
import { observer } from "mobx-react-lite";
import { StoreContext } from "@/stores";
import { classNames, isAscii } from "@/utils";

const NameControl = ({
  displayName,
  updateName,
}: {
  displayName: string;
  updateName?: (n: string) => Promise<unknown>;
}) => {
  const { alertStore } = useContext(StoreContext);

  const [editName, setEditName] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);

  useKeyboardEvent(
    ({ key }) => key === "Enter" && !!editName,
    () => (inputRef.current ? inputRef.current.blur() : setEditName(undefined)),
    [editName, inputRef.current],
  );

  useEffect(() => {
    if (!!editName && !!inputRef.current) inputRef.current.focus();
  }, [!!editName]);

  const saveDisplayName = async () => {
    if (!!editName && (editName.length > 100 || !isAscii(editName))) {
      alertStore.push("Display name update is invalid", {
        details: "name must be less than 100 characters long and contain no special characters",
      });
      setEditName(undefined);
    } else if (!!editName && !!updateName) {
      await updateName(editName)
        .then(() => setEditName(undefined))
        .catch(() => alertStore.push("Error setting display name", { resolution: "retry" }));
    }
  };

  return (
    <div className="w-full h-full text-sm text-white">
      <div className="absolute w-full h-full top-0 left-0 bg-black opacity-50 rounded-md" />
      <p
        className={classNames(
          "relative px-3 py-1 text-white overflow-hidden text-ellipsis",
          updateName ? "cursor-test" : "cursor-default",
          editName ? "hidden" : "",
        )}
        onClick={e => {
          if (!updateName) return;
          e.stopPropagation();
          setEditName(displayName);
        }}
      >
        {displayName}
      </p>
      <input
        value={editName}
        onChange={e => setEditName(e.target.value)}
        onBlur={saveDisplayName}
        ref={inputRef}
        className={`w-full h-full rounded-md px-3 py-1 bg-black ${editName ? "relative" : "hidden"}`}
      />
    </div>
  );
};

export default observer(NameControl);
