import { FadeLoader } from "react-spinners";

const LoadingPlaceholder = ({ text }: { text?: string }) => (
  <>
    {text && <p>{text}</p>}
    <FadeLoader />
  </>
);

export default LoadingPlaceholder;
