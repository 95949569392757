import { useContext } from "react";
import { observer } from "mobx-react-lite";
import Link from "@/components/common/elements/Link";
import config from "@/config";
import { UserStoreContext } from "@/stores";

const SupportEmailLink = ({
  text = "Email Support",
  issueTitle = "Telehealth Issue",
  failedProcessCode,
  className,
  onBlur,
}: {
  onBlur?: () => void;
  text?: string;
  issueTitle?: string;
  failedProcessCode?: string;
  className?: string;
}) => {
  const { userId } = useContext(UserStoreContext);

  const supportSubject = `Telehealth Support Ticket: ${issueTitle}`;
  const lineBreakCode = "%0d%0a";
  const timeStamp = new Date();
  const supportBody =
    "Telehealth Session Details:" +
    lineBreakCode +
    `User Id: ${userId || "No user id found"} ` +
    `Timestamp: ${timeStamp.toLocaleString()} ` +
    (failedProcessCode ? `Failed Process Code: ${failedProcessCode}` : "") +
    lineBreakCode.repeat(2) +
    "Submit your support ticket issue here:" +
    lineBreakCode.repeat(2);

  return (
    <Link
      className={className ? className : undefined}
      href={`mailto:${config.supportLines.email}?subject=${supportSubject}&body=${supportBody}`}
      onBlur={onBlur}
    >
      {text}
    </Link>
  );
};

export default observer(SupportEmailLink);
