import { useEffect } from "react";
import { DailyCall } from "@daily-co/daily-js";
import { useAppMessage } from "@daily-co/daily-react";
import { omit } from "lodash";
import LoadingPlaceholder from "@/components/common/content/LoadingPlaceholder";
import DailyMeetingWindowLayout from "@/components/meeting/daily/DailyMeetingWindowLayout";
import { MeetingPlaceholderContainer } from "@/components/meeting/layout/MeetingPlaceholder";
import { loggerContext } from "@/stores";
import { useAppMessageCallback } from "@/utils/daily.utils";
import { DailyMeetingConnectionParams, useDailyMeetingConnection } from "@/utils/hooks/use-meeting-connection";
import { initLogger } from "@/utils/logging.utils";

const logger = initLogger("DailyMeetingWatch", loggerContext);

const DailyMeetingWatch = (props: { dailyCall: DailyCall; meetingKey: string }) => {
  const { dailyCall, meetingKey } = props;
  const connectionParams: DailyMeetingConnectionParams = {
    dailyCall,
    meetingKey,
    participantKey: "watcher",
    user: { name: "Watcher", type: "watcher" },
  };
  const { isFinished } = useDailyMeetingConnection(connectionParams);
  useAppMessage({
    onAppMessage: useAppMessageCallback(dailyCall, "watcher", meetingKey, logger),
  });

  useEffect(() => {
    if (!isFinished) return;
    logger.postEvent("PageReady", "meeting watch ready", {
      props: omit(props, "dailyCall"),
      connectionParams: omit(connectionParams, "dailyCall"),
    });
  }, [isFinished]);

  if (!isFinished)
    return (
      <MeetingPlaceholderContainer>
        <LoadingPlaceholder text="Connecting to Room" />
      </MeetingPlaceholderContainer>
    );

  return <DailyMeetingWindowLayout meetingKey={meetingKey} />;
};

export default DailyMeetingWatch;
