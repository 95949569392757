import { useParams } from "react-router-dom";
import { useDaily } from "@daily-co/daily-react";
import DailyMeetingWatch from "@/components/meeting/daily/DailyMeetingWatch";
import { MeetingRoomLayout } from "@/components/meeting/layout/MeetingLayout";
import { MeetingPlaceholderContainer } from "@/components/meeting/layout/MeetingPlaceholder";

const MeetingRoomWatchScreen = () => {
  const { roomId } = useParams();
  const dailyCall = useDaily();

  // TODO actually redirect if roomId or daily call undefined
  let content = <MeetingPlaceholderContainer>Invalid Access - Redirecting</MeetingPlaceholderContainer>;

  if (!!roomId && !!dailyCall) content = <DailyMeetingWatch dailyCall={dailyCall} meetingKey={roomId} />;

  return <MeetingRoomLayout roomId={roomId}>{content}</MeetingRoomLayout>;
};

export default MeetingRoomWatchScreen;
