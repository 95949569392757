import { IonIcon } from "@ionic/react";
import { format } from "fecha";
import { calendarSharp, time, personCircle, people } from "ionicons/icons";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import { getCommaList } from "@parallel/vertex/util/string.util";
import { durationMinutes } from "@/utils/appointment";

const attendeeIconStyleClasses = "pr-2 text-xl shrink-0 text-neutral-icon";

const AppointmentBanner = ({
  appointment,
  onClick,
  isUpcomingAppointmentLink = false,
}: {
  appointment: ExtendedAppointment;
  onClick?: () => void;
  isUpcomingAppointmentLink?: boolean;
}) => {
  const attendeeFullNames: string[] = appointment.students.map(s => s.fullName);
  const attendeeNameList = attendeeFullNames.length > 0 ? getCommaList(attendeeFullNames) : "No attendees listed";

  const hoverStyles = onClick
    ? "cursor-pointer focus-within:bg-ocean-light focus-within:border-ocean hover:bg-ocean-light hover:border-ocean"
    : "";

  const borderStyles = isUpcomingAppointmentLink ? "border-ocean border-2 rounded-md p-2 max-w-[95%] bg-white" : "p-2";

  return (
    <div
      onClick={onClick}
      onKeyDown={onClick}
      className={`flex flex-col ${hoverStyles} ${borderStyles} w-full my-0.5`}
      role="menuitem"
      aria-label="appointment"
      tabIndex={0}
    >
      <div className={`mb-0.5 ${isUpcomingAppointmentLink ? "text-ocean" : "text-black"} flex flex-row text-xl`}>
        {`${appointment.title} - ${durationMinutes(appointment)} Minutes`}
      </div>
      <div>
        <div className="flex flex-row text-black items-center">
          <IonIcon icon={calendarSharp} className="text-lg text-neutral-icon" />
          <time dateTime={appointment.startTime.toISO() || undefined} className="px-2">
            {format(appointment.startTime.toJSDate(), "ddd, MMM Do")}
          </time>
          <IonIcon icon={time} className="text-lg text-neutral-icon" />
          <time dateTime={appointment.startTime.toISO() || undefined} className="px-2">
            {format(appointment.startTime.toJSDate(), "h:mma")} - {format(appointment.endTime.toJSDate(), "h:mma")}
          </time>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="flex flex-row items-start text-black">
          <IonIcon className={attendeeIconStyleClasses} icon={personCircle} />
          {appointment.provider.fullName}
        </div>

        <div className="flex flex-row items-start text-black text-justify">
          <IonIcon className={attendeeIconStyleClasses} icon={people} />
          {attendeeNameList}
        </div>
      </div>
    </div>
  );
};

export default AppointmentBanner;
