import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMountEffect } from "@react-hookz/web";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import { ApiStoreContext, alertStore, loggerContext, meetingStore, userStore } from "@/stores";
import { RedirectFn, initLogger, useEventRedirect } from "@/utils/logging.utils";
import { useStoreContextProps, useStoreProps } from "./use-store-props";

const logger = initLogger("use-valid-access", loggerContext);

type ValidationRedirect = {
  toUrl: string;
  reason: string;
  queryParams?: Record<string, string>;
};

type UserValidationResult = {
  userId: string | null;
  invalidRedirect?: ValidationRedirect;
};

const validateUser = (userId: string | undefined, returnAfterLogin: boolean): UserValidationResult => {
  if (!userId)
    return {
      userId: null,
      invalidRedirect: {
        toUrl: "/login",
        reason: "not signed in",
        queryParams: returnAfterLogin ? { redirect: window.location.pathname } : undefined,
      },
    };
  return { userId };
};

type AppointmentParticipant = {
  appointment: ExtendedAppointment;
  userId: string;
  participantKey: string;
};

type AppointmentValidationResult = {
  validProps: AppointmentParticipant | null;
  invalidRedirect?: ValidationRedirect;
};

const validateAppointment = (
  userId: string | undefined,
  appointment: ExtendedAppointment | undefined,
  sessionKey: string,
): AppointmentValidationResult => {
  const { userId: validUserId, invalidRedirect } = validateUser(userId, !!appointment);
  if (!validUserId) return { validProps: null, invalidRedirect };

  if (!appointment)
    return {
      validProps: null,
      invalidRedirect: {
        toUrl: "/lobby",
        reason: "appointment is undefined",
      },
    };

  const signedInAttendee = [appointment.provider, ...appointment.students].find(u => u.userId === validUserId);
  if (!signedInAttendee)
    return {
      validProps: null,
      invalidRedirect: {
        toUrl: "/lobby",
        reason: `signed in user ${userId} is not attendee of appointment ${appointment.appointmentId}`,
      },
    };

  const userKey = signedInAttendee.shortCode || validUserId.split("-")[0];
  return {
    validProps: {
      userId: validUserId,
      appointment,
      participantKey: `${userKey}-${sessionKey}`,
    },
  };
};

export const maybePerformRedirect = (redirectFn: RedirectFn, redirect?: ValidationRedirect) => {
  if (!redirect) return;
  alertStore.push("Appointment accesss blocked", { resolution: "access" });
  redirectFn(redirect.toUrl, redirect.reason, { queryParams: redirect.queryParams });
};

const useInvalidRedirect = (redirect?: ValidationRedirect) => {
  const redirectFn = useEventRedirect(logger);
  useMountEffect(() => maybePerformRedirect(redirectFn, redirect));
};

export const useValidMeetingAccess = (returnAfterLogin: boolean = false) => {
  const { userId, sessionKey } = useStoreProps(userStore, ["userId", "sessionKey"]);

  const { invalidRedirect, userId: validUserId } = validateUser(userId, returnAfterLogin);
  useInvalidRedirect(invalidRedirect);

  if (!validUserId) return null;

  const userKey = validUserId.split("-")[0];
  return { userId: validUserId, participantKey: `${userKey}-${sessionKey}` };
};

export const useValidAppointmentAccess = (): AppointmentParticipant | null => {
  const { userId, sessionKey } = useStoreProps(userStore, ["userId", "sessionKey"]);
  const { appointment } = useStoreProps(meetingStore, ["appointment", "providerUserId"]);

  const { invalidRedirect, validProps } = validateAppointment(userId, appointment, sessionKey);
  useInvalidRedirect(invalidRedirect);

  return validProps;
};

export const useValidAppointmentParamAccess = (): AppointmentParticipant | string => {
  const { appointmentId } = useParams();

  const { userId, sessionKey } = useStoreProps(userStore, ["userId", "sessionKey"]);
  const { appointment } = useStoreProps(meetingStore, ["appointment"]);
  const { appointmentApi } = useStoreContextProps(ApiStoreContext, ["appointmentApi"]);

  const isAppointmentLoaded = !!appointmentId && appointment?.appointmentId === appointmentId;

  const redirect = useEventRedirect(logger);

  // if stored appointment is undefined or doesn't match url param, reload the appointment
  useEffect(() => {
    if (isAppointmentLoaded || !appointmentId) return;
    logger
      .wrapOperation("getAppointment", appointmentApi.getAppointment(appointmentId))
      .then(a => meetingStore.setAppointment(a))
      .catch(() => redirect("/lobby", "appointment fetch failed"));
  }, [appointment, appointmentId]);

  if (!isAppointmentLoaded) return "Fetching Appointment";

  const { invalidRedirect, validProps } = validateAppointment(userId, appointment, sessionKey);
  maybePerformRedirect(redirect, invalidRedirect);

  return validProps || "Invalid Access - Redirecting";
};
