import { useContext } from "react";
import { IonIcon } from "@ionic/react";
import { observer } from "mobx-react-lite";
import Button, { ButtonStyleClass } from "@/components/common/elements/Button";
import Link from "@/components/common/elements/Link";
import Modal from "@/components/common/modal/Modal";
import config from "@/config";
import RecordStartSimpleIcon from "@/icons/record-start-simple.svg";
import RecordStopSimpleIcon from "@/icons/record-stop-simple.svg";
import { MeetingStoreContext } from "@/stores";

const SessionRecordingModal = () => {
  const meetingStore = useContext(MeetingStoreContext);
  const isSessionRecording = !!meetingStore.inProgressRecording;

  const modalText = isSessionRecording ? "Stop Recording" : "Start Recording";
  const modalButtonIcon = isSessionRecording ? RecordStopSimpleIcon : RecordStartSimpleIcon;

  const handleSessionRecordingToggle = () => {
    meetingStore.toggleSessionRecording();

    // Only show a notification when a recording start but the state
    // of `isSessionRecording` is out of sync since ithas been destructed above
    // so it is false when it was just started and true when it is being ended
    if (!isSessionRecording && !!meetingStore.appointment) {
      meetingStore.createNotification({
        title: "Session recording has started",
        body: "Session recording has started.",
        link: {
          label: "You can find more information on Parallel's privacy policy here",
          url: "https://www.parallellearning.com/legal/privacy-policy",
        },
        // Publish notification for everyone but the provider since the provider will
        // have a persistent notification in the UI
        deliverToUserIds: meetingStore.appointment.students.map(s => s.userId),
        audio: {
          url: "https://storage.googleapis.com/parallel-static/recording-start.wav",
          contentType: "audio/wav",
        },
        okButton: true,
      });
    } else if (isSessionRecording && !!meetingStore.appointment) {
      meetingStore.createNotification({
        title: "Session recording has ended",
        // Publish notification for everyone but the provider since the provider will
        // have a persistent notification in the UI
        deliverToUserIds: meetingStore.appointment.students.map(s => s.userId),
        audio: {
          url: config.alertChimeAudioFileUrl,
          contentType: "audio/wav",
        },
      });
    }
  };

  return (
    <Modal
      title={modalText}
      onClose={() => meetingStore.toggleSessionRecordingModal()}
      containerStyle={{ minWidth: "30%" }}
    >
      <div className="py-6 flex flex-col gap-2">
        Are you sure you want to {isSessionRecording ? "stop" : "start"} recording this call?
        <Button
          text={modalText}
          className="my-4 py-2 px-4 font-semibold rounded-md bg-red text-white"
          styleClass={ButtonStyleClass.Custom}
          icon={<IonIcon src={modalButtonIcon} className="fill-white bg-red" />}
          onClick={() => handleSessionRecordingToggle()}
        />
        <div className="text-center">
          <Link href="#" onClick={() => meetingStore.toggleSessionRecordingModal()} newTab={false}>
            Cancel
          </Link>
        </div>
      </div>
    </Modal>
  );
};
export default observer(SessionRecordingModal);
