import { GameConfig } from "@parallel/vertex/types/game.types";
import { BaseAPI } from "./base.api";

export class GameAPI extends BaseAPI {
  public getGameMetadata = (): Promise<GameConfig[]> => this.instance.get(`/game/metadata`).then(r => r.data);

  public getBackgroundImage = (fileName: string): Promise<File> =>
    this.instance
      .get(`/game/image/backgrounds/${fileName}`, { responseType: "blob" })
      .then(r => new File([r.data], fileName));

  public getPieceImage = (fileName: string): Promise<File> =>
    this.instance
      .get(`/game/image/pieces/${fileName}`, { responseType: "blob" })
      .then(r => new File([r.data], fileName));

  public loadPieceImage = async (fileName: string): Promise<string> => {
    const cached = this.pieceImageUrlCache[fileName];
    if (cached) return cached;

    const urlPromise = this.getPieceImage(fileName).then(URL.createObjectURL);
    this.pieceImageUrlCache[fileName] = urlPromise;

    return urlPromise;
  };

  private pieceImageUrlCache: Partial<Record<string, Promise<string>>> = {};
}
