import { useContext } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { IonIcon } from "@ionic/react";
import { format } from "fecha";
import * as Icons from "ionicons/icons";
import { observer } from "mobx-react-lite";
import Link from "@/components/common/elements/Link";
import SupportEmailLink from "@/components/common/elements/SupportEmailLink";
import config from "@/config";
import { AlertStoreContext } from "@/stores";
import { AlertPayload } from "@/stores/alert.store";

const getAlertIcon = (alert: AlertPayload) => {
  switch (alert.severity) {
    case "error":
      return <IonIcon icon={Icons.closeCircle} className="text-red w-10 h-10" />;
    case "warn":
      return <IonIcon icon={Icons.alertCircle} className="text-warning w-10 h-10" />;
    case "info":
      return <IonIcon icon={Icons.informationCircle} className="text-ocean w-10 h-10" />;
  }
};

const getAlertResolutionContent = (alert: AlertPayload) => {
  switch (alert.resolution) {
    case "browser":
      return <p>Please update your browser and/or operating system to the latest version.</p>;
    case "network":
      return (
        <p>
          Please try refreshing this page and viewing <Link href={config.techSupportLink}>this guide</Link> for help
          troubleshooting common network errors.
        </p>
      );
    case "refresh":
      return <p>Please try refreshing the page.</p>;
    case "retry":
      return <p>Please attempt the failed operation again.</p>;
    case "access":
      return (
        <p>Please ensure your are signed in as the correct user and accessing the correct appointment and try again.</p>
      );
    case "devices":
      return (
        <p>
          Please view <Link href={config.techSupportLink}>this guide</Link> for help troubleshooting common mic/camera
          issues.
        </p>
      );
  }
};

const AlertHistoryContent = () => {
  const alertStore = useContext(AlertStoreContext);
  const { alerts, selectedAlert } = alertStore;

  if (alerts.length === 0) return <></>;

  if (!selectedAlert)
    return (
      <div className="flex flex-col w-full h-full">
        <h1 className="p-4 pb-3 text-base text-md font-semibold leading-6 text-slate-700 italic">Recent Alerts</h1>
        <div className="flex-1 overflow-y-auto flex flex-col">
          {alerts.map(a => (
            <div
              className="w-full p-4 flex flex-row items-start gap-2 cursor-pointer hover:bg-light"
              onClick={() => alertStore.setSelected(a)}
              key={a.id}
            >
              {getAlertIcon(a)}
              <div className="flex-1">
                <p className="text-xs text-gray-700">{format(a.createdAt, "h:mma")}</p>
                <p>{a.message}</p>
              </div>
              <ChevronRightIcon className="w-5 h-5 self-center" />
            </div>
          ))}
        </div>
      </div>
    );

  return (
    <div className="flex flex-col gap-4 p-4 overflow-y-auto">
      <div className="w-full flex flex-row items-start gap-2">
        {getAlertIcon(selectedAlert)}
        <div className="flex-1">
          <p className="text-xs text-gray-700">{format(selectedAlert.createdAt, "h:mma")}</p>
          <p className="text-lg">{selectedAlert.message}</p>
        </div>
      </div>
      {selectedAlert.details && <p className="pl-1">{selectedAlert.details}</p>}
      <p className="pl-1">{getAlertResolutionContent(selectedAlert)}</p>
      <p className="pl-1">
        If this issue persists, <SupportEmailLink />
      </p>
      <p className="pl-1 underline text-ocean text-sm cursor-pointer" onClick={() => alertStore.setSelected(undefined)}>
        View All Alerts
      </p>
    </div>
  );
};

export default observer(AlertHistoryContent);
