import { ClipLoader } from "react-spinners";

const LoadingScreen = ({ message }: { message?: string }) => (
  <div className="bg-background flex h-screen">
    <div className="m-auto flex flex-col gap-4 justify-center items-center">
      {message && <p>{message}</p>}
      <ClipLoader />
    </div>
  </div>
);

export default LoadingScreen;
