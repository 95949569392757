import { ReactNode } from "react";

const PlaceholderContainer = ({ children, sidebar }: { children: ReactNode; sidebar?: ReactNode }) => (
  <div className="bg-background w-full h-full flex flex-row overflow-y-auto" role="region" aria-label="placeholder">
    <div className="h-full flex-1 p-8 flex flex-col gap-8 justify-center items-center">{children}</div>
    {sidebar}
  </div>
);

export default PlaceholderContainer;
