import SupportEmailLink from "@/components/common/elements/SupportEmailLink";

export const SupportPrompt = ({
  issueTitle,
  failedProcessCode,
}: {
  issueTitle: string;
  failedProcessCode?: string;
}) => (
  <span>
    <span>
      If this issue persists after attempting the above steps,{" "}
      <SupportEmailLink issueTitle={issueTitle} failedProcessCode={failedProcessCode} />.{" "}
    </span>
    <span>If possible, please include a screenshot of this page in your email.</span>
  </span>
);

export default SupportPrompt;
