import {
  WhiteboardFile,
  WhiteboardRoom,
  ConversionResponse as FileConversionResponse,
} from "@parallel/vertex/types/whiteboard.types";
import { BaseAPI } from "./base.api";

export class WhiteboardAPI extends BaseAPI {
  public prepareWhiteboardRoom = (roomId: string, userId: string): Promise<WhiteboardRoom> =>
    this.instance.get(`/whiteboard/${roomId}/token/${userId}`).then(r => r.data);

  public uploadWhiteboardFile = (roomId: string, file: File): Promise<WhiteboardFile> => {
    const formData = new FormData();
    formData.append("uploadFile", file, file.name);

    return this.instance.post(`whiteboard/${roomId}/file`, formData).then(r => r.data);
  };

  public convertFileForWhiteboard = ({
    fileUrl,
    fileType,
  }: {
    fileUrl: string;
    fileType: "Pdf" | "Powerpoint";
  }): Promise<FileConversionResponse> =>
    this.instance
      .post(`whiteboard/file/prepare`, {
        fileUrl,
        fileType,
      })
      .then(r => r.data);
}
