import { ReactNode, useContext } from "react";
import { IonIcon } from "@ionic/react";
import { people } from "ionicons/icons";
import { observer } from "mobx-react-lite";
import { UserStoreContext } from "@/stores";

const DefaultPlaceholderChild = observer(({ text }: { text?: string }) => {
  const userInitials = useContext(UserStoreContext).initials;

  const content = text || userInitials;
  return (
    <div className="h-full aspect-square rounded-full bg-ocean grid place-items-center">
      <span className="text-white text-2xl">{content}</span>
    </div>
  );
});

export const VideoPlaceholderContainer = ({
  className = "bg-gray-300",
  text,
  children,
}: {
  className?: string;
  text?: string;
  children?: ReactNode;
}) => {
  return (
    <div
      className={`h-full w-full rounded-xl grid place-items-center text-center ${className}`}
      role="region"
      aria-label="video-placeholder"
    >
      {children || <DefaultPlaceholderChild text={text} />}
    </div>
  );
};

export const VideoImagePlaceholder = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <VideoPlaceholderContainer>
      <div
        className="w-full h-full"
        style={{
          backgroundImage: `url("${imageUrl}")`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
    </VideoPlaceholderContainer>
  );
};

const VideoPlaceholder = ({
  icon = <IonIcon icon={people} className="text-5xl text-neutral-icon" />,
  text,
}: {
  icon?: ReactNode;
  text?: string;
}) => (
  <VideoPlaceholderContainer>
    <div className="flex flex-col justify-center items-center gap-y-4">
      <div className="h-24 w-24 rounded-full bg-white flex justify-center items-center flex-row">{icon}</div>
      {text && <div className="flex flex-row justify-center text-xl">{text}</div>}
    </div>
  </VideoPlaceholderContainer>
);

export default VideoPlaceholder;
