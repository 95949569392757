import { ReactNode, useContext } from "react";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import {
  Chat,
  ChatAutoComplete,
  Channel,
  ChannelHeader,
  MessageTimestamp,
  useMessageContext,
  MessageInput,
  MessageList,
  SendButton,
  useMessageInputContext,
  Thread,
  Window,
  MessageText,
} from "stream-chat-react";
import "stream-chat-react/dist/css/v2/index.css";
import SupportPrompt from "@/components/common/content/error/SupportPrompt";
import { ChatStoreContext } from "@/stores";
import "./customStyles.css";

const CustomMessage = () => {
  const { isMyMessage, message } = useMessageContext();

  const messageClass = isMyMessage() ? "bg-gray-200" : "bg-blue-200";

  return (
    <div className={`rounded-lg pt-3 w-full group`}>
      <div className={`p-2 rounded-lg ${messageClass}`}>
        <div className="flex flex-col md:flex-row items-center">
          <span className="text-gray-700 text-md">{message.user?.name}</span>
          <MessageTimestamp customClass="text-gray-500 text-xs pl-2" format="hh:mm a" />
        </div>
        <div className="pl-3 pt-2">
          <MessageText customInnerClass="text-black" />
        </div>
      </div>
    </div>
  );
};

const CustomMessageInput = () => {
  const { handleSubmit } = useMessageInputContext();

  return (
    <div className="flex flex-col str-chat__input-flat str-chat__input-flat--send-button-active">
      <div className="str-chat__input-flat str-chat__input-flat--send-button-active">
        <div className="flex">
          <ChatAutoComplete placeholder="Type your message..." rows={2} />
          <SendButton sendMessage={handleSubmit} className="p-2 pb-3 rounded-r-md">
            <span id="sendButton">
              <PaperAirplaneIcon className="h-5 w-5 text-white rotate-90" />
            </span>
          </SendButton>
        </div>
      </div>
    </div>
  );
};

const ChatWindow = () => {
  const chatStore = useContext(ChatStoreContext);
  const { errorMessage } = chatStore;

  let placeholder: ReactNode | undefined;
  if (errorMessage) {
    placeholder = (
      <div className="flex flex-col gap-4">
        <p>{capitalize(errorMessage)}</p>
        <p>
          Please try refreshing the page. <SupportPrompt issueTitle="Chat Connection Issue" />
        </p>
      </div>
    );
  } else if (!chatStore.channel) {
    placeholder = "Loading";
  }

  return (
    <div className="w-full h-full rounded-xl p-2 z-0">
      {!placeholder ? (
        <Chat client={chatStore.client}>
          <Channel channel={chatStore.channel} Message={observer(CustomMessage)}>
            <Window>
              <ChannelHeader />
              <MessageList />
              <MessageInput Input={CustomMessageInput} />
            </Window>
            <Thread Input={CustomMessageInput} Message={observer(CustomMessage)} />
          </Channel>
        </Chat>
      ) : (
        <div className="w-full h-full p-8 grid place-items-center">{placeholder}</div>
      )}
    </div>
  );
};

export default observer(ChatWindow);
