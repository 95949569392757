import { useContext, useState } from "react";
import * as ReactDOM from "react-dom/client";
import { ClipLoader } from "react-spinners";
import type { AppContext, NetlessApp, AppContext as NetlessAppContext } from "@netless/window-manager";
import { StaticWhiteboardScene } from "@parallel/vertex/types/whiteboard.types";
import config from "@/config";
import { AlertStoreContext, ApiStoreContext, WhiteboardStoreContext, loggerContext } from "@/stores";
import { initLogger } from "@/utils/logging.utils";

type FileState = {
  fileName: string;
  file?: ArrayBuffer;
  type?: string;
};
const VALID_EXTENSIONS = [".jpg", ".jpeg", ".png", ".mp4", ".pptx", ".ppt", ".pdf"];

export const FileUploadApp: NetlessApp = {
  kind: "FileUpload",
  setup: (context: NetlessAppContext) => {
    const box = context.getBox();
    context.mountView(box.$content);
    mount(box.$content, context);
  },
};

const FileUploader = ({ context }: { context: AppContext }) => {
  // init here b/c this is imported by a store
  const logger = initLogger("FileUploader", loggerContext);

  const { whiteboardApi } = useContext(ApiStoreContext);
  const whiteboardStore = useContext(WhiteboardStoreContext);
  const alertStore = useContext(AlertStoreContext);

  const [fileState, setFileState] = useState<FileState>({ fileName: "" });
  const [loadingMessage, setLoadingMessage] = useState("");
  const fastboardApp = whiteboardStore.fastboardApp;
  const room = context.getRoom();

  // file select input change handler
  const fileInputOnChange = (e: any) => {
    try {
      const file = e.target.files[0];
      if (!file) return;
      const fileName = file.name;
      const blob = new Blob([file], { type: file.type });
      if (blob) {
        blob.arrayBuffer().then(buf => {
          setFileState({ file: buf, fileName, type: file.type });
        });
      }
    } catch (err: any) {
      logger.operationError("readFileInput", err, { files: e.target.files });
    }
  };

  const fileSubmitButtonOnclick = async () => {
    const roomId = room?.uuid;
    setLoadingMessage("File upload in process...");
    if (fileState.file && roomId) {
      const fileToUpload = new File([fileState.file], fileState.fileName, {
        type: fileState.type,
      });
      const whiteboardFile = await whiteboardApi.uploadWhiteboardFile(roomId, fileToUpload);
      const { fileName, url } = whiteboardFile;
      try {
        if (fileName.endsWith(".pptx") || fileName.endsWith(".ppt")) {
          const gcpFileUrl = config.whiteboardStorageUrl;
          setLoadingMessage("Converting powerpoint file for whiteboard...");

          const conversionResponse = await whiteboardApi.convertFileForWhiteboard({
            fileUrl: url,
            fileType: "Powerpoint",
          });
          await fastboardApp?.insertDocs({
            fileType: "pptx",
            scenePath: `/pptx/${conversionResponse.uuid}`,
            taskId: conversionResponse.uuid,
            title: fileName,
            url: gcpFileUrl,
          });
          setLoadingMessage("");
        } else if (fileName.endsWith(".pdf")) {
          const conversionResponse = await whiteboardApi.convertFileForWhiteboard({
            fileUrl: url,
            fileType: "Pdf",
          });
          const whiteboardScenes =
            conversionResponse?.images?.map((img: StaticWhiteboardScene) => ({
              name: img.name,
              ppt: img.whiteboardScenePage,
            })) || [];

          await fastboardApp?.insertDocs({
            fileType: "pdf",
            scenePath: `/pdf/${conversionResponse.uuid}`,
            title: fileName,
            scenes: whiteboardScenes,
          });

          setLoadingMessage("");
        } else {
          room.insertImage({
            width: 400,
            height: 400,
            uuid: fileName,
            centerX: 0,
            centerY: 0,
            locked: false,
          });
          room.completeImageUpload(fileName, url);
          setLoadingMessage("");
        }
      } catch (err) {
        setLoadingMessage("");
        logger.operationError("uploadWhiteboardFile", err, { roomId, fileState });
        alertStore.push("Error uploading whiteboard file", { resolution: "retry" });
      }
    }
  };

  return (
    <div className="flex justify-center pt-16">
      {loadingMessage ? (
        <div className="absolute top-0 left-0 w-full h-full grid place-items-center">
          <div className="absolute top-0 left-0 w-full h-full bg-gray-500 opacity-10 rounded-b-md" />
          <p className="text-navy">{loadingMessage}</p>
          <ClipLoader />
        </div>
      ) : (
        <>
          <input
            onChange={fileInputOnChange}
            type="file"
            id="file"
            accept={`${VALID_EXTENSIONS.join(",")}`}
            className="file:bg-inactive file:p-1 file:h-8 file:rounded-lg file:border file:border-gray-400 file:border-solid file:cursor-pointer file:mr-4 file:shadow-none hover:file:bg-blue-200 active:file:bg-blue-400"
          />
          <button
            onClick={async () => await fileSubmitButtonOnclick()}
            className="bg-inactive p-1 h-8 rounded-lg border border-gray-400 cursor-pointer whitespace-nowrap hover:bg-blue-200 active:bg-blue-400"
          >
            Submit
          </button>
        </>
      )}
    </div>
  );
};

export const mount = (dom: HTMLElement, context: AppContext) => {
  const root = ReactDOM.createRoot(dom);
  root.render(<FileUploader context={context} />);
};
