import { useState } from "react";
import config from "@/config";

/**
 * Allow adding / removing placeholder windows to individually test layouts with many participants.
 *  Will only display controls for these in non-production environment.
 */

export const usePlaceholderImages = () => {
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  return {
    imageUrls,
    add: () => setImageUrls([...imageUrls, `http://cataas.com/cat?${imageUrls.length}`]),
    remove: () => setImageUrls(imageUrls.slice(0, -1)),
  };
};

export const PlaceholderControls = ({
  addPlaceholderWindow,
  removePlaceholderWindow,
}: {
  addPlaceholderWindow: () => void;
  removePlaceholderWindow: () => void;
}) => {
  if (config.parallelEnv === "production") return <></>;
  return (
    <div className="absolute top-8 left-1/2 flex gap-2">
      <button onClick={addPlaceholderWindow} className="cursor-pointer" aria-label="placeholder-add">
        Add
      </button>
      <button onClick={removePlaceholderWindow} className="cursor-pointer" aria-label="placeholder-remove">
        Remove
      </button>
    </div>
  );
};
