import { ReactNode } from "react";
import { Tooltip as ReactTooltip, PlacesType } from "react-tooltip";

interface IToolTip {
  id: string;
  place?: PlacesType;
  children?: ReactNode;
  delayShow?: number;
}

const ToolTip = ({ id, place = "right", delayShow = 0, children }: IToolTip) => {
  return (
    <ReactTooltip className="text-sm z-50" place={place} id={id} delayShow={delayShow}>
      {children}
    </ReactTooltip>
  );
};

export default ToolTip;
