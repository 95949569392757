import { makeAutoObservable } from "mobx";
import { AssessmentStimulusTestGroup, AssessmentTestSelection } from "@parallel/vertex/types/assessment.types";
import { Position } from "@/interfaces/geometry";
import { TelehealthLogger } from "@/utils/logging.utils";
import { ActivityStore } from "./activity.store";

export const DEFAULT_STIMULUS_INTERACTION_DETAILS = {
  mousePosition: { x: 0.5, y: 0.5 },
};

export type StimulusInteractionDetails = {
  mousePosition: Position;
  mouseClick?: Position;
};

export class AssessmentStore {
  metadata: AssessmentStimulusTestGroup[] = [];
  isLoading = false;

  search = "";

  remoteStimulus: StimulusInteractionDetails = DEFAULT_STIMULUS_INTERACTION_DETAILS;
  localStimulus: StimulusInteractionDetails = DEFAULT_STIMULUS_INTERACTION_DETAILS;

  public broadcastMouseMove: (position: Position) => unknown = () =>
    this.logger.warn("broadcastMouseMove fn undefined");

  public broadcastMouseClick: (position: Position) => unknown = () =>
    this.logger.warn("broadcastMouseClick fn undefined");

  constructor(
    private activityStore: ActivityStore,
    private logger: TelehealthLogger,
  ) {
    makeAutoObservable(this);
  }

  // return all available assessments filtered by some search string
  //  only filters tests - does not exclude any group
  get filteredMetadata(): AssessmentStimulusTestGroup[] {
    const filterGroupTests = (group: AssessmentStimulusTestGroup): AssessmentStimulusTestGroup => ({
      ...group,
      availableTests: group.availableTests.filter(a => this.isSearchMatch(a.name)),
      subgroups: group.subgroups.map(filterGroupTests),
    });
    return this.metadata.map(filterGroupTests);
  }

  get flatAssessments(): AssessmentTestSelection[] {
    return this.flattenTests(this.metadata);
  }

  get filteredFlatAssessments(): AssessmentTestSelection[] {
    return this.flattenTests(this.filteredMetadata);
  }

  get filteredFlatAssessmentCount() {
    return this.filteredFlatAssessments.length;
  }

  isSearchMatch(test?: string) {
    if (!this.search) return true;
    else if (!test) return false;
    else return test.toLowerCase().includes(this.search.toLowerCase());
  }

  flattenTests(groups: AssessmentStimulusTestGroup[]): AssessmentTestSelection[] {
    const flatten = (group: AssessmentStimulusTestGroup): AssessmentTestSelection[] => [
      ...group.availableTests,
      ...group.subgroups.flatMap(flatten),
    ];

    return groups.flatMap(flatten);
  }

  getGroupSize(group: AssessmentStimulusTestGroup) {
    return this.flattenTests([group]).length;
  }

  async setSelected(assessment: AssessmentTestSelection | null) {
    if (!assessment) {
      if (this.activityStore.currActivityType === "assessment") await this.activityStore.setAssessmentStimulus(null);
      return;
    }
    if (assessment.id === this.activityStore.currAssessmentTest?.id) return;

    this.localStimulus = DEFAULT_STIMULUS_INTERACTION_DETAILS;
    this.remoteStimulus = DEFAULT_STIMULUS_INTERACTION_DETAILS;

    await this.activityStore.setAssessmentStimulus(assessment);

    this.logger.operationSuccess("setSelected", { assessment });
  }

  selectNext() {
    return this.incrementSelection(1);
  }

  selectPrevious() {
    return this.incrementSelection(-1);
  }

  incrementSelection(delta: number) {
    const currTest = this.activityStore.currAssessmentTest;
    if (!currTest) return;

    const flattened = this.filteredFlatAssessments;
    const currIndex = flattened.findIndex(a => a.id === currTest.id);
    if (!currIndex && currIndex !== 0) {
      this.logger.error("unable to find current selected assessment in flattened list", { currTest, flattened });
      return;
    }

    const next = flattened[currIndex + delta];
    if (next) this.setSelected(next);
    return next;
  }

  getGroupPath(
    groupNames: string[],
    searchGroups: AssessmentStimulusTestGroup[] = this.filteredMetadata,
  ): AssessmentStimulusTestGroup[] | null {
    if (!groupNames[0]) return [];

    const groupMatch = searchGroups.find(g => g.name === groupNames[0]);
    if (!groupMatch) return null;

    const rest = this.getGroupPath(groupNames.slice(1), groupMatch.subgroups);
    if (!rest) return null;

    return [groupMatch, ...rest];
  }

  setLocalStimulusMousePosition(mousePosition: Position) {
    this.localStimulus = { ...this.localStimulus, mousePosition };
    this.broadcastMouseMove(mousePosition);
  }

  setLocalStimulusMouseClick(mouseClick: Position) {
    this.localStimulus = { ...this.localStimulus, mouseClick };
    this.broadcastMouseClick(mouseClick);
  }

  remoteUpdate(update: Partial<StimulusInteractionDetails>) {
    this.remoteStimulus = { ...this.remoteStimulus, ...update };
  }

  resetStimulus() {
    this.localStimulus = DEFAULT_STIMULUS_INTERACTION_DETAILS;
    this.remoteStimulus = DEFAULT_STIMULUS_INTERACTION_DETAILS;
  }
}
