import { useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { AlertStoreContext, loggerContext } from "@/stores";
import { initLogger } from "@/utils/logging.utils";

const logger = initLogger("VideoPreview", loggerContext);

const VideoPreview = ({ userStream, onReady }: { userStream?: MediaStream; onReady: () => void }) => {
  const alertStore = useContext(AlertStoreContext);
  const videoRef = useRef<HTMLVideoElement>(null!);
  const [isPendingPlay, setIsPendingPlay] = useState(false);
  useEffect(() => {
    (async () => {
      const currStream = videoRef.current.srcObject as MediaStream | null;
      if (!videoRef.current || !userStream || isPendingPlay || !userStream.active || userStream.id === currStream?.id)
        return;
      setIsPendingPlay(true);
      try {
        if (videoRef.current.srcObject) videoRef.current.load();
        videoRef.current.srcObject = userStream;
        await videoRef.current
          .play()
          .then(logger.operationSuccessHandler("playPreviewVideo"))
          .catch(e => logger.warn("error playing preview media", {}, e));
      } catch (e) {
        logger.warn("unexpected video preview error", undefined, e);
        alertStore.push("Error starting local video preview", { severity: "warn", resolution: "refresh" });
      } finally {
        setIsPendingPlay(false);
        onReady();
      }
    })();
  }, [videoRef, userStream, isPendingPlay]);

  return (
    <div className="rounded-xl shadow-lg bg-black w-full aspect-video overflow-hidden">
      <video
        id="video"
        className="w-full h-full object-cover"
        autoPlay
        muted={true}
        ref={videoRef}
        style={{ transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)" }}
      ></video>
    </div>
  );
};

export default observer(VideoPreview);
