import { pick, sortBy } from "lodash";
import { LayoutType } from "./common";

export const defaultMaxRowSizes: Record<LayoutType, number> = {
  [LayoutType.Focused]: 1,
  [LayoutType.Halved]: 5,
  [LayoutType.Equal]: 2,
  [LayoutType.PrimaryOnly]: 0,
};

const maxAspectRatioDelta = (lastRowSpaces: number) => {
  switch (lastRowSpaces) {
    case 0:
      return 2;
    case 1:
      return 1;
    case 2:
      return 0.5;
    default:
      return 0.2;
  }
};

type IWindowGridConditions = {
  maxRowSize: number;
  rowCount: number;
  lastRowSpaces: number;
  aspectRatioDelta: number;
  resultAspectRatio: number;
};

/**
 * Pick the max row size between 1-4 windows that results in the display with the least amount of dead space.
 *
 * To determine "dead space", calculate + the following criteria:
 *  (a) empty spaces on the last row
 *  (b) difference between the window aspect ratio + 16:9 video aspect ratio (more difference -> more black)
 *
 * See below for more details on the specific picking logic.
 */
const pickBestWindowGrid = (
  layout: LayoutType,
  windowCount: number,
  containerArea?: { width: number; height: number },
) => {
  if (!containerArea) {
    const defaultMaxRowSize = defaultMaxRowSizes[layout];
    return {
      maxRowSize: defaultMaxRowSize,
      rowCount: Math.ceil(windowCount / defaultMaxRowSize),
    };
  }

  const conditions = Array(4)
    .fill(null)
    .map((_, i) => {
      const maxRowSize = i + 1;
      if (maxRowSize > windowCount) return null;

      const rowCount = Math.ceil(windowCount / maxRowSize);

      const lastRowSize = windowCount % maxRowSize;
      const resultAspectRatio = containerArea.width / maxRowSize / (containerArea.height / rowCount);

      return {
        maxRowSize,
        rowCount,
        lastRowSpaces: lastRowSize === 0 ? 0 : maxRowSize - lastRowSize,
        aspectRatioDelta: Math.abs(16 / 9 - resultAspectRatio),
        resultAspectRatio,
      };
    })
    .filter(x => !!x) as IWindowGridConditions[];

  // Pick the grid options with the fewest last row spaces + an aspect ratio within some allowance.
  //  Scale the aspect ratio allowance by last row spaces - when more space is taken up, allow for more aspect ratio difference.
  //  If none of the options meet this criteria, just pick the option with the lowest aspect ratio difference.
  const ordered = sortBy(conditions, "lastRowSpaces");
  const constrained = ordered.find(c => c.aspectRatioDelta <= maxAspectRatioDelta(c.lastRowSpaces));
  const best = constrained || sortBy(conditions, c => c.aspectRatioDelta - maxAspectRatioDelta(c.lastRowSpaces))[0];

  return pick(best, ["maxRowSize", "rowCount"]);
};

// percent string ("x%") for width/height style needed to fit `n` items in a container
const fitPercent = (n: number) => `${Math.floor((1 / n) * 100)}%`;

const resolveSecondaryWindowStyle = (
  layout: LayoutType,
  windowCount: number,
  containerArea?: { width: number; height: number },
) => {
  const { maxRowSize, rowCount } = pickBestWindowGrid(layout, windowCount, containerArea);

  return {
    width: fitPercent(maxRowSize),
    height: fitPercent(rowCount),
    flexGrow: rowCount > 1 ? 0 : 1,
  };
};

export default resolveSecondaryWindowStyle;
