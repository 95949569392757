import { ReactNode } from "react";

const Link = ({
  href,
  newTab = true,
  onClick,
  className = "text-ocean underline cursor-pointer",
  children,
  onBlur,
}: {
  href?: string;
  newTab?: boolean;
  onBlur?: () => void;
  onClick?: () => void;
  className?: string;
  children: ReactNode;
}) => (
  <a
    className={className}
    target={newTab ? "_blank" : ""}
    rel={newTab ? "noopener noreferrer" : ""}
    href={href}
    onKeyDown={e => e.key === "Enter" && onClick && onClick()}
    onClick={onClick}
    tabIndex={0}
    onBlur={onBlur}
  >
    {children}
  </a>
);

export default Link;
