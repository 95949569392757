import { CheckIcon } from "@heroicons/react/20/solid";
import { classNames } from "@/utils";

const ProgressStepper = ({ totalSteps = 1, currentStep = 0 }: { totalSteps: number; currentStep: number }) => {
  const steps = Array(totalSteps)
    .fill(0)
    .map((_, i) => i);

  return (
    <nav aria-label="Progress">
      <ol className="flex items-center">
        {steps.map((step: number) => (
          <li key={step} className={classNames(step !== steps.length - 1 ? "pr-8 sm:pr-20" : "", "relative")}>
            {step < currentStep ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-ocean" />
                </div>
                <div className="relative flex h-8 w-8 items-center justify-center rounded-full bg-ocean hover:bg-navy">
                  <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                  <span className="sr-only">Step {step}</span>
                </div>
              </>
            ) : step === currentStep ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div
                  className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white"
                  aria-current="step"
                >
                  <span className="h-2.5 w-2.5 rounded-full bg-ocean" aria-hidden="true" />
                  <span className="sr-only">Step {step}</span>
                </div>
              </>
            ) : (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Step {step}</span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default ProgressStepper;
