import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { UserStoreContext } from "@/stores";
import ActivityControls from "./ActivityControls";
import MediaControls from "./MediaControls";
import NameControl from "./NameControl";

type MediaControlToggle = {
  status: "pending" | "enabled" | "disabled";
  toggle: () => unknown;
};

export type Participant = {
  participantKey: string;
  isRemote: boolean;
  isScreenshare: boolean;
  isDocumentCamera?: boolean;
  displayName: string;
  updateName?: (n: string) => Promise<unknown>;
  video?: MediaControlToggle;
  audio?: MediaControlToggle;
  isKicked?: boolean;
};

const ParticipantWindowControls = ({
  participant,
  isPinningDisabled,
}: {
  participant: Participant;
  isPinningDisabled: boolean;
}) => {
  const { isStaff } = useContext(UserStoreContext);
  const { isRemote, isScreenshare, updateName } = participant;
  let displayName = participant.displayName;
  if (!isRemote) {
    const suffix = isScreenshare ? "Your Screen" : "You";
    displayName = `${displayName} (${suffix})`;
  }
  return (
    <>
      <div className="flex flex-row justify-between items-end absolute bottom-0 left-0 p-3 w-full max-h-full gap-2">
        <div className="relative lg:max-w-[calc(100%-65px)] md:max-w-[calc(100%-25px)] sm:max-w-[calc(100%-55px)] whitespace-nowrap">
          <NameControl displayName={displayName} updateName={updateName} />
        </div>
        {isStaff && (
          <div className="flex-row grow w-4 hidden justify-end gap-2 pr-2 lg:group-hover:flex md:group-hover:flex sm:group-hover:flex flex-wrap">
            <MediaControls participant={participant} isPinningDisabled={isPinningDisabled} />
          </div>
        )}
      </div>
      <div className="absolute top-0 right-0 flex flex-row gap-2 p-3">
        <ActivityControls participantKey={participant.participantKey} />
      </div>
    </>
  );
};

export default observer(ParticipantWindowControls);
