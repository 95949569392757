import { useDaily } from "@daily-co/daily-react";
import { isString } from "lodash";
import { SimpleHeaderLayout } from "@/components/common/layout/HeaderLayout";
import PlaceholderContainer from "@/components/common/layout/PlaceholderContainer";
import AppointmentWaitingRoom from "@/components/waiting-room/DailyAppointmentWaitingRoom";
import LoadingScreen from "@/screens/LoadingScreen";
import { useValidAppointmentParamAccess } from "@/utils/hooks/use-valid-access";

const AppointmentWaitingRoomScreen = () => {
  const propsOrMessage = useValidAppointmentParamAccess();
  const dailyCall = useDaily();

  if (isString(propsOrMessage)) return <LoadingScreen message={propsOrMessage} />;
  if (!dailyCall) return <PlaceholderContainer>Unexpected error loading meeting video chat</PlaceholderContainer>;

  return (
    <SimpleHeaderLayout isMeeting={true}>
      <AppointmentWaitingRoom dailyCall={dailyCall} {...propsOrMessage} />
    </SimpleHeaderLayout>
  );
};

export default AppointmentWaitingRoomScreen;
