export const round = (n: number, places: number) => Math.round((n + Number.EPSILON) * (10 * places)) / (10 * places);

export const digitalClock = (seconds: number) => {
  const secondsRounded = Math.round(seconds);
  const minutes = Math.floor(secondsRounded / 60);
  const secondsRemainder = secondsRounded % 60;
  return `${minutes}:${secondsRemainder.toString().padStart(2, "0")}`;
};

const isSafari =
  navigator.userAgent.includes("Safari") &&
  !navigator.userAgent.includes("CriOS") &&
  !navigator.userAgent.includes("FxiOS");

// Agora supports iOS versions >= 12.2 on Safari, >= 14.3 on other browsers
// see https://docs.agora.io/en/Video/web_sdk_compatibility?platform=Web#ios
export const isUnsupportedIOS = () => {
  const isOldIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  // iPads w/ iOS >= 13.x do not include iPad in their userAgent
  const isAppleDevice = navigator.userAgent.includes("Macintosh");
  const isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

  const isIOS = isOldIOS || (isAppleDevice && isTouchScreen);

  const [_, majorVersion, minorVersion] = (navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/) || []).map(n =>
    parseInt(n),
  );
  const versionNotSupported =
    majorVersion &&
    minorVersion &&
    (isSafari ? majorVersion <= 12 && minorVersion <= 2 : majorVersion <= 14 && minorVersion <= 3);

  return isIOS && versionNotSupported;
};

export const isAscii = (str: string) => /^[\x00-\x7F]+$/.test(str);

export const classNames = (...classes: (string | undefined)[]) => classes.filter(Boolean).join(" ");

// makes string usable for HTML id / React key
export const toToken = (s: string) => s.toLowerCase().replace(/ /g, "_");
