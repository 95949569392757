import { ReactNode, useContext, useEffect, useState } from "react";
import { DailyCall } from "@daily-co/daily-js";
import { useAppMessage, useDailyEvent, useDevices, useLocalSessionId } from "@daily-co/daily-react";
import { useDeepCompareEffect, useUnmountEffect } from "@react-hookz/web";
import { omit } from "lodash";
import { observer } from "mobx-react-lite";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import LoadingPlaceholder from "@/components/common/content/LoadingPlaceholder";
import Modal from "@/components/common/modal/Modal";
import LiveCaptions from "@/components/meeting/captioning/LiveCaptions";
import SpeechRecognition from "@/components/meeting/captioning/SpeechRecognition";
import { StoreContext, loggerContext } from "@/stores";
import { useAppMessageCallback, useInitialDailyStateSync, useTrackStartedCallback } from "@/utils/daily.utils";
import { DailyMeetingConnectionParams, useDailyMeetingConnection } from "@/utils/hooks/use-meeting-connection";
import { initLogger } from "@/utils/logging.utils";
import ClientFeedbackSurvey from "../activity/survey/ClientFeedbackSurvey";
import { MeetingFinished, MeetingPlaceholderContainer } from "../layout/MeetingPlaceholder";
import { NotificationModal, SessionRecordingModal } from "../layout/modals";
import { DeviceContext, DeviceContextData } from "../layout/sidebar/content/SettingsContent";
import DailyMeetingWindowLayout from "./DailyMeetingWindowLayout";
import { DailyAudio } from "./DailyParticipantMedia";

const logger = initLogger("DailyMeeting", loggerContext);

export type DailyMeetingProps = {
  dailyCall: DailyCall;
  meetingKey: string;
  userId: string;
  participantKey: string;
  loadingText: string;
  appointment?: ExtendedAppointment;
};

const DailyMeeting = (props: DailyMeetingProps) => {
  const { dailyCall, meetingKey, userId, participantKey, loadingText, appointment } = props;
  const {
    participantStore,
    meetingStore,
    userStore: { fullName, isStaff },
  } = useContext(StoreContext);
  const { isStartingSessionRecording, notification, feedbackFormTemplateId } = meetingStore;
  const { localFeedbackSurveyStatus } = participantStore;

  const connectionParams: DailyMeetingConnectionParams = {
    dailyCall,
    meetingKey,
    participantKey,
    user: { id: userId, name: fullName, type: isStaff ? "provider" : "client" },
  };
  const { isFinished, error, reconnect } = useDailyMeetingConnection(connectionParams);
  useUnmountEffect(() => {
    if (!isFinished) return;
    dailyCall.leave();
  });

  const sendAppMessage = useAppMessage({
    onAppMessage: useAppMessageCallback(dailyCall, participantKey, meetingKey, logger),
  });
  const { cameras: dailyCameras, setCamera, microphones: dailyMics, setMicrophone } = useDevices();
  const cameras = dailyCameras.map(c => ({
    deviceId: c.device.deviceId,
    label: c.device.label,
    isEnabled: c.selected,
  }));
  const localSessionId = useLocalSessionId();
  const { isSynced } = useInitialDailyStateSync(sendAppMessage, localSessionId);

  useDeepCompareEffect(() => {
    if (!isSynced) return;
    participantStore.updateLocalParticipant({ cameras });
  }, [cameras, isSynced]);

  const [customAudioTrack, setCustomAudioTrack] = useState<MediaStreamTrack>();
  useDailyEvent("track-started", useTrackStartedCallback(localSessionId, setCustomAudioTrack, logger));

  let content = (
    <>
      <DailyMeetingWindowLayout meetingKey={meetingKey} /> {!isStaff && <LiveCaptions />}
    </>
  );

  useEffect(() => {
    if (localFeedbackSurveyStatus === "pending") participantStore.receivedClientFeedbackSurvey();
  }, [localFeedbackSurveyStatus]);

  let modal: ReactNode = undefined;
  if (localFeedbackSurveyStatus === "in-progress" && !!feedbackFormTemplateId) {
    modal = (
      <Modal title="Feedback Survey">
        <div className="w-[800px]">
          <ClientFeedbackSurvey
            feedbackFormTemplateId={feedbackFormTemplateId}
            appointmentId={appointment?.appointmentId}
            userId={userId}
          />
        </div>
      </Modal>
    );
  } else if (notification) {
    modal = <NotificationModal notification={notification} />;
  } else if (isStartingSessionRecording) {
    modal = <SessionRecordingModal />;
  }

  if (!isFinished) {
    content = (
      <MeetingPlaceholderContainer>
        <LoadingPlaceholder text={loadingText} />
      </MeetingPlaceholderContainer>
    );
  } else if (error) {
    content = <MeetingPlaceholderContainer>Error connecting to Daily</MeetingPlaceholderContainer>;
  } else if (!localSessionId) {
    content = <MeetingFinished userId={userId} reconnect={reconnect} appointment={appointment} />;
  }

  const isPageReady = isFinished && !error && !!localSessionId;
  useEffect(() => {
    if (!isPageReady) return;
    logger.postEvent("PageReady", "meeting ready", {
      props: omit(props, "dailyCall"),
      connectionParams: omit(connectionParams, "dailyCall"),
    });
  }, [isPageReady]);

  const devices: DeviceContextData = {
    mics: dailyMics.map(m => ({
      deviceId: m.device.deviceId,
      label: m.device.label,
      isEnabled: m.selected,
      setEnabled: () => setMicrophone(m.device.deviceId),
    })),
    cameras: cameras.map(c => ({
      ...c,
      setEnabled: () => setCamera(c.deviceId),
    })),
  };

  return (
    <DeviceContext.Provider value={devices}>
      {content}
      {modal}
      {customAudioTrack && <DailyAudio audioTrack={customAudioTrack} />}
      {!!meetingKey && !!participantKey && (
        <SpeechRecognition meetingKey={meetingKey} participantKey={participantKey} />
      )}
    </DeviceContext.Provider>
  );
};

export default observer(DailyMeeting);
