import { useContext } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import ObjectiveMetricInput from "@parallel/polygon/components/progress/metric/ObjectiveMetricInput";
import {
  ProcessButton,
  StatusValue,
  useAsyncProcessStatus,
} from "@parallel/polygon/components/shared/input/status.input";
import { ObjectiveMetricBody, StudentObjective } from "@parallel/vertex/types/progress.types";
import { StoreContext } from "@/stores";

const MetricWriteStatus = ({ status }: { status: StatusValue }) => {
  switch (status) {
    case "loading":
      return (
        <>
          <CircularProgress size={16} />
          <Typography variant="body2">Loading</Typography>
        </>
      );
    case "success":
      return (
        <>
          <CheckCircleOutlineIcon />
          <Typography variant="body2">Update Saved</Typography>
        </>
      );
    case "failure":
      return (
        <>
          <ErrorOutlineIcon />
          <Typography variant="body2">Error Saving Update</Typography>
        </>
      );
  }
};

const ProgressSidebarInput = ({ selectedObjective }: { selectedObjective: StudentObjective }) => {
  const { meetingStore } = useContext(StoreContext);

  const { status: metricStatus, perform: upsertMetric } = useAsyncProcessStatus(
    (body: ObjectiveMetricBody) => meetingStore.upsertObjectiveMetric(selectedObjective.objectiveId, body),
    { resetStatusTimeoutMs: 1000 },
  );
  const isCompleted = !!selectedObjective.completedAt;

  return (
    <Stack gap={1}>
      <ObjectiveMetricInput compact objective={selectedObjective} upsertMetric={upsertMetric} />
      {metricStatus ? (
        <Stack width="100%" height={38} direction="row" gap={1} justifyContent="center" alignItems="center">
          <MetricWriteStatus status={metricStatus.value} />
        </Stack>
      ) : (
        <ProcessButton
          startIcon={isCompleted ? <LockOpenIcon /> : <CheckCircleIcon />}
          process={() =>
            meetingStore.setObjectiveCompleted(selectedObjective.objectiveId, !selectedObjective.completedAt)
          }
          loadingOnly
        >
          {isCompleted ? "Re-Open Objective" : "Mark Objective Complete"}
        </ProcessButton>
      )}
    </Stack>
  );
};

export default ProgressSidebarInput;
