import {
  AppointmentProgress,
  ObjectiveMetricBody,
  StudentGoal,
  StudentObjective,
  UpdateGoalBody,
} from "@parallel/vertex/types/progress.types";
import { BaseAPI, BaseApiOptions } from "@/api/base.api";

export class ProgressAPI extends BaseAPI {
  constructor(options: BaseApiOptions) {
    super({ ...options, parseTimestamps: true });
  }

  public getAppointmentProgress = (appointmentId: string): Promise<AppointmentProgress> =>
    this.instance.get(`progress/appointment/${appointmentId}`).then(r => r.data);

  public updateStudentGoal = (studentId: string, goalId: string, body: UpdateGoalBody): Promise<StudentGoal> =>
    this.instance.put(`progress/student/${studentId}/goal/${goalId}`, body).then(r => r.data);

  public upsertObjectiveAppointmentMetric = (
    objectiveId: string,
    appointmentId: string,
    body: ObjectiveMetricBody,
  ): Promise<StudentObjective> =>
    this.instance.put(`progress/objective/${objectiveId}/appointment/${appointmentId}/metric`, body).then(r => r.data);

  public setStudentNote = (studentId: string, appointmentId: string, note: string): Promise<void> =>
    this.instance.put(`progress/student/${studentId}/appointment/${appointmentId}/note`, { note });
}
