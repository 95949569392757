import { useState } from "react";

const emojiLabelStyles = "text-3xl cursor-pointer";

const SmileySlider = ({
  onChangeCallback,
  inputLabel,
}: {
  onChangeCallback: (value: string) => void;
  inputLabel?: string;
}) => {
  const [value, setValue] = useState<string>("");

  const handleValueChange = (val: string) => {
    setValue(val);
    onChangeCallback(val);
  };

  return (
    <div className="flex flex-col items-center gap-8">
      <div className="text-3xl text-center">{inputLabel ? inputLabel : undefined}</div>
      <div className="w-80 flex flex-row gap-2">
        <label className={emojiLabelStyles} onClick={() => handleValueChange("0")}>
          🙁
        </label>
        <input
          type="range"
          className="bg-transparent h-4 w-full cursor-pointer rounded-lg border-transparent bg-inactive accent-ocean mt-4 hover:accent-ocean"
          id="smileySlider"
          min="0"
          max="10"
          step="0.1"
          onChange={e => handleValueChange(e.target.value)}
          value={value}
          size={140}
        />
        <label className={emojiLabelStyles} onClick={() => handleValueChange("10")}>
          🙂
        </label>
      </div>
    </div>
  );
};

export default SmileySlider;
