import { AppointmentAPI } from "@/api/appointment.api";
import { AssessmentAPI } from "@/api/assessment.api";
import { AuthAPI } from "@/api/auth.api";
import { ChatAPI } from "@/api/chat.api";
import { FormAPI } from "@/api/form.api";
import { GameAPI } from "@/api/game.api";
import { MeetingAPI } from "@/api/meeting.api";
import { ProgressAPI } from "@/api/progress.api";
import { RecordingAPI } from "@/api/recording.api";
import { WhiteboardAPI } from "@/api/whiteboard.api";
import { AlertStore } from "@/stores/alert.store";
import { UserStore } from "@/stores/user.store";
import { TelehealthLogger } from "@/utils/logging.utils";

export class ApiStore {
  appointmentApi: AppointmentAPI;
  assessmentApi: AssessmentAPI;
  authApi: AuthAPI;
  chatApi: ChatAPI;
  formApi: FormAPI;
  gameApi: GameAPI;
  meetingApi: MeetingAPI;
  progressApi: ProgressAPI;
  recordingApi: RecordingAPI;
  whiteboardApi: WhiteboardAPI;

  constructor(alertStore: AlertStore, userStore: UserStore, logger: TelehealthLogger) {
    const commonParams = { alertStore, userStore, logger };
    this.appointmentApi = new AppointmentAPI(commonParams);
    this.assessmentApi = new AssessmentAPI(commonParams);
    this.authApi = new AuthAPI({ userStore });
    this.chatApi = new ChatAPI(commonParams);
    this.formApi = new FormAPI(commonParams);
    this.gameApi = new GameAPI(commonParams);
    this.meetingApi = new MeetingAPI(commonParams);
    this.progressApi = new ProgressAPI(commonParams);
    this.recordingApi = new RecordingAPI(commonParams);
    this.whiteboardApi = new WhiteboardAPI(commonParams);
  }
}
