type RgbColor = { r: number; g: number; b: number };

export const hexToRgb = (hex: string): RgbColor => {
  const bigint = parseInt(hex.slice(1), 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
};

const toHex = (c: number) => {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

export const rgbToHex = ({ r, g, b }: RgbColor) => `#${toHex(r)}${toHex(g)}${toHex(b)}`;
