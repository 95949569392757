import { ReactElement, ReactNode, useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { helpCircleOutline } from "ionicons/icons";
import { observer } from "mobx-react-lite";
import HelpContent from "@/components/meeting/layout/sidebar/content/HelpContent";
import { AlertStoreContext } from "@/stores";
import HeaderUserBadge from "../elements/HeaderUserBadge";
import Header, { IonIconHeaderButton } from "./Header";

export const BaseHeaderLayout = ({ header, children }: { header: ReactElement; children: ReactElement }) => {
  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full grow-0">{header}</div>
      <main className="bg-background w-full flex-1 relative overflow-hidden">{children}</main>
    </div>
  );
};

const FloatingHelpContainer = ({
  onClose,
  show = false,
  showAppointment = false,
}: {
  onClose: () => void;
  show?: boolean;
  showAppointment?: boolean;
}) => {
  return (
    <div className="w-80 h-[80vw] py-16 pl-6 fixed top-0 right-0 z-50 pointer-events-none">
      <Transition
        show={show}
        enter="transform transition ease-in duration-300 sm:duration-300"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-300 sm:duration-300"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
        className="flex flex-col bg-white shadow-xl pointer-events-auto max-h-full"
      >
        <div className="w-full p-4 flex flex-row justify-between items-center border-b border-slate-300 text-2xl">
          <span>Help</span>
          <XMarkIcon className="h-4 w-4 cursor-pointer" aria-hidden="true" onClick={onClose} />
        </div>
        <div className="grow w-full overflow-y-auto">
          <HelpContent showAppointment={showAppointment} />
        </div>
      </Transition>
    </div>
  );
};

const _SimpleHeaderLayout = ({
  children,
  showAppointment = false,
  isMeeting,
}: {
  children: ReactNode;
  showAppointment?: boolean;
  isMeeting?: boolean;
}) => {
  const [isHelpEnabled, setIsHelpEnabled] = useState(false);
  const { selectedAlert } = useContext(AlertStoreContext);
  useEffect(() => {
    if (selectedAlert) setIsHelpEnabled(true);
  }, [selectedAlert]);

  const helpButton = (
    <IonIconHeaderButton icon={helpCircleOutline} label="Help" onClick={() => setIsHelpEnabled(!isHelpEnabled)} />
  );
  const header = <Header badges={<HeaderUserBadge isMeeting={isMeeting} />} controls={helpButton} />;

  return (
    <BaseHeaderLayout header={header}>
      <>
        {children}
        <FloatingHelpContainer
          onClose={() => setIsHelpEnabled(false)}
          show={isHelpEnabled}
          showAppointment={showAppointment}
        />
      </>
    </BaseHeaderLayout>
  );
};

export const SimpleHeaderLayout = observer(_SimpleHeaderLayout);
