import { ReactNode, useContext } from "react";
import { DailyTrackState } from "@daily-co/daily-js";
import { useDaily, useParticipantProperty } from "@daily-co/daily-react";
import { observer } from "mobx-react-lite";
import { ParticipantStoreContext } from "@/stores";
import { DailyParticipantData } from "@/utils/daily.utils";
import ParticipantWindowControls, { Participant } from "../layout/controls/ParticipantWindowControls";
import VideoPlaceholder from "../video/VideoPlaceholder";
import DailyParticipantMedia from "./DailyParticipantMedia";

const getMediaStatus = (track: DailyTrackState) => {
  switch (track.state) {
    case "playable":
      return "enabled";
    case "loading":
      return "pending";
    default:
      return "disabled";
  }
};

const DailyParticipantPlaceholder = ({ userName }: { userName?: string }) => {
  let icon: ReactNode | undefined = undefined;
  if (userName) {
    const initials = userName
      .split(" ")
      .map(n => n[0])
      .join("");
    icon = <span className="text-4xl">{initials}</span>;
  }
  return <VideoPlaceholder icon={icon} />;
};

export type DailyParticipantBaseProps = { dailySessionId: string; isScreenshare?: boolean };

export type DailyParticipantProps = DailyParticipantBaseProps & {
  isPinningDisabled?: boolean;
  activeSpeakerId?: string;
};

const DailyParticipant = ({
  dailySessionId,
  isScreenshare = false,
  isPinningDisabled = false,
  activeSpeakerId,
}: DailyParticipantProps) => {
  const participantStore = useContext(ParticipantStoreContext);

  const dailyCall = useDaily();
  const [tracks, untypedUserData, isLocal] = useParticipantProperty(dailySessionId, ["tracks", "userData", "local"]);
  const userData = untypedUserData as DailyParticipantData | undefined;

  let content: ReactNode = <DailyParticipantPlaceholder userName={userData?.displayName} />;
  let participantProps: Participant | undefined = undefined;

  // in rare edge-cases, daily wires can get crossed and `tracks` is undefined
  if (tracks && userData) {
    const videoTrack = isScreenshare ? tracks.screenVideo : tracks.video;
    const videoStatus = getMediaStatus(videoTrack);

    const audioTrack = isScreenshare ? tracks.screenAudio : tracks.audio;
    const audioStatus = getMediaStatus(audioTrack);

    const participantKey = userData.participantKey;
    const isRemote = !isLocal;

    participantProps = {
      participantKey,
      isRemote,
      isScreenshare,
      displayName: userData.displayName,
      video: {
        status: videoStatus,
        toggle: () => {
          if (!dailyCall || videoStatus === "pending") return;
          const isHidden = videoStatus === "disabled";
          dailyCall.updateParticipant(dailySessionId, { setVideo: isHidden });
          participantStore.updateParticipant(participantKey, { isVideoHidden: !isHidden }, { skipSync: true });
        },
      },
      audio: {
        status: audioStatus,
        toggle: () => {
          if (!dailyCall || audioStatus === "pending") return;
          const isMuted = audioStatus === "disabled";
          dailyCall.updateParticipant(dailySessionId, { setAudio: isMuted });
          participantStore.updateParticipant(participantKey, { isAudioMuted: !isMuted }, { skipSync: true });
        },
      },
      isKicked: false,
    };

    if (videoTrack.state !== "off") {
      content = (
        <DailyParticipantMedia
          video={videoTrack.persistentTrack}
          audio={isLocal ? undefined : audioTrack.persistentTrack}
          isRemote={isRemote}
          isScreenshare={isScreenshare}
          isOutlined={activeSpeakerId === dailySessionId && !isScreenshare}
        />
      );
    }
  }

  return (
    <div className="w-full h-full relative group">
      {content}
      {participantProps && (
        <ParticipantWindowControls participant={participantProps} isPinningDisabled={isPinningDisabled} />
      )}
    </div>
  );
};

export default observer(DailyParticipant);
