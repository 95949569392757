import { createContext, useContext, useState } from "react";
import { PulseLoader } from "react-spinners";
import { useMountEffect } from "@react-hookz/web";
import { observer } from "mobx-react-lite";
import Select from "@/components/common/elements/Select";
import { loggerContext, MeetingStoreContext, UserStoreContext } from "@/stores";
import { ERROR_FOLLOW_UP_TEXT } from "@/utils/constants";
import { initLogger } from "@/utils/logging.utils";

type Device = {
  deviceId: string;
  label: string;
  isEnabled: boolean;
  setEnabled: () => Promise<unknown>;
};

export type DeviceContextData = {
  mics: Device[];
  cameras: Device[];
  refreshDevices?: () => void;
};

const logger = initLogger("SettingsContent", loggerContext);

export const DeviceContext = createContext<DeviceContextData>({
  mics: [],
  cameras: [],
});

const SettingsContent = () => {
  const { mics, cameras, refreshDevices } = useContext(DeviceContext);
  useMountEffect(() => refreshDevices && refreshDevices());

  const { isStaff } = useContext(UserStoreContext);

  const meetingStore = useContext(MeetingStoreContext);
  const { isLiveCaptioningEnabled } = meetingStore;

  const [error, setError] = useState<string>();

  const [isMicLoading, setIsMicLoading] = useState(false);
  const [isCameraLoading, setIsCameraLoading] = useState(false);

  const selectMic = (micId: string) => {
    const mic = mics.find(m => m.deviceId === micId);
    if (!mic) return;
    setIsMicLoading(true);
    logger
      .wrapOperation("selectMic", mic.setEnabled())
      .catch(() => setError(`error changing mic - ${ERROR_FOLLOW_UP_TEXT.retryOrContactSupport}`))
      .finally(() => setIsMicLoading(false));
  };

  const selectCamera = (cameraId: string) => {
    const camera = cameras.find(m => m.deviceId === cameraId);
    if (!camera) return;
    setIsCameraLoading(true);
    logger
      .wrapOperation("selectCamera", camera.setEnabled())
      .catch(() => setError(`error changing camera - ${ERROR_FOLLOW_UP_TEXT.retryOrContactSupport}`))
      .finally(() => setIsCameraLoading(false));
  };

  return (
    <div className="w-full h-full p-4 flex flex-col">
      <span className="">Select Camera</span>
      <PulseLoader loading={isCameraLoading} size={5} cssOverride={{ display: "inline", marginLeft: "10px" }} />
      <div className="mt-2 mb-3">
        <Select
          className="border-ocean text-ocean min-w-full w-full rounded truncate overflow-hidden"
          showLabel={false}
          label=""
          value={cameras.find(c => c.isEnabled)?.deviceId}
          onChange={selectCamera}
          disabled={isCameraLoading}
          options={cameras.map(c => ({ name: c.label, value: c.deviceId }))}
        />
      </div>
      <span className="">Select Microphone</span>
      <PulseLoader loading={isMicLoading} size={5} cssOverride={{ display: "inline", marginLeft: "10px" }} />
      <div className="mt-2 mb-3">
        <Select
          className="border-ocean w-full min-w-full rounded truncate text-ocean text-ellipsis overflow-hidden"
          showLabel={false}
          label=""
          value={mics.find(m => m.isEnabled)?.deviceId}
          onChange={selectMic}
          disabled={isCameraLoading}
          options={mics.map(m => ({ name: m.label, value: m.deviceId }))}
        />
      </div>
      {error && <div className="xl:w-96 pt-4">{error}</div>}
      {isStaff && (
        <>
          <span className="mt-auto">Toggle Live Captioning</span>
          <div className="mt-2 mb-3">
            <Select
              className="border-ocean w-full min-w-full rounded truncate text-ocean text-ellipsis overflow-hidden"
              showLabel={false}
              label=""
              value={isLiveCaptioningEnabled ? "on" : "off"}
              onChange={value => meetingStore.toggleLiveCaptioning(value === "on")}
              options={[
                { name: "Active", value: "on" },
                { name: "Inactive", value: "off" },
              ]}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default observer(SettingsContent);
