import { toast, Id as ToastId, ToastOptions } from "react-toastify";
import { DocumentChartBarIcon } from "@heroicons/react/20/solid";
import { makeAutoObservable, runInAction } from "mobx";
import { MeetingParticipant } from "@parallel/vertex/types/meeting.types";
import { Override } from "@parallel/vertex/types/shared.types";
import { getCommaList } from "@parallel/vertex/util/string.util";
import AlertToast from "@/components/common/elements/AlertToast";
import { TelehealthLogger } from "@/utils/logging.utils";

const MAX_ALERT_COUNT = 1000;

const FEEDBACK_SURVEY_ALERT_ID = "feedback-survey-alert";
const PARTICIPANT_FOCUS_ALERT_ID = "participant-focus-alert";

export type AlertId = ToastId;

export enum NetworkQualityStatus {
  Ok = "Ok",
  Poor = "Poor",
  Unstable = "Unstable",
}

export type ResolutionCategory = "browser" | "network" | "refresh" | "retry" | "access" | "devices";

export type AlertSeverity = "error" | "warn" | "info";

type PushAlertOptions = {
  resolution?: ResolutionCategory;
  details?: string;
  severity?: AlertSeverity;
  autoClose?: number | false;
};

export type AlertPayload = Override<PushAlertOptions, { severity: AlertSeverity }> & {
  message: string;
  createdAt: Date;
};

export type Alert = AlertPayload & { id: AlertId };

export const getNetworkAlertContent = (status: NetworkQualityStatus): PushAlertOptions & { message: string } => {
  switch (status) {
    case NetworkQualityStatus.Ok:
      return { message: "Internet Connection OK", severity: "info" };
    case NetworkQualityStatus.Poor:
      return { message: "Poor Internet Connection", severity: "warn", autoClose: false };
    case NetworkQualityStatus.Unstable:
      return { message: "Unstable Internet Connection", severity: "error", autoClose: false };
  }
};

export class AlertStore {
  alerts: Alert[] = [];
  selectedAlert?: AlertPayload = undefined;

  isSessionExpired?: boolean = false;
  shouldReloadOnLogin?: boolean = false;

  lostFocusAlert?: { id: AlertId; message: string };

  constructor() {
    makeAutoObservable(this);
  }
  logger: TelehealthLogger = {} as TelehealthLogger;

  push(message: string, options: PushAlertOptions = {}): AlertId {
    const { severity = "error", autoClose } = options;
    const payload = { message, createdAt: new Date(), severity, ...options };
    const id = toast[severity](<AlertToast message={message} onClick={() => this.setSelected(payload)} />, {
      autoClose,
      closeOnClick: false,
    });
    runInAction(() => {
      this.alerts.unshift({ id, message, createdAt: new Date(), severity, ...options });
      if (this.alerts.length > MAX_ALERT_COUNT) {
        this.logger.warn("alert count exceeded max");
        this.alerts = this.alerts.slice(0, 10);
      }
    });
    return id;
  }

  clear(alertId: AlertId) {
    toast.dismiss(alertId);
  }

  setSelected(alert?: AlertPayload) {
    this.selectedAlert = alert;
  }

  setShouldReloadOnLogin(shouldReloadOnLogin: boolean) {
    runInAction(() => (this.shouldReloadOnLogin = shouldReloadOnLogin));
  }

  handleLoginSuccess() {
    runInAction(() => (this.isSessionExpired = false));
    if (this.shouldReloadOnLogin) {
      this.setShouldReloadOnLogin(false);
    }
  }

  // common function for dismissing toasts by id
  dismissAlertByAlertId(alertId: string) {
    if (toast.isActive(alertId)) toast.dismiss(alertId);
  }

  public dismissFeedbackSurveyAlert = () => {
    this.dismissAlertByAlertId(FEEDBACK_SURVEY_ALERT_ID);
  };

  public updateFeedbackSurveyAlert = (
    callFeedbackSurveyFn: () => Promise<void> | undefined,
    appointmentId?: string,
    canReceiveSurveyKeys: string[] = [],
  ) => {
    const sendFeedbackSurvey = () => {
      callFeedbackSurveyFn();
      this.dismissFeedbackSurveyAlert();
    };

    if (!toast.isActive(FEEDBACK_SURVEY_ALERT_ID)) {
      const customToastMessage = (
        <div className="text-sm">
          Send the ROM survey before the meeting ends
          <button
            onClick={() => sendFeedbackSurvey()}
            className="items-baseline cursor-pointer text-ocean hover:underline flex-nowrap"
          >
            <DocumentChartBarIcon className="inline-block w-5 h-5 mx-1" />
            Send the ROM Survey
          </button>
        </div>
      );
      toast(customToastMessage, {
        className: "w-[330px]",
        toastId: FEEDBACK_SURVEY_ALERT_ID,
        type: toast.TYPE.WARNING,
        autoClose: false,
      });

      this.logger.operationSuccess("updateFeedbackSurveyAlert", {
        appointmentId,
        canReceiveSurveyKeys,
      });
    }
  };

  public updateFocusAlert = (lostFocusParticipants: MeetingParticipant[], isGroupMeeting: boolean) => {
    const count = lostFocusParticipants.length;

    if (count === 0) {
      if (!toast.isActive(PARTICIPANT_FOCUS_ALERT_ID)) return;

      const preamble = isGroupMeeting ? "All clients have" : "Client has";
      const newMessage = `${preamble} navigated back to the meeting`;
      if (!!this.lostFocusAlert?.message && this.lostFocusAlert.message === newMessage) return;
      toast.update(PARTICIPANT_FOCUS_ALERT_ID, {
        render: newMessage,
        type: toast.TYPE.INFO,
        autoClose: 5000,
      });
      this.lostFocusAlert = { id: PARTICIPANT_FOCUS_ALERT_ID, message: newMessage };
      return;
    }

    const lostFocusParticipantNames = lostFocusParticipants.map(p => p.displayName);
    const displayNameMessage = getCommaList(lostFocusParticipantNames);

    // at least one participant has lost focus - upsert a toast
    const preamble = isGroupMeeting ? `${displayNameMessage} ${count === 1 ? "has" : "have"}` : "Client has";
    const newMessage = `${preamble} navigated away from the meeting`;
    const options: ToastOptions = {
      autoClose: false,
      type: toast.TYPE.WARNING,
    };

    if (!toast.isActive(PARTICIPANT_FOCUS_ALERT_ID)) {
      toast.warn(newMessage, { toastId: PARTICIPANT_FOCUS_ALERT_ID, ...options });

      this.lostFocusAlert = {
        id: PARTICIPANT_FOCUS_ALERT_ID,
        message: newMessage,
      };
    } else {
      if (this.lostFocusAlert?.message === newMessage) return;
      toast.update(PARTICIPANT_FOCUS_ALERT_ID, { render: newMessage, ...options });
      this.lostFocusAlert = { id: PARTICIPANT_FOCUS_ALERT_ID, message: newMessage };
    }
  };
}
