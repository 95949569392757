import { ReactNode, useState } from "react";
import { IonIcon } from "@ionic/react";
import { SliderHandle, SliderInput, SliderRange, SliderTrack } from "@reach/slider";
import { eye } from "ionicons/icons";

const FadeSlider = ({
  fadeOpacity,
  setFadeOpacity,
}: {
  fadeOpacity: number;
  setFadeOpacity: (opacity: number) => void;
}) => {
  return (
    <div className="flex w-full p-3 items-end justify-center">
      <SliderInput className="max-w-full w-1/2 h-2" max={1} step={0.01} value={fadeOpacity} onChange={setFadeOpacity}>
        <SliderTrack>
          <SliderRange className="bg-blue-400" />
          <SliderHandle className="bg-blue-500 w-6 h-6 px-1 rounded-full py-0.25 mt-0.125">
            <IonIcon icon={eye} className="fill-white" />
          </SliderHandle>
        </SliderTrack>
      </SliderInput>
    </div>
  );
};

const StimulusOverlay = ({
  background,
  foreground,
  containerClassName,
}: {
  background: ReactNode;
  foreground: ReactNode;
  containerClassName?: string;
}) => {
  const [fadeOpacity, setFadeOpacity] = useState(0.15);
  return (
    <div className={`flex flex-col-reverse w-full h-full bg-white rounded-xl ${containerClassName}`}>
      <FadeSlider fadeOpacity={fadeOpacity} setFadeOpacity={setFadeOpacity} />
      <div className="relative grow">
        <div className="absolute w-full h-full">{background}</div>
        <div className="absolute w-full h-full" style={{ opacity: fadeOpacity }}>
          {foreground}
        </div>
      </div>
    </div>
  );
};

export default StimulusOverlay;
