import { cloneElement, ReactElement, ReactNode, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useClickOutside } from "@react-hookz/web";
import { classNames, toToken } from "@/utils";

export enum ButtonStyleClass {
  Primary = "primary",
  Secondary = "secondary",
  Danger = "danger",
  DangerSecondary = "danger-secondary",
  Custom = "custom",
  Disabled = "disabled",
}

export type Size = "large" | "medium" | "small" | "custom";

type ButtonClassProps = {
  styleClass: string;
  hoverClass?: string;
  childClass?: string;
  confirmClass?: ButtonStyleClass;
};

export const buttonClasses: Record<ButtonStyleClass, ButtonClassProps> = {
  [ButtonStyleClass.Primary]: {
    styleClass: "bg-success text-white focus:outline-offset-4",
    hoverClass: "hover:bg-navy",
    confirmClass: ButtonStyleClass.Secondary,
  },
  [ButtonStyleClass.Secondary]: {
    styleClass: "bg-white text-success outline outline-1 -outline-offset-1 outline-success",
    hoverClass: "hover:outline-2 hover:-outline-offset-2",
    confirmClass: ButtonStyleClass.Primary,
  },
  [ButtonStyleClass.Danger]: {
    styleClass: "bg-white text-orange outline outline-1 -outline-offset-1 outline-orange",
    hoverClass: "hover:outline-2 hover:-outline-offset-2",
    confirmClass: ButtonStyleClass.DangerSecondary,
  },
  [ButtonStyleClass.DangerSecondary]: {
    styleClass: "bg-orange text-white",
    hoverClass: "hover:bg-orange-dark",
    confirmClass: ButtonStyleClass.Danger,
  },
  [ButtonStyleClass.Custom]: {
    styleClass: "",
  },
  [ButtonStyleClass.Disabled]: {
    styleClass: "bg-gray-300 text-black outline outline-1 -outline-offset-1 outline-black",
  },
};

const sizeClass = (size: Size) => {
  switch (size) {
    case "large":
      return "py-2.5 px-4";
    case "medium":
      return "py-2 px-4";
    case "small":
      return "py-1 px-2";
    case "custom":
      return "";
  }
};

const getStyleClass = (classType: ButtonStyleClass = ButtonStyleClass.Primary, withHover: boolean = true) => {
  const { styleClass, hoverClass } = buttonClasses[classType];
  return withHover && !!hoverClass ? `${styleClass} ${hoverClass}` : styleClass;
};

export type ButtonProps = {
  text?: ReactNode;
  icon?: ReactElement;
  onClick?: () => void;
  href?: string;
  newTab?: boolean;
  className?: string;
  styleClass?: ButtonStyleClass;
  size?: Size;
  styleOverrides?: any;
  disabled?: boolean;
  onClickOutside?: () => void;
  suffixIcon?: ReactElement;
  tooltip?: { id: string; text: string; place: "top" | "right" | "bottom" | "left" };
  isSubmit?: boolean;
  label?: string;
};

export const DEFAULT_BUTTON_CLASS = "font-semibold rounded-md";

const Button = ({
  text,
  icon,
  onClick,
  href,
  newTab = true,
  className = DEFAULT_BUTTON_CLASS,
  styleClass = ButtonStyleClass.Primary,
  size = "large",
  styleOverrides = {},
  disabled = false,
  onClickOutside = () => null,
  tooltip,
  suffixIcon,
  isSubmit,
  label,
}: ButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  useClickOutside(ref, onClickOutside);
  let Button = (
    <button
      onClick={
        !!onClick && !disabled
          ? e => {
              e.stopPropagation();
              onClick();
            }
          : undefined
      }
      className={classNames(
        getStyleClass(disabled ? ButtonStyleClass.Disabled : styleClass),
        sizeClass(size),
        className,
        disabled ? "cursor-default" : "cursor-pointer",
      )}
      style={styleOverrides}
      ref={ref}
      type={isSubmit ? "submit" : undefined}
      aria-label={label}
    >
      <div className="flex flex-row gap-2 place-content-center text-sm whitespace-nowrap">
        {icon && cloneElement(icon, { className: `${icon.props.className} w-5 h-5` })}
        {text && <span>{text}</span>}
        {suffixIcon && cloneElement(suffixIcon, { className: `${suffixIcon.props.className} w-5 h-5 pl-2` })}
      </div>
    </button>
  );

  if (tooltip) {
    Button = (
      <>
        {cloneElement(Button, { id: tooltip.id })}
        <Tooltip anchorId={tooltip.id} content={tooltip.text} place={tooltip.place} />
      </>
    );
  }

  if (href)
    return newTab ? (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {Button}
      </a>
    ) : (
      <a href={href}>{Button}</a>
    );

  return Button;
};

export default Button;

export const ConfirmButton = (props: ButtonProps) => {
  const [isConfirming, setIsConfirming] = useState(false);

  if (!isConfirming) return <Button {...props} onClick={() => setIsConfirming(true)} />;

  const confirmClass = buttonClasses[props.styleClass || ButtonStyleClass.Primary].confirmClass;
  return (
    <Button
      {...props}
      onClick={() => {
        !!props.onClick && props.onClick();
        setIsConfirming(false);
      }}
      text="Click Again to Confirm"
      styleClass={confirmClass}
      onClickOutside={() => setIsConfirming(false)}
    />
  );
};

type DropDownOption = { text: string; onClick: () => void };

export const DropDownButton = (props: ButtonProps & { options: DropDownOption[]; menuClass?: string }) => {
  const [isEnabled, setIsEnabled] = useState(false);
  return (
    <div className="relative">
      <Button
        {...props}
        className={classNames(props.className || DEFAULT_BUTTON_CLASS, isEnabled ? "rounded-b-none shadow" : "")}
        onClick={() => setIsEnabled(!isEnabled)}
        onClickOutside={() => setIsEnabled(false)}
        suffixIcon={props.suffixIcon}
      />
      {isEnabled && (
        <div className="relative">
          {/* have to wrap in fixed-width div to prevent list items getting squished to the button width */}
          <div className="absolute left-0 top-0 w-48 z-50">
            <ul
              className={classNames(
                getStyleClass(props.styleClass, false),
                "text-sm shadow divide-y divide-gray-200 max-h-48 overflow-y-auto p-px",
              )}
            >
              {props.options.map(({ text, onClick }) => (
                <li key={`dropdown_${toToken(text)}`}>
                  <div
                    className={classNames(
                      "relative w-full p-2 flex items-center hover:bg-gray-50 cursor-pointer",
                      props.menuClass ? props.menuClass : undefined,
                    )}
                    onMouseDown={e => {
                      onClick();
                      e.stopPropagation();
                    }}
                  >
                    {text}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
