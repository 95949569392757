import { initServiceConfig } from "@parallel/vertex/config/initialize.config";

export default initServiceConfig(import.meta.env, {
  global: {
    agoraAppIds: {
      base: "cae73929d42e4f86ab92aece26d8bdc3",
      whiteboard: "LIGPcNIUEeyQ1TWgqixuWQ/_2SUykWGNAcmpg",
    },
    alertChimeAudioFileUrl: "https://storage.googleapis.com/parallel-static/recording-start.wav",
    sentryEndpoint: "https://95969827f2df4c9b81072538e744141c@o1183338.ingest.sentry.io/6300563",
    techSupportLink: "https://parallellearninghelp.zendesk.com/hc/en-us/articles/18322789020187",
    testingSessionAppointmentTypeId: "26fedea8-90c2-48dd-888b-44ccc9ca35bf",
    feebackFormTemplateIds: [
      "ae4ca7e0-047c-4d92-9c11-00b4812266b2",
      "3c36ad2b-3579-4268-87e0-2c1f382cf31c",
      "b9caa827-123a-4c91-b46e-b140b5ae4622",
      "95de18d4-1278-4704-94a2-fb78f98c2360",
    ],
  },
  preprod: {
    nexusServerUrl: "https://api.parallel-staging.com/telehealth",
    whiteboardStorageUrl: "https://storage.googleapis.com/agora-staging-file-conversion/dynamicConvert",
    healthieUrl: "https://securestaging.gethealthie.com",
  },
  prod: {
    nexusServerUrl: "https://api.parallellearning.com/telehealth",
    whiteboardStorageUrl: "https://storage.googleapis.com/whiteboard-file-conversion/dynamicConvert",
    healthieUrl: "https://app.parallellearning.com",
  },
  local: {
    nexusServerUrl: "http://localhost:8081",
  },
});
