import { MutableRefObject } from "react";
import { useEventListener, useRafState } from "@react-hookz/web";
import { debounce } from "lodash";
import { Position } from "@/interfaces/geometry";

// how long to wait between updating mouse state (triggering message to other user)
const UPDATE_PERIOD_MS = 1;

const useDebouncedMouseHovered = (ref: MutableRefObject<HTMLDivElement | null>) => {
  const [position, setPosition] = useRafState<Position>({ x: 0, y: 0 });

  const handleMove = debounce(
    (e: MouseEvent) => {
      if (!ref.current) return;
      const bounds = ref.current.getBoundingClientRect();
      const position = {
        x: e.clientX - bounds.left,
        y: e.clientY - bounds.top,
      };
      setPosition(position);
    },
    UPDATE_PERIOD_MS,
    { leading: true, trailing: false },
  );
  useEventListener(ref, "mousemove", handleMove);

  return position;
};

export default useDebouncedMouseHovered;
