import { Context, useContext, useEffect, useState } from "react";
import { pick } from "lodash";
import { autorun } from "mobx";

export const useStoreProps = <A>(store: A, propNames: string[]) => {
  const getProps = () => pick(store, propNames) as A;
  const [currProps, setCurrProps] = useState(getProps());
  useEffect(() => {
    autorun(() => setCurrProps(getProps()));
  }, [store]);
  return currProps;
};

export const useStoreContextProps = <A>(storeContext: Context<A>, propNames: string[]) => {
  const store = useContext(storeContext);
  return useStoreProps(store, propNames);
};
