import { useContext, useState } from "react";
import { useDaily, useLocalSessionId, useParticipantProperty } from "@daily-co/daily-react";
import { noop } from "lodash";
import { ApiStoreContext } from "@/stores";
import { AUDIO_PLAYER_TRACK_NAME } from "@/utils/daily.utils";
import AudioPlayer, { AudioTrack } from "../activity/assessment/AudioPlayer";

const DailyAudioPlayer = ({ trackPaths }: { trackPaths: string[] }) => {
  const { assessmentApi } = useContext(ApiStoreContext);

  const dailyCall = useDaily();
  const localSessionId = useLocalSessionId();
  const localAudioTrack = useParticipantProperty(localSessionId, "audioTrack") || undefined;

  const [audio, setAudio] = useState<HTMLAudioElement>();

  const initiateTrack = async (path: string) => {
    if (!dailyCall) return;
    const audioFile = await assessmentApi.getAssessmentAudio(path.replace("/", ""));
    const audio = new Audio(URL.createObjectURL(audioFile));
    setAudio(audio);
  };

  let track: AudioTrack | undefined = undefined;
  if (!!dailyCall && !!audio) {
    track = {
      setStatusListener: noop,
      start: async () => {
        const stream: MediaStream = (audio as any).captureStream();
        await audio.play();
        const audioTrack = stream.getAudioTracks()[0];
        dailyCall.startCustomTrack({ track: audioTrack, trackName: AUDIO_PLAYER_TRACK_NAME });
      },
      resume: () => audio.play(),
      stop: () => {
        audio.pause();
        audio.currentTime = 0;
      },
      pause: () => audio.pause(),
      backToStart: () => (audio.currentTime = 0),
      terminate: noop,
      durationSeconds: audio.duration || 0,
      currTimeSeconds: () => audio.currentTime,
    };
  }

  const isEnabled = localAudioTrack?.enabled || false;
  const mic = {
    isEnabled,
    toggle: () => dailyCall?.setLocalAudio(!isEnabled),
  };

  return (
    <AudioPlayer
      trackPaths={trackPaths}
      track={track}
      initiateTrack={initiateTrack}
      clearTrack={() => setAudio(undefined)}
      mic={mic}
    />
  );
};

export default DailyAudioPlayer;
