import { useContext } from "react";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import SupportPrompt from "@/components/common/content/error/SupportPrompt";
import { WhiteboardStoreContext } from "@/stores";
import AgoraFastboard from "./AgoraFastboard";

const Whiteboard = () => {
  const { errorMessage } = useContext(WhiteboardStoreContext);
  return (
    <div className="w-full h-full rounded-xl bg-white">
      {!errorMessage ? (
        <AgoraFastboard />
      ) : (
        <div className="w-full h-full p-8 grid place-items-center">
          <div className="w-1/2 flex flex-col gap-4">
            <p>{capitalize(errorMessage)}</p>
            <p>
              Please try refreshing the page. <SupportPrompt issueTitle="Whiteboard Connection Issue" />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(Whiteboard);
