import { AgoraRecording, MeetingRecordingLocation } from "@parallel/vertex/types/recording.types";
import { BaseAPI } from "./base.api";

export class RecordingAPI extends BaseAPI {
  public startRecording = (
    meetingKey: string,
    userId: string,
    location: MeetingRecordingLocation,
  ): Promise<AgoraRecording> =>
    this.instance.post(`/recording/start`, { meetingKey, userId, location }).then(r => r.data);

  public stopRecording = (meetingKey: string, userId: string, recording: AgoraRecording): Promise<void> =>
    this.instance.post(`/recording/stop`, {
      ...recording,
      meetingKey,
      userId,
    });
}
