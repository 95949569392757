import { Position, OffsetRelativeArea } from "@parallel/vertex/types/shared.types";

export type { Position, OffsetRelativeArea } from "@parallel/vertex/types/shared.types";

export type PixelArea = { width: number; height: number };

export type OffsetPixelArea = PixelArea & { offset: Position };

// all values should be within [0,1] + represent the _proportion_ of the area
//  i.e. `width: 0.25` means that the width spans 25% of the original area's width
export type IRelativeAreaSelection = {
  width: number;
  height: number;
  position: Position;
};

export const aspectRatio = (area: PixelArea) => area.width / area.height;

export const isWider = (a1: PixelArea, a2: PixelArea) => aspectRatio(a1) > aspectRatio(a2);

// lock aspect ratio to contents area + return area that will display as large as possible in window
export const lockedArea = (container: PixelArea, contents: PixelArea): OffsetPixelArea => {
  const lockedHeight = container.width / aspectRatio(contents);
  const lockedWidth = container.height * aspectRatio(contents);
  return isWider(contents, container)
    ? {
        width: container.width,
        height: lockedHeight,
        offset: { x: 0, y: (container.height - lockedHeight) / 2 },
      }
    : {
        width: lockedWidth,
        height: container.height,
        offset: { x: (container.width - lockedWidth) / 2, y: 0 },
      };
};

export const relativeArea = (
  reference: OffsetPixelArea,
  relative: OffsetRelativeArea,
  source?: PixelArea,
): OffsetPixelArea => {
  const relativeWidth = reference.width * relative.width;
  const sourceAspectRatio = source ? aspectRatio(source) : 1;
  return {
    width: relativeWidth,
    height: relative.height ? reference.height * relative.height : relativeWidth / sourceAspectRatio,
    offset: {
      x: reference.offset.x + relative.offset.x * reference.width,
      y: reference.offset.y + relative.offset.y * reference.height,
    },
  };
};
