import { Fragment, ReactNode, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { IonIcon } from "@ionic/react";
import { peopleOutline, qrCodeOutline } from "ionicons/icons";
import { observer } from "mobx-react-lite";
import config from "@/config";
import uTurnLeft from "@/icons/uturn-left-ocean.svg";
import { UserStoreContext, loggerContext } from "@/stores";
import { initLogger, useEventRedirect } from "@/utils/logging.utils";

const logger = initLogger("HeaderUserBadge", loggerContext);

type DropdownOption = {
  label: string;
  icon?: ReactNode;
  href?: string;
  onClick?: () => any;
};

const HeaderUserBadge = ({ isMeeting }: { isMeeting?: boolean }) => {
  const userStore = useContext(UserStoreContext);

  const userType = userStore.isStaff ? "Provider" : "Client";
  const userName = !isMeeting && userStore.auth?.type === "appointment-code" ? undefined : userStore.fullName;

  const redirect = useEventRedirect(logger);
  const logout = async () => {
    await userStore.signOut();
    redirect(`/login`, "header navigate to login");
  };

  const onEnter = (cb: any) => (e: React.KeyboardEvent<HTMLButtonElement>) => e.key === "Enter" && cb();

  const lobbyLogout = () => {
    if (!userName) {
      logout();
    }
  };

  const optionList: DropdownOption[] = [
    {
      label: "Join with session code",
      icon: <IonIcon icon={qrCodeOutline} />,
      onClick: async () => redirect("/join", "header navigate to session code join"),
    },
    {
      label: "Sign Out",
      icon: <IonIcon icon={peopleOutline} />,
      onClick: async () => await logout(),
    },
  ];
  if (isMeeting)
    optionList.unshift({
      label: "Back to Lobby",
      icon: <IonIcon color="blue" icon={uTurnLeft} />,
      onClick: () => redirect("/lobby", "changing appointment user"),
    });

  return (
    <div className="flex flex-row justify-center">
      <Menu as="div" className="flex flex-row justify-center" aria-haspopup>
        <Menu.Button aria-label="Header User Badge" name="Header User Badge" onKeyDown={onEnter(lobbyLogout)}>
          <div className="flex flex-row justify-center">
            <div className="w-10 p-2 bg-ocean rounded-l-md border border-ocean">
              <img
                alt="user-badge-image"
                src={`${config.staticAssetsUrl}/logos/parallel-brandmark-white.svg`}
                className="object-scale-down"
              />
            </div>

            <div className="bg-neutral px-2 text-ocean flex flex-col justify-center rounded-r-md border border-l-0 border-gray-300">
              {userName ? (
                <>
                  <span className={`flex flex-row break-keep text-sm`}>{userName}</span>
                  <span className="flex flex-row text-xs">{userType}</span>
                </>
              ) : (
                <span onClick={logout} className="hover:underline cursor-pointer">
                  Back to Login
                </span>
              )}
            </div>
          </div>
        </Menu.Button>

        {!!userName && (
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-2 top-[52px] z-10 w-52 origin-top-right rounded-md bg-white shadow-md ring-1 ring-ocean ring-opacity-0  focus:outline-none">
              {optionList.map((option, i) => (
                <Menu.Item key={i} as="div" aria-label={option.label}>
                  {({ active }) => (
                    <button
                      onClick={option.onClick ? option.onClick : undefined}
                      onKeyDown={onEnter(option.onClick)}
                      className={`${active && "bg-light"} w-full block px-4 py-2 text-sm hover:bg-ocean-light hover:cursor-pointer text-ocean text-left`}
                    >
                      {!!option.icon && option.icon} {option.label}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        )}
      </Menu>
    </div>
  );
};

export default observer(HeaderUserBadge);
