import { useContext } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { getFullNameInitials } from "@parallel/vertex/util/string.util";
import config from "@/config";
import { StoreContext } from "@/stores";

// TODO search + pin filter
const ProgressSidebarHeader = () => {
  const { meetingStore } = useContext(StoreContext);
  const { appointment, appointmentProgress } = meetingStore;

  return (
    <Stack width="100%" p={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Link
          href={
            appointment
              ? `${config.pathwayClientUrl}/calendar/appointment/${appointment.appointmentId}/progress`
              : undefined
          }
          underline="hover"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant="h6">Goals</Typography>
            <LaunchIcon fontSize="small" />
          </Stack>
        </Link>

        <Stack direction="row" gap={1}>
          {appointmentProgress?.students.map(student => {
            const isSelected = student.studentId === appointmentProgress.selectedStudentId;
            return (
              <Avatar
                sx={{
                  height: 32,
                  width: 32,
                  bgcolor: isSelected ? "surface.dark" : undefined,
                  fontSize: 16,
                  cursor: isSelected ? undefined : "pointer",
                  "&:hover": {
                    textDecoration: isSelected ? undefined : "underline",
                  },
                }}
                onClick={() => meetingStore.selectProgressStudent(student.studentId)}
                key={student.studentId}
              >
                {getFullNameInitials(student.studentName)}
              </Avatar>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default observer(ProgressSidebarHeader);
